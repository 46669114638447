@if (! selectedResource) {
  <div class="relative-cont">
    <div class="export-button-cont">
      <awc-button
        class="add-new-button"
        [text]="'Add New'"
        [suffixIcon]="addIcon"
        [size]="buttonSize"
        (click)="exportDirectToLibrary()"
      ></awc-button>
    </div>
    <awf-table [tableId]="dashboardTables.LIBRARY" (rowClick)="toggleSelection($event)" [(total)]="total"></awf-table>
  </div>
} @else {
  <div class="relative-cont">
    <app-library-panel [resourceId]="selectedResource" (resourceLoaded)="setLibraryItemLoaded()"></app-library-panel>
    @if (libraryItemLoaded) {
    <div class="button-cont">
      <awc-button [type]="btnTypeSecondary" [text]="'Back'" [size]="buttonSize" (clicked)="back()"></awc-button>
      <awc-button
        [text]="'Import'"
        [size]="buttonSize"
        (clicked)="importResource(selectedResource)"
      ></awc-button>
    </div>
    } @else {
      <div class="loading-cont">
        <awc-icon [icon]="Icons.SPINNER"></awc-icon>
      </div>
    }
  </div>
}
