/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComponentAxle } from './ComponentAxle';
import type { ComponentSide } from './ComponentSide';
/**
 * Solved motor node.
 */
export type SolvedMotor = {
    name: string;
    axle?: ComponentAxle;
    side?: ComponentSide;
    mass?: number;
    cost?: number;
    in_powers: Array<number>;
    out_powers: Array<number>;
    losses: Array<number>;
    losses_ratio: Array<number>;
    solved_component_type?: SolvedMotor.solved_component_type;
    in_voltages: Array<number>;
    speeds: Array<number>;
    in_torques: Array<number>;
    out_torques: Array<number>;
    currents_d: Array<number>;
    currents_q: Array<number>;
    currents?: Array<number>;
    power_factors?: Array<number>;
};
export namespace SolvedMotor {
    export enum solved_component_type {
        MOTOR = 'motor',
    }
}

