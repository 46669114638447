/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Deceleration Limit Configuration.
 */
export type DecelerationLimit = {
    item_type?: DecelerationLimit.item_type;
    name?: string;
    limit?: number;
    config_type?: DecelerationLimit.config_type;
};
export namespace DecelerationLimit {
    export enum item_type {
        CONFIG = 'config',
    }
    export enum config_type {
        DECELERATION_LIMIT = 'deceleration_limit',
    }
}

