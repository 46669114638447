import { Injectable } from '@angular/core';
import { AndromedaMenuItem } from '@ansys/andromeda/contributions';
import { Icons } from '@ansys/awc-angular/icons';
import { DialogService } from '@ansys/andromeda/shared';
import { PopoutService } from '@ansys/awc-angular/popouts';
import { FeatureFlagsDialogComponent } from '../components/dialogs/feature-flags-dialog/feature-flags-dialog.component';

@Injectable({ providedIn: 'root' })
export class FeatureFlagMenu implements AndromedaMenuItem {
  type = 'feature-flags-dialog';
  priority = 100;
  id = 'feature-flags-dialog';
  prefixIcon = {
    icon: Icons.BUG,
  };
  text = 'Feature Flags';

  constructor(
    private dialog: DialogService,
    private popoutService: PopoutService
  ) {}

  execute(): void {
    this.dialog.open(FeatureFlagsDialogComponent, { title: 'Feature Flags' });
    this.popoutService.closeAll();
  }
}
