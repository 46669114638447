<div class="input-cont" [ngClass]="layout" [style.cursor]="type === InputTypes.SELECT ? 'pointer' : 'default'">
  <div *ngIf="label || type === InputTypes.RATIO" [style.lineHeight]="1" #labelEl>
    <span *ngIf="label" class="label">{{label}}</span>
    <ng-container *ngIf="type === InputTypes.RATIO">
      <span class="label ratio-label"> : {{altLabel}}</span>
      <span class="label"> Ratio</span>
    </ng-container>
  </div>
  <div class="form-element" [ngClass]="{ 'readonly': readonly, 'disabled' : disabled }">
    <input *ngIf="type === InputTypes.RATIO" tabindex="0" min="0" max="100" class="inputField" type="number" [placeholder]="placeholder ?? ''" step="1" [value]="value" (input)="checkInput($event)" (focus)="setInitial($event)" (keydown)="stopProp($event)" (keyup.enter)="updateValue($event)" (blur)="updateValue($event)" [readOnly]="loading || readonly" >
    <input *ngIf="type !== InputTypes.RATIO" tabindex="0" class="inputField" type="text" [placeholder]="placeholder ?? ''" [value]="value" (input)="checkInput($event)" (focus)="setInitial($event)" (keydown)="stopProp($event)" (keyup.enter)="updateValue($event)" (blur)="updateValue($event)" [readOnly]="loading || readonly" >

    <span *ngIf="type === InputTypes.RATIO && !loading" class="label ratio-label"> : {{ratioValue}}</span>
    <ng-container *ngIf="type !== InputTypes.SELECT; else selectToggle">
      <awc-icon *ngIf="loading; else unitEl" class="input-loading" [icon]="spinner"></awc-icon>
      <ng-template #unitEl>
        <span *ngIf="unit && !errState" class="unit">{{unitStr}}</span>
        <awc-icon *ngIf="errState" class="input-loading" [icon]="warning" color="red"></awc-icon>
      </ng-template>
    </ng-container>
    <ng-template #selectToggle>
      <awc-icon class="input-loading" [icon]="chevron" [ngClass]="active ? 'active' : ''"></awc-icon>
    </ng-template>
  </div>
</div>
