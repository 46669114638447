import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@ansys/andromeda/shared';
import { TITLE } from '@ansys/andromeda/auth';
import { Icons } from '@ansys/awc-angular/icons';
import { FeatureFlagsComponent } from '../../widgets/feature-flags/feature-flags.component';

@Component({
  selector: 'app-feature-flags-dialog-dialog',
  standalone: true,
  imports: [FeatureFlagsComponent],
  templateUrl: './feature-flags-dialog.component.html',
  styleUrl: './feature-flags-dialog.component.scss',
})
export class FeatureFlagsDialogComponent {
  protected readonly icons = Icons;

  constructor(
    @Inject(DIALOG_DATA) public data: undefined,
    @Inject(TITLE) protected appName: string,
    private cdr: ChangeDetectorRef
  ) {}
}
