import {
  AWCTreeItem,
  AWCTreeToolbarItem,
  TreeComponent,
  TreeToolVisibility,
} from '@ansys/awc-angular/trees';
import { Icons, IconType } from '@ansys/awc-angular/icons';

/**
 * Add toolbar item
 */
export class AddItemTB implements AWCTreeToolbarItem {
  public id = TreeItemToolbarIconIdEnum.ADD;
  public icon = {
    icon: Icons.ADD,
    src: 'https://s2.svgbox.net/materialui.svg?ic=add',
  };
  public prefixIcon = {
    icon: Icons.ADD,
    src: 'https://s2.svgbox.net/materialui.svg?ic=add',
  };
  public tooltip = 'Add New';
  public visibility = TreeToolVisibility.HOVER;
  private _execute: (item: AWCTreeItem) => void;

  constructor(_execute: (item: AWCTreeItem) => void) {
    this._execute = _execute;
  }

  public async execute(
    tool: AWCTreeToolbarItem,
    item: AWCTreeItem,
    component: TreeComponent
  ): Promise<void> {
    this._execute(item);
  }
}

/**
 * Add toolbar item
 */
export class ExportNewItem implements AWCTreeToolbarItem {
  public id = TreeItemToolbarIconIdEnum.EXPORT;
  public icon = { icon: Icons.LEGO };
  public prefixIcon = { icon: Icons.LEGO };
  public tooltip = 'Create New Item in Library';
  public visibility = TreeToolVisibility.HOVER;
  private readonly _execute: (item: AWCTreeItem) => void;

  constructor(_execute: (item: AWCTreeItem) => void) {
    this._execute = _execute;
  }

  public async execute(
    tool: AWCTreeToolbarItem,
    item: AWCTreeItem,
    component: TreeComponent
  ): Promise<void> {
    this._execute(item);
  }
}

/**
 * Duplicate toolbar item
 */
export class CloneItemTB implements AWCTreeToolbarItem {
  public id = TreeItemToolbarIconIdEnum.CLONE;
  public icon = { icon: Icons.COPY };
  public prefixIcon = { icon: Icons.COPY };
  public tooltip = 'Clone Item';
  public visibility = TreeToolVisibility.HOVER;
  private _execute: (item: AWCTreeItem) => void;

  constructor(_execute: (item: AWCTreeItem) => void) {
    this._execute = _execute;
  }

  public async execute(
    tool: AWCTreeToolbarItem,
    item: AWCTreeItem,
    component: TreeComponent
  ): Promise<void> {
    this._execute(item);
  }
}

export class AddItemToLibraryTB implements AWCTreeToolbarItem {
  public id = TreeItemToolbarIconIdEnum.ADD_TO_LIBRARY;
  public icon = { icon: Icons.LEGO };
  public prefixIcon = { icon: Icons.LEGO };
  public tooltip = 'Export Item to Library';
  public visibility = TreeToolVisibility.HOVER;
  private _execute: (item: AWCTreeItem) => void;

  constructor(_execute: (item: AWCTreeItem) => void) {
    this._execute = _execute;
  }

  public async execute(
    tool: AWCTreeToolbarItem,
    item: AWCTreeItem,
    component: TreeComponent
  ): Promise<void> {
    this._execute(item);
  }
}

/**
 * Delete toolbar item
 */
export class DeleteItemTB implements AWCTreeToolbarItem {
  public id = TreeItemToolbarIconIdEnum.DELETE;
  public icon: IconType = { icon: Icons.DELETE };
  public tooltip = 'Delete Item';
  public visibility = TreeToolVisibility.HOVER;
  private _execute: (item: AWCTreeItem) => void;

  constructor(_execute: (item: AWCTreeItem) => void) {
    this._execute = _execute;
  }

  public async execute(
    tool: AWCTreeToolbarItem,
    item: AWCTreeItem,
    component: TreeComponent
  ): Promise<void> {
    this._execute(item);
  }
}

export enum TreeItemToolbarIconIdEnum {
  ADD = 'add',
  EXPORT = 'export',
  CLONE = 'clone',
  ADD_TO_LIBRARY = 'add-to-library',
  DELETE = 'delete',
}
