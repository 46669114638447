/* eslint-disable @typescript-eslint/naming-convention */

import {
  InverterAnalytical,
  InverterIGBTData,
  InverterMOSFETData,
} from 'src/api';

export const invertAnalytical: InverterAnalytical = {
  component_type: InverterAnalytical.component_type.INVERTER_ANALYTICAL,
  name: 'Default Inverter',
  item_type: InverterAnalytical.item_type.COMPONENT,
  inverter_data: {} as InverterIGBTData | InverterMOSFETData,
};
