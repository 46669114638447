/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ButtonType, ButtonSize } from '@ansys/awc-angular/buttons';
import { FlexLayout } from '@ansys/awc-angular/core';
import { IconType, Icons } from '@ansys/awc-angular/icons';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { DriveCycleSolved, RequirementsSolved, UnitType } from 'src/api';
import { ConceptInputType, PlotConfigType } from 'src/app/shared/enums';
import { InputTypes } from '../forms/input/input.component';
import {
  AWFLocation,
  AWF_LOCATION,
  SnackBarService,
} from '@ansys/andromeda/shared';
import { State } from '@ansys/andromeda/store';
import {
  ConfigurationEnvironment,
  ConfigurationsService,
} from 'src/app/shared/services/configurations.service';
import {
  DataDisplayService,
  DataDisplayState,
} from 'src/app/shared/services/data-display.service';
import { Subscription, take } from 'rxjs';
import { RequirementsService } from 'src/app/shared/services/requirements.service';
import { ComponentsService } from 'src/app/shared/services/components.service';
import {
  wheelList,
  invertBatteryList,
  motorList,
  transmissionList,
  clutchList,
} from '../../layout/data-display-panel/drive-cycle-component-lists';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { DriveCyclePlotAxis } from 'src/app/shared/types';
import { DriveCycleAxisState } from 'src/app/state/lib/drive-cycle-axis.state';
import { ConfigurationEnvState } from 'src/app/state/lib/configuration-env.state';
import { MotorLossTypeState } from 'src/app/state/lib/motor-loss-type.state';
import { MotorSpeedState } from 'src/app/state/lib/motor-speed.state';
import {
  dcSolvedComponents,
  reqSolvedComponents,
} from '../charts/table-display/lib/columns';
import { ResultType } from '../results-list/results-list.component';
import { PlotService } from '../../../shared/services/plot.service';
import { AddDriveCycleAxisAction } from '../../../actions/add-drive-cycle-axis/add-drive-cycle-axis.action';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { SelectedRequirementColumnsState } from '../../../state/lib/selected-requirement-columns.state';
import { InverterLossesType } from '../../../shared/enums/inverter-losses-type';
import { InverterComponentOptionsState } from '../../../state/lib/inverter-component-options.state';

@Component({
  selector: 'app-data-config',
  templateUrl: './data-config.component.html',
  styleUrls: ['./data-config.component.scss'],
})
export class DataConfigComponent implements OnDestroy, OnChanges {
  @Input() plotConfigType: PlotConfigType = PlotConfigType.NONE;
  @Input() showResultsButton!: boolean;
  @Input() items: AWCTreeItem[] = [];
  @Output() showResultsButtonChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  readonly PlotConfigType = PlotConfigType;
  readonly resultType = ResultType;

  readonly InputTypes = InputTypes;
  readonly UnitType = UnitType;
  readonly Icons = Icons;
  protected layout: FlexLayout = FlexLayout.COLUMN;
  protected selectedGear: string[] = [];
  protected selectedVoltage: string[] = [];
  protected componentGears: AWCListItem[] = [];
  protected componentVoltages: AWCListItem[] = [];
  protected alttype: ButtonType = ButtonType.SECONDARY;
  protected smallsize: ButtonSize = ButtonSize.SMALL;
  protected addIcon: IconType = { icon: Icons.ADD };
  protected driveCycleComponents: AWCListItem[] = [];
  protected driveCycleAttributes: AWCListItem[] = [];
  protected motorLossesTypes: AWCListItem[] = [
    {
      id: 'total',
      text: 'Total Losses',
    },
    {
      id: 'iron',
      text: 'Iron Losses',
    },
  ];
  protected inverterLossesTypes: AWCListItem[] = [
    { id: InverterLossesType.TOTAL, text: 'Total Losses' },
    { id: InverterLossesType.SWITCHING, text: 'Switching Losses' },
    { id: InverterLossesType.CONDUCTION, text: 'Conduction Losses' },
    { id: InverterLossesType.AC_HARNESS, text: 'AC Harness Losses' },
    { id: InverterLossesType.DC_HARNESS, text: 'DC Harness Losses' },
  ];
  protected requirementYAxis = this.requirementService.getRequirementYAxis();
  protected driveCycleAxis: DriveCyclePlotAxis[] = [];
  protected vehicleOverviewGradient: number = 0;
  protected vehicleOverviewSpeed: number = 50;
  protected vehicleOverviewAcceleration: number = 0;
  protected vehicleOverviewAltitude: number = 0;
  protected vehicleOverviewHeadwind: number = 0;
  protected vehicleOverviewStepsize: number = 0.2;
  protected activeResult!: RequirementsSolved;
  protected selectedMotorLossesType: string[] = [
    this.inverterLossesTypes[0].id,
  ];
  protected selectedInverterLossesTypes: string[] =
    this.selectedMotorLossesType;
  protected motorLossesSpeed: number | undefined = undefined;
  protected requirementColumns: AWCListItem[] = reqSolvedComponents;
  // TODO: Move to state;
  protected selectedRequirementColumns!: string[];
  protected selectedPointIndex: string[] = [];
  protected btnType: ButtonType = ButtonType.SECONDARY;
  protected btnSize: ButtonSize = ButtonSize.MEDIUM;
  protected readonly DataDisplayState = DataDisplayState;
  protected inverterOptions: {
    dc_current?: number | null;
    power_factor?: number | null;
    phase_current_max?: number | null;
    frequency?: number | null;
  } = {
    dc_current: null,
    power_factor: null,
    phase_current_max: null,
    frequency: null,
  };
  private lossType: string = InverterLossesType.TOTAL;
  private subscriptions: Subscription[] = [];
  private listMap: Map<string, AWCListItem[]> = new Map([
    ['wheel', wheelList],
    ['inverter', invertBatteryList],
    ['battery', invertBatteryList],
    ['motor', motorList],
    ['road', wheelList],
    ['transmission', transmissionList],
    ['clutch', clutchList],
  ]);

  constructor(
    private snackbar: SnackBarService,
    private state: State,
    protected requirementService: RequirementsService,
    private _cdr: ChangeDetectorRef,
    protected dataDisplay: DataDisplayService,
    private componentService: ComponentsService,
    private configService: ConfigurationsService,
    private plotDataService: PlotService,
    @Inject(AWF_LOCATION) private location: AWFLocation,
    private actions: ActionContributionsService,
    private plotService: PlotService
  ) {
    this.inverterOptions = this.state.value(InverterComponentOptionsState);
    this.subscriptions.push(
      this.requirementService.selectedResult.subscribe((result: any) => {
        this.activeResult = result;

        // Get Requirement columns
        this.requirementColumns =
          this.activeResult?.requirement_solved_type ===
          this.resultType.DRIVE_CYCLE
            ? dcSolvedComponents
            : reqSolvedComponents;

        // Set the initial selected column state
        if (
          this.activeResult &&
          this.state.value(SelectedRequirementColumnsState).length === 0 &&
          this.requirementColumns.length > 0
        ) {
          this.state.set(
            SelectedRequirementColumnsState,
            this.requirementColumns.map((listItem) => listItem.id)
          );
        }

        // Set the initial table state
        this.refreshTableView();

        if (result?.requirement_solved_type === this.resultType.DRIVE_CYCLE) {
          const components = this.activeResult.solved_components;
          this.driveCycleComponents = components?.map((c: any) => {
            return {
              id: c.name,
              text: c.name,
              userData: c,
            };
          });
          this.driveCycleAttributes = [];

          this.driveCycleAxis = this.state.value(
            DriveCycleAxisState,
            '' + this.activeResult.id
          );
        }
        this._cdr.markForCheck();
      })
    );
    const env: ConfigurationEnvironment = (this.state.value(
      ConfigurationEnvState,
      this.location.tab?.id
    ) as ConfigurationEnvironment) || {
      gradient: 0,
      speed: 50,
      acceleration: 0,
      altitude: 0,
      headwind: 0,
      stepsize: 0.2,
    };
    this.setFromEnv(env);

    this.state.get(SelectedRequirementColumnsState).subscribe((columns) => {
      this.selectedRequirementColumns = columns;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      const selectedItem = changes['items'].currentValue[0];
      if (!selectedItem || !selectedItem.userData) {
        return;
      }

      if (selectedItem.userData['gear_ratios']) {
        this.componentGears = (
          selectedItem.userData['gear_ratios'] as number[]
        ).map((gear: number): AWCListItem => {
          return {
            id: gear.toString(),
            text: `Gear ${gear}`,
          };
        });
        this.selectedGear = [this.componentGears[0].id];
      }

      if (selectedItem.userData['voltages']) {
        this.componentVoltages = (
          selectedItem.userData['voltages'] as number[]
        ).map((voltage: number): AWCListItem => {
          return {
            id: voltage.toString(),
            text: `Voltage ${voltage}`,
          };
        });
        this.selectedVoltage = [this.componentVoltages[0].id];
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  protected selectionMotorLossType(id: string): void {
    this.selectedMotorLossesType = [id];
    this.dataDisplay.displayState = DataDisplayState.LOADING;
    this.state.set(MotorLossTypeState, id, this.location.tab?.id || '');
    this.componentService
      .displayData(this.items[0].id, {
        lossType: id,
        speed: this.motorLossesSpeed || undefined,
      })
      .then(() => {
        this.dataDisplay.displayState = DataDisplayState.GRAPH_DISPLAY;
        this.snackbar.success('Losses type updated!');
      })
      .catch(() => {
        // do nothing
      });
  }

  protected selectionInverterLossType(lossType: string): void {
    this.lossType = lossType;
    this.getDisplayData();
  }

  protected onExportInverterLosses(): void {
    this.componentService.exportInverterData(this.items[0].id, {
      type: ConceptInputType.INVERTER,
      lossType: this.selectedInverterLossesTypes[0],
    });
  }

  protected setInverterInputs(property: string, $event: string | number): void {
    if (property in this.inverterOptions) {
      // Update the inverterOptions property with the event value
      this.inverterOptions[property as keyof typeof this.inverterOptions] =
        typeof $event === 'string' ? parseFloat($event) : $event;
    }
    this.state.set(InverterComponentOptionsState, this.inverterOptions);

    this.getDisplayData();
  }

  protected setMotorSpeed(speed: number | string): void {
    this.motorLossesSpeed = speed as number;
    this.state.set(MotorSpeedState, speed, this.location.tab?.id || '');
    this.componentService
      .displayData(this.items[0].id, {
        lossType: this.selectedMotorLossesType[0],
        speed: this.motorLossesSpeed || undefined,
      })
      .then(() => {
        this.dataDisplay.displayState = DataDisplayState.GRAPH_DISPLAY;
        this.snackbar.success('Motor speed updated!');
      })
      .catch(() => {
        // do nothing
      });
  }

  protected getMatchingAttributes(type: string): AWCListItem[] {
    return this.listMap.get(type) || [];
  }

  protected addPlotAxis(): void {
    const newAxis: DriveCyclePlotAxis = {
      component: 'Road',
      attribute: 'speeds',
      unit: UnitType.ANGULAR_SPEED,
      type: 'road',
      color: '#800080',
    };
    this.driveCycleAxis = [...this.driveCycleAxis, newAxis];
    this.state.set(
      DriveCycleAxisState,
      this.driveCycleAxis,
      '' + this.activeResult.id
    );

    this.actions.execute(AddDriveCycleAxisAction, [
      this.activeResult as DriveCycleSolved,
      this.driveCycleAxis,
    ]);
  }

  protected removeAxis(index: number): void {
    this.driveCycleAxis.splice(index, 1);
    this.state.set(
      DriveCycleAxisState,
      this.driveCycleAxis,
      '' + this.activeResult.id
    );
    this.actions.execute(AddDriveCycleAxisAction, [
      this.activeResult as DriveCycleSolved,
      this.driveCycleAxis,
    ]);
  }

  protected selectionDriveCycleComponentAxis(
    item: AWCListItem,
    axis: DriveCyclePlotAxis
  ): void {
    axis.component = item.id;
    axis.type = (item.userData as any)['solved_component_type'];
    const attributes = this.getMatchingAttributes(axis.type);
    if (!attributes.find((a: AWCListItem) => a.id === axis.attribute)) {
      axis.attribute = attributes[0].id;
    }
    axis.unit = this.getUnitFromAttribute(axis.attribute);
    this.state.set(
      DriveCycleAxisState,
      this.driveCycleAxis,
      '' + this.activeResult.id
    );
    this.actions.execute(AddDriveCycleAxisAction, [
      this.activeResult as DriveCycleSolved,
      this.driveCycleAxis,
    ]);
  }

  protected selectionDriveCycleAttributeAxis(id: string, axis: any): void {
    axis.attribute = id;
    axis.unit = this.getUnitFromAttribute(axis.attribute);
    this.state.set(
      DriveCycleAxisState,
      this.driveCycleAxis,
      '' + this.activeResult.id
    );
    this.actions.execute(AddDriveCycleAxisAction, [
      this.activeResult as DriveCycleSolved,
      this.driveCycleAxis,
    ]);
  }

  protected changeAxisColor($event: Event, axis: any): void {
    axis.color = ($event.target as HTMLInputElement).value;
    this.state.set(
      DriveCycleAxisState,
      this.driveCycleAxis,
      '' + this.activeResult.id
    );
    this.actions.execute(AddDriveCycleAxisAction, [
      this.activeResult as DriveCycleSolved,
      this.driveCycleAxis,
    ]);
  }

  protected selectionIDSet(id: string, type: string): void {
    // logic
    this.snackbar.info('Updating plot...');
    if (type === 'gearRatio') {
      this.selectedGear = [id];
    } else {
      this.selectedVoltage = [id];
    }
    const env = {
      [type]: id,
      type: this.items[0].parent?.id ?? 'motor',
    };
    this.dataDisplay.displayState = DataDisplayState.LOADING;
    this.componentService
      .displayData(this.items[0].id, env)
      .then(() => {
        this.dataDisplay.displayState = DataDisplayState.GRAPH_DISPLAY;
        this.snackbar.success('Plot updated!');
      })
      .catch(() => {
        // do nothing
      });
  }

  /**
   * Send plot environment change
   * change this logic to not use state as its overkill -- just follow same route I took in component plot above.
   * @param {any} $value value of the input
   */
  protected setEnv(value: number | string, env: string): void {
    const currentState = this.state.value(
      ConfigurationEnvState,
      this.location.tab?.id
    );
    const newState = {
      ...currentState,
      [env]: value,
    };
    this.state.set(ConfigurationEnvState, newState, this.location.tab?.id);
    this.snackbar.success('Plot updating!');
    this.setFromEnv(newState);
    this.dataDisplay.displayState = DataDisplayState.LOADING;
    this.configService
      .displayData(this.configService.setConfigurationIDs(this.items))
      .then(() => {
        this.dataDisplay.displayState = DataDisplayState.GRAPH_DISPLAY;
        this.snackbar.success('Plot updated!');
      });
  }

  protected onToggleAllColumns(toggle: boolean): void {
    if (toggle) {
      this.state.set(
        SelectedRequirementColumnsState,
        this.requirementColumns.map((column) => column.id)
      );
    } else {
      this.state.set(SelectedRequirementColumnsState, []);
    }

    this.refreshTableView();
    this._cdr.markForCheck();
  }

  protected onToggleColumns(toggle: boolean, selection: AWCListItem): void {
    if (toggle) {
      if (
        !this.state
          .value(SelectedRequirementColumnsState)
          .includes(selection.id)
      ) {
        this.state
          .get(SelectedRequirementColumnsState)
          .pipe(take(1))
          .subscribe((currentColumns) => {
            const updatedColumns = [...currentColumns, selection.id];
            this.state.set(SelectedRequirementColumnsState, updatedColumns);
          });
      }
    } else {
      const currentColumns = this.state.value(SelectedRequirementColumnsState);
      this.state.set(
        SelectedRequirementColumnsState,
        currentColumns.filter((id) => id !== selection.id)
      );
    }

    this.refreshTableView();
    this._cdr.markForCheck();
  }

  protected onSelectedYAxis($event: string): void {
    // Reset yaxis to 0, 0
    this.plotService.updateGraphLayout({
      yaxis: { range: [0, 0] },
    });
    this.requirementService.requirementYAxis.set($event);
  }

  private getDisplayData(): void {
    this.dataDisplay.displayState = DataDisplayState.LOADING;

    this.componentService
      .displayData(this.items[0].id, {
        type: ConceptInputType.INVERTER,
        lossType: this.lossType,
        dc_current: this.inverterOptions?.dc_current,
        power_factor: this.inverterOptions?.power_factor,
        phase_current_max: this.inverterOptions?.phase_current_max,
        frequency: this.inverterOptions?.frequency,
      })
      .then(() => {
        this.dataDisplay.displayState = DataDisplayState.GRAPH_DISPLAY;
        this.snackbar.success('Losses type updated!');
      })
      .catch(() => {
        // do nothing
      });
  }

  private refreshTableView(): void {
    this.plotDataService.activePointIndex
      .pipe(take(1))
      .subscribe((point: string[]) => {
        this.selectedPointIndex = point;

        this.requirementService.solvedComponentTable(
          this.activeResult,
          parseInt(this.selectedPointIndex[0]),
          this.state.value(SelectedRequirementColumnsState)
        );
      });
  }

  private getUnitFromAttribute(attribute: string): UnitType {
    switch (attribute) {
      case 'speeds':
        return UnitType.ANGULAR_SPEED;
      case 'in_torques':
      case 'out_torques':
        return UnitType.TORQUE;
      case 'in_powers':
      case 'out_powers':
        return UnitType.POWER;
      case 'losses':
        return UnitType.POWER;
      case 'in_voltages':
      case 'out_voltages':
        return UnitType.VOLTAGE;
      case 'currents_d':
      case 'currents_q':
        return UnitType.CURRENT;
      case 'efficiencies':
        return UnitType.ROAD_EFFICIENCY;
      default:
        return UnitType.RATIO;
    }
  }

  private setFromEnv(env: ConfigurationEnvironment): void {
    this.vehicleOverviewGradient = env.gradient || 0;
    this.vehicleOverviewSpeed = env.speed || 50;
    this.vehicleOverviewAcceleration = env.acceleration || 0;
    this.vehicleOverviewAltitude = env.altitude || 0;
    this.vehicleOverviewHeadwind = env.headwind || 0;
    this.vehicleOverviewStepsize = env.stepsize || 0.2;
  }
}
