import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { IconComponent } from '@ansys/awc-angular/icons';
import { DIALOG_DATA, DIALOG_REF } from '@ansys/andromeda/shared';
import {
  ButtonComponent,
  ButtonSize,
  ButtonType,
} from '@ansys/awc-angular/buttons';
import { DialogComponent } from '@ansys/awc-angular/popups';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [IconComponent, ButtonComponent],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  protected type: ButtonType = ButtonType.PRIMARY;
  protected size: ButtonSize = ButtonSize.MEDIUM;

  constructor(
    @Inject(DIALOG_DATA) public data: { message?: string },
    @Inject(DIALOG_REF) public dialogRef: DialogComponent,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {
    this.data = this.data || { message: '' };
  }

  ngOnInit(): void {
    console.log(this.data?.message);
    this.cdr.detectChanges(); // Trigger a manual change detection
  }
}
