import { Component, Inject } from '@angular/core';
import {
  DIALOG_DATA,
  DIALOG_REF,
  DialogService,
} from '@ansys/andromeda/shared';
import { DialogComponent } from '@ansys/awc-angular/popups';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { AddPartComponent } from '../add-part/add-part.component';
import { ComponentFileType } from '../../../../api';
import { HttpResponse } from '@angular/common/http';
import {
  AddPartDialogData,
  ConceptPartType,
  PartServiceType,
} from '../../../shared/types';
import { UploadFileResponseType } from '../../../shared/types/upload-file-response.type';
import { LibraryExportComponent } from '../library-export/library-export.component';
import { Icons, IconType } from '@ansys/awc-angular/icons';
import { ConceptSections, WizardProgress } from '../../../shared/enums';
import { SectionService } from '../../../shared/services/section.service';
import { SelectComponent } from '@ansys/awc-angular/forms';
import { AWCListContent, AWCListItem } from '@ansys/awc-angular/lists';
import {
  ButtonComponent,
  ButtonSize,
  ButtonType,
} from '@ansys/awc-angular/buttons';
import { FlexLayout } from '@ansys/awc-angular/core';
import {
  componentTreeView,
  vehicleOverviewTreeView,
} from '../../../shared/models/parent-tree-items.model';

type ServiceType = PartServiceType;

@Component({
  selector: 'app-select-item-to-create-dialog',
  standalone: true,
  imports: [SelectComponent, ButtonComponent],
  templateUrl: './select-item-to-create-dialog.component.html',
  styleUrl: './select-item-to-create-dialog.component.scss',
})
export class SelectItemToCreateDialogComponent {
  private get service(): ServiceType {
    if (!this.$service)
      this.$service = this.sectionService.getService(
        this.type as ConceptSections
      );
    return this.$service;
  }
  protected type: string = '';
  protected suffixIcon!: IconType;
  protected button = ButtonType;
  protected alttype: ButtonType = ButtonType.SECONDARY;
  protected size: ButtonSize = ButtonSize.MEDIUM;
  protected placeholder: string = 'Select an option...';
  protected addGeometry: Icons = Icons.ADD_GEOMETRY;
  protected layout: FlexLayout = FlexLayout.COLUMN; // TODO: ??
  protected readonly conceptSections = ConceptSections;
  protected itemTypes: AWCListContent[] = [
    {
      id: ConceptSections.CONFIGURATIONS,
      text: 'Configuration',
      icon: { src: 'assets/icons/vehicle.svg' },
    },
    {
      id: ConceptSections.COMPONENT,
      text: 'Component',
      icon: { icon: Icons.MODELER },
    },
  ];

  // TODO: Items should be fetched from service
  protected configItems: AWCListContent[] = vehicleOverviewTreeView;
  protected componentItems: AWCListContent[] = componentTreeView;
  protected selectedItemType!: string;
  protected selectedItem!: string;
  protected step: WizardProgress = WizardProgress.START;
  protected readonly wizardProgress = WizardProgress;
  private $service!: ServiceType;

  constructor(
    @Inject(DIALOG_DATA) protected data: any,
    @Inject(DIALOG_REF) private dialogRef: DialogComponent,
    private sectionService: SectionService,
    private dialogService: DialogService
  ) {}

  protected selectionItemTypeChanged(item: AWCListItem): void {
    this.selectedItemType = item.id;
  }

  protected selectionItemChanged(item: AWCListItem): void {
    this.selectedItem = item.id;
  }

  protected confirmItemType(): void {
    const item = this.itemTypes.find(
      (item) => (item as AWCListItem).id === this.selectedItemType
    ) as AWCTreeItem;

    this.type = item.id;

    this.step = WizardProgress.END;
  }

  protected confirmItem(): void {
    const items =
      this.type === ConceptSections.CONFIGURATIONS
        ? this.configItems
        : this.componentItems;

    const item = items.find(
      (item) => (item as AWCListItem).id === this.selectedItem
    ) as AWCTreeItem;

    this.exportNewItem(item);
  }

  private exportNewItem(item: AWCTreeItem): void {
    const dialog = this.dialogService.open(AddPartComponent, {
      title: `Add Concept ${this.type} To Library`,
      data: {
        type: this.type,
        parent: item,
        step: WizardProgress.MIDDLE,
        onUploadFilePart: async (
          file: File,
          type: ComponentFileType
        ): Promise<any> => {
          if (this.service.uploadFilePart) {
            const uploadedFile: HttpResponse<any> = await this.service
              .uploadFilePart(file, type)
              .then((fileResponse) => {
                return fileResponse as HttpResponse<any>;
              })
              .catch((e) => {
                throw new Error(e);
              });

            const [id, responseObject] = uploadedFile.body;

            return { id, responseObject };
          }
        },
        onConfirm: (
          addedItem: ConceptPartType,
          file: File,
          type: ComponentFileType,
          uploadedFile?: UploadFileResponseType
        ) => {
          dialog.close();
          this.dialogService.open(LibraryExportComponent, {
            title: `Export ${this.type}`,
            data: {
              type: this.type,
              requestBody: addedItem,
              uploadedFile,
            },
          });
        },
      } as AddPartDialogData,
    });
    this.dialogRef.close();
  }
}
