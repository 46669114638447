/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InverterIGBTData } from './InverterIGBTData';
import type { InverterMOSFETData } from './InverterMOSFETData';
import type { InverterSimpleData } from './InverterSimpleData';
/**
 * Inverter model in DB.
 */
export type InverterAnalyticalInDB = {
    item_type?: InverterAnalyticalInDB.item_type;
    name?: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: InverterAnalyticalInDB.component_type;
    inverter_data: (InverterSimpleData | InverterIGBTData | InverterMOSFETData);
    _id?: string;
    id?: string;
};
export namespace InverterAnalyticalInDB {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
      INVERTER_ANALYTICAL = 'InverterAnalytical',
    }
}

