import { Icons } from '@ansys/awc-angular/icons';

export const itemIconMap = new Map([
  ['vehicle', { src: 'assets/icons/vehicle.svg' }],
  ['ancillary_load', { icon: Icons.ACOUSTIC }],
  ['deceleration_limit', { icon: Icons.TRACTION_LOAD }],
  ['mass', { icon: Icons.PRESSURE_LOAD }],
  ['wheel', { icon: Icons.RADIO_ACTIVE }],
  ['aero', { icon: Icons.STREAM_LINES }],
  ['transmission', { icon: Icons.SETTINGS }],
  ['inverter', { icon: Icons.ELECTRICAL }],
  ['motor', { src: 'assets/icons/Motor.svg' }],
  ['battery', { src: 'assets/icons/battery.svg' }],
  ['clutch', { src: 'assets/icons/clutch.svg' }],
  ['static_requirement', { icon: Icons.PIN }],
  ['dynamic_requirement', { icon: Icons.ROCKET }],
  ['drive_cycle', { icon: Icons.ELECTRICAL }],
]);
