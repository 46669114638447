/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppThemeState, State } from '@ansys/andromeda/store';
import { AWCTableColumns, AWCTableRow } from '@ansys/awc-angular/tables';
import { PlotState } from '../../state/lib/plot.state';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { Layout } from 'plotly.js';
import { DefaultChartAxisColourConfig } from '../../actions';

@Injectable({
  providedIn: 'root',
})
export class PlotService {
  /**
   * Subject to subscribe to graph data
   */
  private pointIndex = new BehaviorSubject<string[]>(['']);
  private tableData = new BehaviorSubject<
    [AWCTableColumns[], AWCTableRow[], string[], string[]]
  >([[], [], [], []]);
  public activePointIndex = this.pointIndex.asObservable();
  public activeTableData = this.tableData.asObservable();

  constructor(
    private state: State,
    private actions: ActionContributionsService
  ) {}

  /**
   * Set graph data
   */
  public setGraphData(data: any): void {
    this.state.set(PlotState, {
      ...this.state.value(PlotState),
      data,
    });
  }

  public async resetGraphLayout(): Promise<void> {
    this.state.reset(PlotState);
  }

  public async clearGraph(): Promise<void> {
    await this.resetGraphLayout();
    this.setGraphData([]);
    this.state.reset(PlotState);
  }

  public async updateGraphLayout(layout: Partial<Layout>): Promise<void> {
    const theme = this.state.value(AppThemeState);
    const isDarkMode =
      theme === 'dark' ||
      (theme === 'auto' &&
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches);

    this.state.set(PlotState, {
      ...this.state.value(PlotState),
      layout: {
        ...this.state.value(PlotState).layout,
        ...(await this.actions.execute(DefaultChartAxisColourConfig)),
        ...layout,
        legend: {
          ...this.state.value(PlotState).layout?.legend,
          font: { color: isDarkMode ? '#fff' : '#000' },
          ...layout.legend,
        },
        xaxis: {
          ...this.state.value(PlotState).layout?.xaxis,
          ...(await this.actions.execute(DefaultChartAxisColourConfig)),
          ...layout.xaxis,
        },
        yaxis: {
          ...this.state.value(PlotState).layout?.yaxis,
          ...(await this.actions.execute(DefaultChartAxisColourConfig)),
          ...layout.yaxis,
        },
        yaxis2: {
          ...this.state.value(PlotState).layout?.yaxis2,
          titlefont: { color: 'rgb(33, 150, 243)' },
          tickfont: { color: 'rgb(33, 150, 243)' },
          gridcolor: 'rgb(33, 150, 243)',
          gridwidth: 0.5,
          color: 'rgb(33, 150, 243)',
          ...layout.yaxis2,
        },
      },
    });
  }

  public setTableData(
    data: [AWCTableColumns[], AWCTableRow[], string[], string[]]
  ): void {
    this.setSelectedPointIndex(data[3]);
    this.tableData.next(data);
  }

  public setSelectedPointIndex(pointIndex: string[]): void {
    this.pointIndex.next(pointIndex);
  }
}
