import { ConceptSections } from '../enums';

export const sectionItemMap = new Map<string, ConceptSections>([
  ['transmission', ConceptSections.COMPONENT],
  ['motor', ConceptSections.COMPONENT],
  ['inverter', ConceptSections.COMPONENT],
  ['battery', ConceptSections.COMPONENT],
  ['clutch', ConceptSections.COMPONENT],
  ['aero', ConceptSections.CONFIGURATIONS],
  ['wheel', ConceptSections.CONFIGURATIONS],
  ['mass', ConceptSections.CONFIGURATIONS],
  ['ancillary_load', ConceptSections.CONFIGURATIONS],
  ['deceleration_limit', ConceptSections.CONFIGURATIONS],
]);
