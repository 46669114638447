<div class="loading-cont">
  @if (data.message) {
    <div>
      {{ data.message }}
    </div>
  }

  <div class="confirm-dialog">
    <awc-button
      text="OK"
      [type]="type"
      [size]="size"
      (clicked)="dialogRef.close()"></awc-button>
  </div>
</div>
