import {
  DIALOG_DATA,
  DIALOG_REF,
  DialogService,
} from '@ansys/andromeda/shared';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { ButtonSize, ButtonType } from '@ansys/awc-angular/buttons';
import { FlexLayout } from '@ansys/awc-angular/core';
import { InputType } from '@ansys/awc-angular/forms';
import { IconType, Icons } from '@ansys/awc-angular/icons';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ConceptSections } from 'src/app/shared/enums/concept.enum';
import { AddPartDialogData } from 'src/app/shared/types';
import { WizardProgress, PartType } from 'src/app/shared/enums';
import { ConceptPartType } from 'src/app/shared/types';
import { selectItemMap } from './lib/select-item-map';
import { defaultPartsMap } from './lib/default-parts-map';
import { ImportLibraryAssetComponent } from '../import-library-asset/import-library-asset.component';
import { DialogComponent } from '@ansys/awc-angular/popups';
import { ComponentFileType } from '../../../../api';
import { UploadFileResponseType } from '../../../shared/types/upload-file-response.type';

@Component({
  selector: 'app-add-part',
  templateUrl: './add-part.component.html',
  styleUrls: ['./add-part.component.scss'],
})
export class AddPartComponent {
  @ViewChild('fileUpload') upload!: ElementRef;
  public step: WizardProgress = WizardProgress.START;
  protected readonly steps = WizardProgress;
  protected readonly partTypes = PartType;
  protected type: ButtonType = ButtonType.PRIMARY;
  protected alttype: ButtonType = ButtonType.SECONDARY;
  protected size: ButtonSize = ButtonSize.MEDIUM;
  protected smallsize: ButtonSize = ButtonSize.SMALL;
  protected suffixIcon!: IconType;
  protected libraryIcon: IconType = {
    src: 'https://s2.svgbox.net/octicons.svg?ic=book',
  };
  protected label: string = '';
  protected placeholder: string = 'Select an option...';
  protected items: AWCListItem[] = [];
  protected iconButton: Icons = Icons.ADD_GEOMETRY;
  protected spinner: Icons = Icons.SPINNER;
  protected layout: FlexLayout = FlexLayout.COLUMN;
  protected addIcon: IconType = { icon: Icons.ADD };
  protected spinnerIconType: IconType = { icon: Icons.SPINNER };
  protected checkIconType: IconType = { icon: Icons.CHECK };
  protected selectedItems: string[] = [];
  protected loading: boolean = false;
  protected uploadedFile?: File;
  protected defaultPart: any = { name: '' };
  protected numberType: InputType = InputType.NUMBER;
  protected fileTypeAcceptance: string = '';
  protected selectedItem: AWCListItem | undefined;
  protected disableDropdown = false;
  protected uploadingFilePart: boolean = false;
  protected uploadedFilePart?: UploadFileResponseType;
  protected partsLoaded: boolean = true;
  private userConfirmed: boolean = false;
  private selectItemMap: Map<string, AWCListItem[]> = selectItemMap;
  private defaultParts: Map<PartType, ConceptPartType> = defaultPartsMap as Map<
    PartType,
    ConceptPartType
  >;

  constructor(
    @Inject(DIALOG_DATA) public data: AddPartDialogData,
    @Inject(DIALOG_REF) public dialogRef: DialogComponent,
    private dialogService: DialogService,
    private _cdr: ChangeDetectorRef
  ) {
    this.suffixIcon = this.data.parent.prefixIcon as IconType;
    this.selectedItems = ['item1'];
    this.stepWizard(); // Skipping step 1 till such a time when we get library filtering for the 'Import from Library' option
  }

  ngOnInit(): void {
    this.items = this.selectItemMap.get(this.data.parent.id) as AWCListItem[];

    if (this.data.step) {
      const { type, parent, step } = this.data;

      if (
        (type === ConceptSections.CONFIGURATIONS ||
          type === ConceptSections.REQUIREMENT ||
          parent.id === 'clutch') &&
        step === WizardProgress.MIDDLE
      ) {
        this.step = WizardProgress.END;
      } else {
        this.step = step;
      }
    }

    if (this.items.length) {
      this.selectedItem = this.items[0];
      this.getFileTypeFromSelectedItem();
    }

    this.defaultPart = this.defaultParts.get(
      this.data.parent.id as PartType
    ) || { name: '' };
  }

  protected iconButtonAction(): void {
    if (this.selectedItem?.userData && this.selectedItem.userData['upload']) {
      // Prevent the dropdown from opening
      this.disableDropdown = true;
      setTimeout(() => {
        this.disableDropdown = false;
      });
      // find relevant id to use for default part map
      const refId =
        (this.data.parent.id as PartType) === PartType.MOTOR &&
        this.selectedItem.userData['type'] !== 'motor_torque_grid_file'
          ? this.selectedItem.userData['type']
          : this.data.parent.id;
      this.defaultPart = this.selectedItem.userData['defaultPart'] ??
        this.defaultParts.get(refId as PartType) ?? { name: '' };

      this.upload.nativeElement.click();
    } else {
      if (this.data.parent.id === 'inverter') {
        this.defaultPart.data = this.selectedItem?.userData;
      }
      this.step = WizardProgress.END;
    }
  }

  protected selectionChanged($event: AWCListItem[]): void {
    this.selectedItems = [$event[0].id];
    this.selectedItem = $event[0];
    this.getFileTypeFromSelectedItem();
  }

  protected stepWizard(): void {
    if (
      this.data.type === ConceptSections.CONFIGURATIONS ||
      this.data.type === ConceptSections.REQUIREMENT ||
      this.data.parent.id === 'clutch'
    ) {
      this.step = WizardProgress.END;
    } else {
      this.step++;
    }
  }

  protected addGear(): void {
    this.defaultPart.gear_ratios.push(1);
    this.defaultPart.fixed_efficiencies.push(100);
  }

  protected clickedLibrary(): void {
    // TODO: Wait for filtering to become available in awf-table to filter by `type`
    this.dialogService.open(ImportLibraryAssetComponent, {
      title: 'Library',
      data: { type: this.data.parent.id },
    });
    this.dialogRef.close();
  }

  /**
   * Import file event
   * @param {any} $event
   */
  protected async importFile($event: Event): Promise<void> {
    if (this.uploadingFilePart) {
      return;
    }

    const input = $event.target as HTMLInputElement;
    if (!input?.files?.length) return;
    const file: File = input.files[0];
    if (file) {
      this.uploadedFile = file;
      this.defaultPart = {
        ...this.defaultPart,
        name: file.name.split('.')[0],
      };
      this.step = WizardProgress.END;

      // Start upload process early
      if (this.data.onUploadFilePart) {
        this.uploadingFilePart = true;
        try {
          this.uploadedFilePart = await this.data.onUploadFilePart(
            this.uploadedFile,
            this.selectedItem?.userData
              ? (this.selectedItem.userData['type'] as ComponentFileType)
              : ('' as ComponentFileType)
          );
        } catch (e) {
          this.uploadingFilePart = false;
          this.uploadedFile = undefined;
          this.defaultPart = {
            ...this.defaultPart,
            name: '',
          };
          this.step = WizardProgress.MIDDLE;
        }

        // If by the time the file has uploaded, the user has submitted the next stage, action it here
        if (this.userConfirmed) {
          this.dataConfirm();
        }
        this.uploadingFilePart = false;
      }
    }

    this._cdr.detectChanges();
  }

  protected setPart(
    value: string | number,
    type: string,
    index?: number
  ): void {
    if (index !== undefined) {
      this.defaultPart[type][index] = value;
      return;
    }
    this.defaultPart = {
      ...this.defaultPart,
      [type]: value,
    } as ConceptPartType;
  }

  protected uploadClick(): void {
    this.upload.nativeElement.value = '';
  }

  protected confirm(): void {
    this.loading = true;
    if (this.uploadingFilePart) {
      // File part is still uploading. Handle data.onConfirm in importFile() method
      this.userConfirmed = true;
    } else {
      this.dataConfirm();
    }
  }

  private dataConfirm(): void {
    this.data.onConfirm(
      this.defaultPart,
      this.uploadedFile,
      this.selectedItem?.userData
        ? (this.selectedItem.userData['type'] as string)
        : '',
      this.uploadedFilePart
    );
  }

  private getFileTypeFromSelectedItem(): void {
    this.fileTypeAcceptance = (
      this.selectedItem?.userData?.['file_types'] as string[]
    )?.join(', ');
  }
}
