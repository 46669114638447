/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TransmissionLossMapData } from './TransmissionLossMapData';
/**
 * Transmission In DB.
 */
export type TransmissionLossMapInDB = {
    item_type?: TransmissionLossMapInDB.item_type;
    name?: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: TransmissionLossMapInDB.component_type;
    shift_time?: number;
    loss_map: TransmissionLossMapData;
    moment_of_inertia_wheel_side?: number;
    _id?: string;
    id?: string;
};
export namespace TransmissionLossMapInDB {
  export enum item_type {
    COMPONENT = 'component',
  }
    export enum component_type {
        TRANSMISSION_LOSS_MAP = 'TransmissionLossMap',
    }
}

