<div class="job-list-cont" [style.height]="maxHeight + 'px'">
  <ng-container *ngIf="!selectedJob; else selectedJobCont">
    <ng-container *ngFor="let job of jobsList">
      <div
        class="job-list-item"
        (click)="selectJob(job)"
        *ngIf="job.jobId"
        [awcTooltip]="job.jobName || ''"
        [ngClass]="{
          failed: isFailed(job),
          complete: isComplete(job)
        }"
      >
        <div class="job-list-item-meta">
          <div class="job-list-item-title">
            <ng-container *ngIf="job.jobName && job.jobId; else generatingJob">
              {{ job.jobName }}
            </ng-container>
            <ng-template #generatingJob>
              <div class="generating">
                <awc-icon [icon]="Icons.SPINNER" [size]="iconXSmall"></awc-icon>
                <span>Generating Job Information...</span>
              </div>
            </ng-template>
          </div>

          <div class="job-list-item-status" [ngClass]="{ failed: isFailed(job), complete: isComplete(job) }">
            <span class="job-list-item-status-date">
              <ng-container *ngIf="job.queuedStatusDate; else checkLastStatusDate">
                {{ job.queuedStatusDate | date: 'mediumDate' }}
              </ng-container>
              <ng-template #checkLastStatusDate>
                <ng-container *ngIf="job.lastStatusDate; else useNow">
                  {{ job.lastStatusDate | date: 'mediumDate' }}
                </ng-container>
              </ng-template>
              <ng-template #useNow>
                {{ currentDate | date: 'mediumDate' }}
              </ng-template>
            </span>
            <span class="job-list-item-status-state" [ngClass]="{ failed: isFailed(job), complete: isComplete(job) }">
              {{ (barlabels[job.jobId] || job.finalStatus || job.lastStatus || 'CREATED') | uppercase }}
            </span>
          </div>

          <div class="delete-wrapper">
            <div class="delete-cont">
              <div
                *ngIf="['FAILED', 'FINISHED', 'PAUSED', 'CANCELLED', 'COMPLETED'].includes((job.finalStatus || job.lastStatus) ?? '')"
                class="result-list-action-icons"
                (click)="onDeleteJob($event, job)"
              >
                <awc-icon [icon]="Icons.DELETE" [size]="iconSmall"></awc-icon>
              </div>
              <div
                *ngIf="['FAILED', 'FINISHED', 'PAUSED', 'CANCELLED', 'COMPLETED'].includes((job.finalStatus || job.lastStatus) ?? '')"
                class="result-list-action-icons"
                (click)="onRenameJob($event, job)"
                [awcTooltip]="'Rename Job'"
              >
                <awc-icon [icon]="Icons.EDIT" [size]="iconSmall"></awc-icon>
              </div>
              <div
                *ngIf="['CREATED', 'QUEUED', 'RUNNING', 'PAUSED'].includes((job.finalStatus || job.lastStatus) ?? '')"
                class="result-list-action-icons"
                (click)="onDeleteJob($event, job)"
              >
                <awc-icon [icon]="Icons.CLOSE" [size]="iconSmall"></awc-icon>
              </div>
            </div>
          </div>

          <div class="progress-bar">
            <awc-progress-bar
              *ngIf="job.jobId && (loadingBar[job.jobId] === undefined || loadingBar[job.jobId])"
              [color]="loadingcolor"
              [loading]="true"
            ></awc-progress-bar>
            <awc-progress-bar
              *ngIf="job.jobId && loadingBar[job.jobId] !== undefined && !loadingBar[job.jobId]"
              [color]="color"
              [loading]="false"
              [progressBarItem]="progressBars[job?.jobId || '']"
            ></awc-progress-bar>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #selectedJobCont>
    <div class="selected-job-cont">
      <div class="selected-job-action" (click)="deselectJob(true)">
        <awc-icon [icon]="Icons.LEFT_ARROW"></awc-icon>
        <span>Back to jobs</span>
      </div>
      <ng-container *ngIf="!$resultsLoaded; else resultsLoaded">
        <awc-icon [icon]="Icons.SPINNER"></awc-icon>
      </ng-container>
      <ng-template #resultsLoaded>
        <div class="selected-job-item-cont">
          <ng-container *ngFor="let result of results;">
            <div class="selected-job-item" [ngClass]="selectedResult === result.id ? 'selected-job-item-active' : ''" (click)="selectResult(result)">
              <awc-icon *ngIf="result.requirement_solved_type === ResultType.DRIVE_CYCLE" [icon]="Icons.ELECTRICAL" [color]="result.feasible ? 'green' : 'red'"></awc-icon>
              <awc-icon *ngIf="result.requirement_solved_type === ResultType.DYNAMIC" [icon]="Icons.ROCKET" [color]="result.feasible ? 'green' : 'red'"></awc-icon>
              <awc-icon *ngIf="result.requirement_solved_type === ResultType.STATIC" [icon]="Icons.PIN" [color]="result.feasible ? 'green' : 'red'"></awc-icon>
              <span>{{result.requirement.name}}</span>
              <awc-icon *ngIf="result.requirement_solved_type === ResultType.DRIVE_CYCLE && result.warnings && Object.keys(result.warnings).length > 0" [icon]="Icons.WARNING" (click)="onDriveCycleWarning(result)" [color]="ColorBackground.SECONDARY_DEFAULT" class="requirement-warning-icon"></awc-icon>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
