/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComponentAxle } from './ComponentAxle';
import type { ComponentSide } from './ComponentSide';
/**
 * Solved road node.
 */
export type SolvedRoad = {
    name: string;
    axle?: ComponentAxle;
    side?: ComponentSide;
    mass?: number;
    cost?: number;
    in_powers: Array<number>;
    out_powers: Array<number>;
    losses: Array<number>;
    losses_ratio: Array<number>;
    solved_component_type?: SolvedRoad.solved_component_type;
    speeds: Array<number>;
    in_torques: Array<number>;
    out_torques: Array<number>;
};
export namespace SolvedRoad {
    export enum solved_component_type {
        ROAD = 'road',
    }
}

