<awc-grid
  [config]="config">
  <awf-dashboard-panel [padding]="paddingZero">
    <div class="panel-cont" [style.height]="maxHeight()+'px'" [ngStyle]="{'--featured-image': dataDisplay.displayState !== DataDisplayState.GRAPH_DISPLAY ? bgImage : ''}">
      <ng-container [ngSwitch]="dataDisplay.displayState">
        <ng-container *ngSwitchCase="DataDisplayState.LOADING">
          <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
            <awc-icon [icon]="Icons.SPINNER"></awc-icon>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="DataDisplayState.PROGRESS">
          <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
            <awc-progress-bar
              style="width: 80%;"
              [color]="color"
              [label]="dataDisplay.message"
              [loading]="!dataDisplay.progress()"
              [progressBarItem]="dataDisplay.progressItem()"
              ></awc-progress-bar>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="DataDisplayState.CONFIG_HELP">
          <div class="helper-cont">
            <div class="large-icon">
              <awc-icon src="assets/icons/vehicle.svg"></awc-icon>
            </div>
            <div class="helper-item">
              <awc-icon [icon]="Icons.CHECKED" [ngStyle]="{ 'visibility': selectedConfigurations.aero ? 'visible' : 'hidden' }"></awc-icon>
              <span *ngIf="!selectedConfigurations.aero;">Add/Select Aerodynamic Configuration</span>
              <span *ngIf="selectedConfigurations.aero;">Aerodynamic Configuration Selected</span>
            </div>
            <div class="helper-item">
              <awc-icon [icon]="Icons.CHECKED" [ngStyle]="{ 'visibility': selectedConfigurations.mass ? 'visible' : 'hidden' }"></awc-icon>
              <span *ngIf="!selectedConfigurations.mass;">Add/Select Mass Configuration</span>
              <span *ngIf="selectedConfigurations.mass;">Mass Configuration Selected</span>
            </div>
            <div class="helper-item">
              <awc-icon [icon]="Icons.CHECKED" [ngStyle]="{ 'visibility': selectedConfigurations.wheel ? 'visible' : 'hidden' }"></awc-icon>
              <span *ngIf="!selectedConfigurations.wheel;">Add/Select Wheel Configuration</span>
              <span *ngIf="selectedConfigurations.wheel;">Wheel Configuration Selected</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="DataDisplayState.COMPONENT_HELP">
          <div class="helper-cont">
            <div class="large-icon">
              <awc-icon [icon]="Icons.MODELER"></awc-icon>
            </div>
            <div class="helper-item">
              <awc-icon [icon]="Icons.UNCHECKED" ></awc-icon>
              <span>Add/Select Component</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="DataDisplayState.ARCHITECTURE">
          <app-motor-architecture *ngIf="template" [template]="template" (loadMaxSpeed)="handleLoadMaxSpeed()"></app-motor-architecture>
        </ng-container>
        <ng-container *ngSwitchCase="DataDisplayState.REQUIREMENT_HELP">
          <div class="helper-cont">
            <div class="helper-item" style="z-index: 4;" *ngIf="showResultsButton; else selectResults">
              <awc-button
                text="Go to Results"
                [type]="goToButtonType"
                [size]="size"
                [suffixIcon]="resultsIcon"
                (clicked)="goToResults()"></awc-button>
            </div>
            <ng-template #selectResults>
              <div class="helper-item">
                <span>Select Results</span>
              </div>
            </ng-template>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="DataDisplayState.GRAPH_DISPLAY">
          <app-graph-display [section]="selectedSection"></app-graph-display>
        </ng-container>
        <ng-container *ngSwitchCase="DataDisplayState.TABLE_DISPLAY">
          <app-table-display [section]="selectedSection" [part]="selectedItem" [result]="activeResult" ></app-table-display>
        </ng-container>
        <ng-container *ngSwitchCase="DataDisplayState.BLUEPRINT_DISPLAY">
          <app-blueprint-display [section]="selectedSection" [part]="selectedItem" [result]="activeResult" ></app-blueprint-display>
        </ng-container>
        <ng-container *ngSwitchCase="DataDisplayState.NO_DATA">
          <app-no-data-display [part]="selectedItem"></app-no-data-display>
        </ng-container>
      </ng-container>
    </div>
  </awf-dashboard-panel>
  <section-card
    [sections]="subsections"
    [(selectedSectionId)]="selectedSubSection">
    <div class="h100 config-cont" [slot]="ConceptSubSection.MAIN">
      <app-active-job-display
        *ngIf="selectedSection === ConceptSections.REQUIREMENT && !showResultsButton; else checkOtherSections"
        [result]="activeResult">
      </app-active-job-display>

      <ng-template #checkOtherSections>
        <app-architecture-data-panel *ngIf="selectedSection === ConceptSections.ARCHITECTURE; else activeSelection" [onLoadMaxSpeed]="maxSpeedLoad"></app-architecture-data-panel>
      </ng-template>

      <ng-template #activeSelection>
        <active-selection-edit-inputs
          [filteredItems]="filteredItems"
          [type]="type">
        </active-selection-edit-inputs>
      </ng-template>
    </div>
    <div class="h100 config-cont" [slot]="ConceptSubSection.PLOTS">
      <app-data-config [plotConfigType]="plotConfigType" [(showResultsButton)]="showResultsButton" [items]="filteredItems"></app-data-config>
    </div>
  </section-card>
</awc-grid>
