import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TITLE,
  AUTH_PROVIDER,
  AuthProvider,
  AUTH_ENVIRONMENT,
  MSAL_CLIENT_ID,
  APP_URL,
  MSAL_B2C_SIGNUP_SIGNIN_AUTHORITY,
  MSAL_B2C_AUTHORITY_DOMAIN,
  MSAL_ID_PORTAL_SCOPE,
} from '@ansys/andromeda/auth';
import {
  ANALYTICS_PRODUCT,
  DISABLE_ANALYTICS,
} from '@ansys/andromeda/analytics';
import {
  AWFLocationMeta,
  AWFQueryInterceptor,
  PRODUCT_NAME,
  URL_QUERY_INTERCEPTOR,
} from '@ansys/andromeda/shared';
import { RIGHT_NAV, SECTION_HEADER } from '@ansys/andromeda/workspace';
import {
  DASHBOARD_CONFIG,
  FileHierarchy,
  INSTANCE_ICON,
  INSTANCE_TAB_TYPE,
  INSTANCE_TITLE,
  LIBRARY_RESOURCE_PANEL,
  PROJECT_FILE_HIERARCHY,
  PROJECT_SHARE_STRATEGY,
  ProjectSharingStrategy,
  TEMPLATE_LIST,
} from '@ansys/andromeda/dashboard';
import { environment } from '../../environments/environment';
import { ConceptTitleComponent } from '../components/widgets/concept-title/concept-title.component';
import { ConceptNavigationToolsComponent } from '../components/widgets/concept-navigation-tools/concept-navigation-tools.component';
import { LibraryPanelComponent } from '../components/widgets/library-panel/library-panel.component';
import { REDUX_STORE } from '@ansys/andromeda/store';
import { ConceptUrlInterceptor } from '../shared/services/url-interceptor.service';
import { TemplateListService } from '../shared/services/template-list.service';
import { BFF_GRAPH_URL } from '@ansys/portal-bff-angular-client';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: TITLE,
      useValue: 'ConceptEV',
    },
    {
      provide: DISABLE_ANALYTICS,
      useValue: false,
    },
    {
      provide: ANALYTICS_PRODUCT,
      useValue: 'conceptev',
    },
    {
      provide: AUTH_ENVIRONMENT,
      useValue: environment.type,
    },
    {
      provide: AUTH_PROVIDER,
      useValue: AuthProvider.MSAL,
    },
    {
      provide: INSTANCE_TAB_TYPE,
      useValue: 'concept',
    },
    {
      provide: MSAL_CLIENT_ID,
      useValue: environment.msalClientId,
    },
    {
      provide: PRODUCT_NAME,
      useValue: 'CONCEPTEV',
    },
    {
      provide: APP_URL,
      useValue: environment.appURL,
    },
    {
      provide: SECTION_HEADER,
      useValue: ConceptTitleComponent,
    },
    {
      provide: RIGHT_NAV,
      useValue: ConceptNavigationToolsComponent,
    },
    {
      provide: LIBRARY_RESOURCE_PANEL,
      useValue: LibraryPanelComponent,
    },
    {
      provide: INSTANCE_TITLE,
      useValue: 'Concept',
    },
    {
      provide: REDUX_STORE,
      useValue: false,
    },
    {
      provide: INSTANCE_ICON,
      useValue: {
        src: 'assets/icons/vehicle.svg',
      },
    },
    {
      provide: BFF_GRAPH_URL,
      useValue: environment.bff,
    },
    {
      provide: MSAL_B2C_SIGNUP_SIGNIN_AUTHORITY,
      useValue: environment.msalb2c,
    },
    {
      provide: MSAL_B2C_AUTHORITY_DOMAIN,
      useValue: environment.msalAuthDomain,
    },
    {
      provide: MSAL_ID_PORTAL_SCOPE,
      useValue: environment.msalPortalScope,
    },
    {
      provide: URL_QUERY_INTERCEPTOR,
      useClass: ConceptUrlInterceptor,
      multi: true,
    },
    {
      provide: TEMPLATE_LIST,
      useClass: TemplateListService,
    },
    {
      provide: PROJECT_FILE_HIERARCHY,
      useValue: FileHierarchy.INSTANCED,
    },
    {
      provide: PROJECT_SHARE_STRATEGY,
      useValue: ProjectSharingStrategy.EDITOR_ONLY,
    },
  ],
})
export class TokensModule {}
