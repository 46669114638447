<ng-container *featureFlag="featureFlagsEnum.VIEW_SECTION_BREADCRUMBS" >
 @if (!conceptService.loadingErrorMessage()) {
      <div *ngIf="display" class="breadcrumb-cont">
        <div class="breadcrumb-inner">
          <span [awcTooltip]="projectTitle" *ngIf="!loading else loadingSkeleton">{{ projectTitle }} </span>
          <span>/</span>
          <span *ngIf="!loading else loadingSkeleton" [awcTooltip]="branchTitle">{{ branchTitle }}</span>
          <span>/</span>
          <div *ngIf="!loading else loadingSkeleton" [awcTooltip]="versionTitle" class="version-title">
            {{ versionTitle }}
          </div>
        </div>
      </div>

    <ng-template #loadingSkeleton>
      <awc-loading-skeleton
        [width]="width"
        [lineHeight]="lineHeight"
        [type]="type"
      ></awc-loading-skeleton>
    </ng-template>
  }
</ng-container>
