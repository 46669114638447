/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { JobInput } from '../models/JobInput';
import type { JobStart } from '../models/JobStart';
import type { JobStatus } from '../models/JobStatus';
import type { RequirementsSolved } from '../models/RequirementsSolved';
import type { SubmittedJob } from '../models/SubmittedJob';
import type { UploadedFile } from '../models/UploadedFile';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class JobsService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Validate Requirement Job
     * Checks if job requirements are valid.
     * @param designInstanceId
     * @param accountId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public validateRequirementJobJobsValidatePost(
        designInstanceId: string,
        accountId: string,
        requestBody: JobInput,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/jobs:validate',
            query: {
                'design_instance_id': designInstanceId,
                'account_id': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Requirement Job
     * Create job for a requirement and architecture.
     * @param designInstanceId
     * @param accountId
     * @param requestBody
     * @returns any[] Successful Response
     * @throws ApiError
     */
    public createRequirementJobJobsPost(
        designInstanceId: string,
        accountId: string,
        requestBody: JobInput,
    ): Observable<any[]> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/jobs',
            query: {
                'design_instance_id': designInstanceId,
                'account_id': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Job Endpoint
     * Delete Job from Concept.
     * @param designInstanceId
     * @param accountId
     * @param itemId
     * @returns void
     * @throws ApiError
     */
    public deleteJobEndpointJobsDelete(
        designInstanceId: string,
        accountId: string,
        itemId: string,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/jobs',
            query: {
                'design_instance_id': designInstanceId,
                'account_id': accountId,
                'item_id': itemId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start Job
     * Start a job.
     * @param designInstanceId
     * @param accountId
     * @param requestBody
     * @returns SubmittedJob Successful Response
     * @throws ApiError
     */
    public startJobJobsStartPost(
        designInstanceId: string,
        accountId: string,
        requestBody: JobStart,
    ): Observable<SubmittedJob> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/jobs:start',
            query: {
                'design_instance_id': designInstanceId,
                'account_id': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Request Status
     * Request status of job.
     * @param designInstanceId
     * @param requestBody
     * @returns JobStatus Successful Response
     * @throws ApiError
     */
    public requestStatusJobsStatusPost(
        designInstanceId: string,
        requestBody: SubmittedJob,
    ): Observable<JobStatus> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/jobs:status',
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Request Result
     * Get result.
     * @param resultsFileName
     * @param designInstanceId
     * @param requestBody
     * @param calculateUnits
     * @returns RequirementsSolved Successful Response
     * @throws ApiError
     */
    public requestResultJobsResultPost(
        resultsFileName: string,
        designInstanceId: string,
        requestBody: SubmittedJob,
        calculateUnits: boolean = true,
    ): Observable<Array<RequirementsSolved>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/jobs:result',
            query: {
                'results_file_name': resultsFileName,
                'calculate_units': calculateUnits,
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Request Console Log
     * Get contents of console.log.
     * @param designInstanceId
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public requestConsoleLogJobsErrorFilePost(
        designInstanceId: string,
        requestBody: SubmittedJob,
    ): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/jobs:error_file',
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Results File Data Format
     * Update the data format of a results file form the HPC.
     * @param resultsFileName
     * @param designInstanceId
     * @param requestBody
     * @returns UploadedFile Successful Response
     * @throws ApiError
     */
    public updateResultsFileDataFormatJobsDataCompatibilityConversionPost(
        resultsFileName: string,
        designInstanceId: string,
        requestBody: SubmittedJob,
    ): Observable<UploadedFile> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/jobs:data_compatibility_conversion',
            query: {
                'results_file_name': resultsFileName,
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
}
