<ng-container *ngIf="simpleData; else newInverterStructure">
  <div class="two-col-inputs">

    <app-input
      label="Modulation Index"
      placeholder="Modulation Index..."
      [value]="part.inverter_data.modulation_index"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_modulation_index'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'modulation_index', part.id+'_modulation_index')"></app-input>
    <app-input
      label="Fixed Loss"
      placeholder="Fixed Loss..."
      [value]="simpleData.fixed_loss"
      [unit]="UnitType.POWER"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_fixed_loss'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'fixed_loss', part.id+'_fixed_loss')"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="AC Resistance"
      placeholder="AC Resistance..."
      [value]="simpleData.ac_resistance"
      [type]="InputTypes.NUMBER"
      [unit]="UnitType.RESISTANCE"
      [disableRounding]="true"
      [id]="part.id+'_ac_resistance'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'ac_resistance', part.id+'_ac_resistance')"></app-input>
    <app-input
      label="DC Resistance"
      placeholder="DC Resistance..."
      [value]="simpleData.dc_resistance"
      [unit]="UnitType.RESISTANCE"
      [type]="InputTypes.NUMBER"
      [disableRounding]="true"
      [id]="part.id+'_dc_resistance'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'dc_resistance', part.id+'_dc_resistance')"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="AC Harness Resistance"
      placeholder="AC Harness Resistance..."
      [value]="part.inverter_data.ac_harness_resistance"
      [unit]="UnitType.RESISTANCE"
      [type]="InputTypes.NUMBER"
      [disableRounding]="true"
      [id]="part.id+'_ac_harness_resistance'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'ac_harness_resistance', part.id+'_ac_harness_resistance')"></app-input>
    <app-input
      label="DC Harness Resistance"
      placeholder="DC Harness Resistance..."
      [value]="part.inverter_data.dc_harness_resistance"
      [unit]="UnitType.RESISTANCE"
      [disableRounding]="true"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_dc_harness_resistance'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'dc_harness_resistance', part.id+'_dc_harness_resistance')"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="Forward V"
      placeholder="Switch Forward Voltage..."
      [value]="simpleData.switch_forward_voltage"
      [unit]="UnitType.VOLTAGE"
      [type]="InputTypes.NUMBER"
      [disableRounding]="true"
      [id]="part.id+'_switch_forward_voltage'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'switch_forward_voltage', part.id+'_switch_forward_voltage')"></app-input>
    <app-input
      label="Per PWM"
      placeholder="Switch Per PWM Period..."
      [value]="simpleData.switch_per_pwm_period"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_switch_per_pwm_period'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'switch_per_pwm_period', part.id+'_switch_per_pwm_period')"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="Resistance"
      placeholder="Switch Resistance..."
      [value]="simpleData.switch_resistance"
      [unit]="UnitType.RESISTANCE"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_switch_resistance'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'switch_resistance', part.id+'_switch_resistance')"></app-input>
    <app-input
      label="Time"
      placeholder="Switching Time..."
      [value]="simpleData.switching_time"
      [unit]="UnitType.TIME"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_switching_time'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'switching_time', part.id+'_switching_time')"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="Cost"
      placeholder="Optional..."
      [value]="part.cost"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_cost'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'cost', part.id+'_cost'])"></app-input>
    <app-input
      label="Mass"
      placeholder="Optional..."
      [value]="part.mass"
      [unit]="UnitType.MASS"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_mass'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'mass', part.id+'_mass'])"></app-input>
  </div>
</ng-container>

<ng-template #newInverterStructure>
  <div class="two-col-inputs">
    <app-input
      label="Modulation Index"
      placeholder="Modulation Index..."
      [value]="part.inverter_data.modulation_index"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_modulation_index'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'modulation_index', part.id+'_modulation_index')"></app-input>
    <app-input
      label="AC Harness Resistance"
      placeholder="Optional"
      [value]="igbtData.ac_harness_resistance"
      [type]="InputTypes.NUMBER"
      [unit]="UnitType.RESISTANCE"
      [id]="part.id+'_ac_harness_resistance'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'ac_harness_resistance', part.id+'_ac_harness_resistance')"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="Switching Energy On"
      placeholder="Optional"
      [value]="igbtData.switching_energy_on"
      [unit]="UnitType.ENERGY"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_switching_energy_on'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'switching_energy_on', part.id+'_switching_energy_on')"></app-input>
    <app-input
      label="DC Harness Resistance"
      placeholder="Optional"
      [value]="igbtData.dc_harness_resistance"
      [unit]="UnitType.RESISTANCE"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_dc_harness_resistance'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'dc_harness_resistance', part.id+'_dc_harness_resistance')"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="Switching Energy Off"
      placeholder="Optional"
      [value]="igbtData.switching_energy_off"
      [unit]="UnitType.ENERGY"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_switching_energy_off'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'switching_energy_off', part.id+'_switching_energy_off')"></app-input>
      <app-input
      label="Switching Energy Reverse"
      placeholder="Optional"
      [value]="igbtData.switching_energy_reverse"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_switching_energy_reverse'"
      [unit]="UnitType.ENERGY"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'switching_energy_reverse', part.id+'_switching_energy_reverse')"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="Reference Current"
      placeholder="Optional"
      [value]="igbtData.current_ref"
      [unit]="UnitType.CURRENT"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_current_ref'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'current_ref', part.id+'_current_ref')"></app-input>
    <app-input
      label="Reference Voltage"
      placeholder="Optional"
      [value]="igbtData.voltage_ref"
      [type]="InputTypes.NUMBER"
      [unit]="UnitType.VOLTAGE"
      [id]="part.id+'_voltage_ref'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'voltage_ref', part.id+'_voltage_ref')"></app-input>
  </div>
  <div class="two-col-inputs">
    <concept-select
      label="PWM Definition"
      placeholder="PWM Definition"
      [items]="pwmDefinitions"
      [layout]="layout"
      [fillWidth]="true"
      [(selectedItems)]="selectedDefinition"
      (selectedChanged)="changeDefinition($event[0])"
      [style.width]="'49%'"
      ></concept-select>
    <app-input
      *ngIf="igbtData?.pwm_definition === PWMFrequencyDefinition._1"
      label="PWM Frequency"
      placeholder="Optional"
      [value]="igbtData.pwm_frequency"
      [type]="InputTypes.NUMBER"
      [unit]="UnitType.FREQUENCY"
      [id]="part.id+'_pwm_frequency'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'pwm_frequency', part.id+'_pwm_frequency')"></app-input>
    <app-input
      *ngIf="igbtData?.pwm_definition === PWMFrequencyDefinition._2"
      label="PWM Ratio"
      placeholder="Optional"
      [value]="igbtData.pwm_ratio"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_pwm_ratio'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'pwm_ratio', part.id+'_pwm_ratio')"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="Diode Voltage Drop"
      placeholder="Optional"
      [value]="igbtData.diode_voltage_drop"
      [type]="InputTypes.NUMBER"
      [unit]="UnitType.VOLTAGE"
      [id]="part.id+'_diode_voltage_drop'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'diode_voltage_drop', part.id+'_diode_voltage_drop')"></app-input>
    <app-input
      label="Diode Dynamic Resistance"
      placeholder="Optional"
      [value]="igbtData.diode_dynamic_resistance"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_diode_dynamic_resistance'"
      [unit]="UnitType.RESISTANCE"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'diode_dynamic_resistance', part.id+'_diode_dynamic_resistance')"></app-input>
  </div>
  <div class="two-col-inputs" *ngIf="igbtData.inverter_type === 'IGBT'">
    <app-input
      label="Transistor Voltage Drop"
      placeholder="Optional"
      [value]="igbtData.transistor_voltage_drop"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_transistor_voltage_drop'"
      [unit]="UnitType.VOLTAGE"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'transistor_voltage_drop', part.id+'_transistor_voltage_drop')"></app-input>
    <app-input
      label="Transistor Dynamic Resistance"
      placeholder="Optional"
      [value]="igbtData.transistor_dynamic_resistance"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_transistor_dynamic_resistance'"
      [unit]="UnitType.RESISTANCE"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'transistor_dynamic_resistance', part.id+'_transistor_dynamic_resistance')"></app-input>
  </div>
  <div class="two-col-inputs" *ngIf="mosfetData.inverter_type === 'MOSFET'">
    <app-input
      label="Drain Source On Resistance"
      placeholder="Optional"
      [value]="mosfetData.drain_source_on_resistance"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_drain_source_on_resistance'"
      [unit]="UnitType.RESISTANCE"
      [updater]="update"
      [readonly]="readonly"
      (update)="setInverterData($event, 'drain_source_on_resistance', part.id+'_drain_source_on_resistance')"></app-input>
  </div>
  <div class="two-col-inputs">
    <app-input
      label="Cost"
      placeholder="Optional..."
      [value]="part.cost"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_cost'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'cost', part.id+'_cost'])"></app-input>
    <app-input
      label="Mass"
      placeholder="Optional..."
      [value]="part.mass"
      [unit]="UnitType.MASS"
      [type]="InputTypes.NUMBER"
      [id]="part.id+'_mass'"
      [updater]="update"
      [readonly]="readonly"
      (update)="setPart.emit([''+$event, 'mass', part.id+'_mass'])"></app-input>
  </div>
</ng-template>
