/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SwitchSize } from '@ansys/awc-angular/forms';
import { ConceptInputType } from 'src/app/shared/enums/concept.enum';
import { InputTypes } from '../forms/input/input.component';
import { UnitType } from 'src/api';
import { UploadFileResponseType } from '../../../shared/types/upload-file-response.type';

@Component({
  selector: 'app-part-inputs',
  templateUrl: './part-inputs.component.html',
  styleUrls: ['./part-inputs.component.scss'],
})
export class PartInputsComponent {
  // TYPE THIS
  @Input() public part!: any;
  @Input() public type!: string;
  @Input() public fileResponse?: UploadFileResponseType;
  @Input() public forceEnable!: boolean;
  @Input() public update: boolean = false;
  @Input() readonly: boolean = false;
  @Output() public partChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateInput: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() partsLoaded: EventEmitter<boolean> = new EventEmitter(true);
  @ViewChild('fileUpload') upload!: ElementRef;
  protected partsLoadedEmitted: boolean = true;
  protected readonly InputTypes = InputTypes;
  protected readonly ConceptInputType = ConceptInputType;
  protected readonly UnitType = UnitType;
  protected size: SwitchSize = SwitchSize.MEDIUM;

  /**
   * Set the part value
   */
  protected setPart(
    value: string | number | null,
    type: string,
    id: string,
    index?: number
  ): void {
    if (index !== undefined) {
      this.part[type][index] = value;
      this.partChange.emit(this.part);
      this.update && id && this.updateInput.emit(id);
      return;
    }
    if (typeof value === 'string' && value.length === 0) {
      value = null;
    }
    this.part = {
      ...this.part,
      [type]: value,
    };
    this.partChange.emit(this.part);
    this.update && id && this.updateInput.emit(id);
  }

  protected forceUpdate(input: string): void {
    this.partChange.emit(this.part);
    this.update && this.updateInput.emit(input);
  }
}
