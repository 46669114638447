<div class="dialog-cont">
  <awc-input
    [value]="value"
    [label]="data.label"
    [placeholder]="data.placeholder"
    (valueChange)="value = $event.toString()"
    (keyup)="$event.code === 'Enter' && confirm()"
  ></awc-input>

  <div class="cta">
    <awc-button
      text="Cancel"
      (buttonClicked)="cancel()"></awc-button>
    <awc-button
      text="Confirm"
      [disabled]="submitting"
      (buttonClicked)="confirm()"></awc-button>
  </div>
</div>
