/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Aero } from './Aero';
import type { DecelerationLimit } from './DecelerationLimit';
import type { Mass } from './Mass';
import type { WheelInput } from './WheelInput';
/**
 * Static requirement with both torque and acceleration.
 */
export type StaticRequirement = {
    item_type?: StaticRequirement.item_type;
    name?: string;
    description?: string;
    mass?: Mass;
    aero?: Aero;
    wheel?: WheelInput;
    speed: number;
    deceleration_limit?: (DecelerationLimit | null);
    state_of_charge?: number;
    altitude?: number;
    headwind?: number;
    gradient?: number;
    front_axle_split?: (number | null);
    total_tractive_torque: number;
    acceleration: number;
    aero_force: number;
    mass_force: number;
    rolling_resistance_force: number;
    total_force: number;
    total_tractive_power: number;
    voltage_oc: number;
    requirement_type?: StaticRequirement.requirement_type;
};
export namespace StaticRequirement {
    export enum item_type {
        REQUIREMENT = 'requirement',
    }
    export enum requirement_type {
        STATIC = 'static',
    }
}

