<div class="axle-cont">
  <span>Front Axle</span>
  <div class="selects">
    <awc-select
      label="Number of Wheels"
      placeholder="Number of Wheels on Front Axle"
      [items]="wheelItemsFront"
      [selectedItems]="['' + (architecture.architecture()?.number_of_front_wheels ?? '2')]"
      (selectionChanged)="changeArchitectureWheels($event[0].id)"></awc-select>
    <awc-select
      label="Number of Motors"
      placeholder="Number of Motors on Front Axle"
      [items]="motorItemsFront"
      [selectedItems]="['' + (architecture.architecture()?.number_of_front_motors ?? '1')]"
      (selectionChanged)="changeArchitectureMotors($event[0].id)"></awc-select>
  </div>
</div>

<div class="axle-cont">
  <span>Rear Axle</span>
  <div class="selects">
    <awc-select
      label="Number of Wheels"
      placeholder="Number of Wheels on Rear Axle"
      [items]="wheelItemsRear"
      [selectedItems]="['' + (architecture.architecture()?.number_of_rear_wheels ?? '2')]"
      (selectionChanged)="changeArchitectureWheels($event[0].id, true)"></awc-select>
    <awc-select
      label="Number of Motors"
      placeholder="Number of Motors on Front Axle"
      [items]="motorItemsRear"
      [selectedItems]="['' + (architecture.architecture()?.number_of_rear_motors ?? '0')]"
      (selectionChanged)="changeArchitectureMotors($event[0].id, true)"></awc-select>
  </div>
</div>

<app-input
  label="Wheelbase"
  placeholder="Optional..."
  [value]="architecture.architecture()?.wheelbase ?? ''"
  [unit]="UnitType.LENGTH"
  (update)="updateWheelbase($event)"
  [updater]="true"
  [type]="InputTypes.NUMBER"></app-input>
