import { UnitType } from '../../../api';
import { ConceptUnitService } from '../services/unit.service';

export function solvedComponentHeadersMap(
  unitChoices: ConceptUnitService
): Map<string, string> {
  return new Map([
    ['speeds', `Speeds (${unitChoices.getChoice(UnitType.ANGULAR_SPEED)})`],
    ['in_torques', `In Torque (${unitChoices.getChoice(UnitType.TORQUE)})`],
    ['out_torques', `Out Torque (${unitChoices.getChoice(UnitType.TORQUE)})`],
    ['in_powers', `In Power (${unitChoices.getChoice(UnitType.POWER)})`],
    ['out_powers', `Out Power (${unitChoices.getChoice(UnitType.POWER)})`],
    ['in_voltages', `In Voltage (${unitChoices.getChoice(UnitType.VOLTAGE)})`],
    [
      'out_voltages',
      `Out Voltage (${unitChoices.getChoice(UnitType.VOLTAGE)})`,
    ],
    ['losses', `Losses (${unitChoices.getChoice(UnitType.POWER)})`],
    ['losses_ratio', 'Losses Ratio'],
    ['efficiencies', 'Efficiency (%)'],
    ['gear_ratios', 'Gear Ratio'],
  ]);
}
