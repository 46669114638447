import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CheckboxComponent,
  LabelComponent,
  LabelSize,
  SwitchComponent,
} from '@ansys/awc-angular/forms';
import { CardComponent } from '@ansys/awc-angular/panels';
import {
  BorderRadius,
  ComponentWidth,
  ElevationSize,
} from '@ansys/awc-angular/core';
import { FeatureFlagService } from '../../../shared/services/feature-flag.service';
import { FeatureFlagsEnum } from '../../../shared/enums/feature-flags.enum';
import { ButtonComponent } from '@ansys/awc-angular/buttons';
import { ConfirmComponent } from '../../dialogs/confirm/confirm.component';
import { DialogService } from '@ansys/andromeda/shared';

@Component({
  selector: 'app-feature-flags',
  standalone: true,
  templateUrl: './feature-flags.component.html',
  styleUrls: ['./feature-flags.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  imports: [
    CardComponent,
    CommonModule,
    LabelComponent,
    SwitchComponent,
    ButtonComponent,
    CheckboxComponent,
  ],
  providers: [],
})
export class FeatureFlagsComponent {
  protected borderRadius: BorderRadius = BorderRadius.MEDIUM;
  protected elevation: ElevationSize = ElevationSize.SMALL;
  protected featureFlagsEnabled: boolean = true;
  protected size: LabelSize = LabelSize.MEDIUM;
  protected sizing: ComponentWidth = ComponentWidth.FILL;
  protected features: string[] = [];
  protected views: string[] = [];
  protected subviews: string[] = [];

  constructor(
    protected featureFlagsService: FeatureFlagService,
    private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.features = Object.values(FeatureFlagsEnum).filter(
      (flag): flag is FeatureFlagsEnum => flag.includes('feature-')
    );

    this.views = Object.values(FeatureFlagsEnum).filter(
      (flag): flag is FeatureFlagsEnum => flag.includes('view-')
    );

    this.subviews = Object.values(FeatureFlagsEnum).filter(
      (flag): flag is FeatureFlagsEnum => flag.includes('interaction-')
    );
  }

  protected flagToggled(flag: string, state: boolean): void {
    this.featureFlagsService.setFlag(flag as FeatureFlagsEnum, state);
  }

  protected borderToggled(flag: string, state: boolean): void {
    this.featureFlagsService.setBorder(flag as FeatureFlagsEnum, state);
  }

  protected onResetToDefault(): void {
    const dialog = this.dialogService.open(ConfirmComponent, {
      title: 'Are you sure you want to reset Feature Flags to default?',
      data: {
        onConfirm: async () => {
          dialog.close();
          this.featureFlagsService.resetToDefault();
        },
        onCancel: () => {
          dialog.close();
        },
      },
    });
  }
}
