import { Inject, Injectable } from '@angular/core';
import {
  LibraryResourceTransfer,
  LibraryResourceData,
} from '@ansys/andromeda/dashboard';
import { ConceptSections } from '../enums';
import { PartServiceType } from '../types';
import { SectionService } from './section.service';
import { TreeService } from './tree.service';
import { AWFLocation, AWF_LOCATION } from '@ansys/andromeda/shared';
import { ComponentInDB } from '../../../api';
import { ConceptService } from './concept.service';
import { sectionItemMap } from '../maps/section-item-map';

type ServiceType = PartServiceType;
@Injectable({
  providedIn: 'root',
})
export class LibraryTransferService implements LibraryResourceTransfer {
  private get service(): ServiceType {
    if (this._currentType !== this.type || !this.$service) {
      this._currentType = this.type;
      this.$service = this.sectionService.getService(
        this.type as ConceptSections
      );
    }
    return this.$service;
  }
  private _currentType?: ConceptSections;
  private $service!: ServiceType;
  private type!: ConceptSections;
  private typeMap = sectionItemMap;
  private itemTypeMap = new Map<string, ConceptSections>([
    ['component', ConceptSections.COMPONENT],
    ['config', ConceptSections.CONFIGURATIONS],
  ]);
  constructor(
    private sectionService: SectionService,
    private treeService: TreeService,
    @Inject(AWF_LOCATION) private location: AWFLocation,
    private conceptService: ConceptService
  ) {}

  importResource(data: LibraryResourceData): Promise<void> {
    const meta = JSON.parse(data?.resource?.metadata ?? '{}');
    const type = this.typeMap.get(meta?.type);
    if (!type || !meta.type) {
      throw new Error('Invalid Type');
    }
    this.type = this.typeMap.get(meta?.type) as ConceptSections;
    return new Promise((resolve) => {
      data.file?.text().then((text: string) => {
        const addedItem = JSON.parse(text);
        this.service.addPart(addedItem, undefined, type).then((part) => {
          if (this.type === ConceptSections.COMPONENT) {
            this.service.shouldUpdateSection?.call(
              this.service,
              part as any,
              meta?.type
            );
          }
          const treeItem = this.treeService.addVehiclePartItem(
            meta?.type,
            part
          );
          if (type === this.location.section?.type) {
            this.treeService.selectItem.next(treeItem);
          }
        });
        resolve();
      });
    });
  }

  importResourceFromData(data: any): void {
    const type = this.itemTypeMap.get(data.item_type);
    if (!type) {
      throw new Error('Invalid Type');
    }

    this.type = type;
    this.service
      .addPart(data, undefined, this.type)
      .then((part) => {
        const partType = data[`${data.item_type}_type`] as string;
        const parentType =
          this.conceptService.typeMap.get(partType) ?? partType;
        if (!parentType) {
          throw new Error('Invalid Type');
        }

        if (this.type === ConceptSections.COMPONENT) {
          this.service.shouldUpdateSection?.call(
            this.service,
            part as any,
            parentType
          );
        }

        const treeItem = this.treeService.addVehiclePartItem(parentType, part);
        if (this.type === this.location.section?.type) {
          this.treeService.selectItem.next(treeItem);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }
}
