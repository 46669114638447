import { StateNode } from '@ansys/andromeda/store';
import { StoreType } from '@ansys/andromeda/store';
import { FeatureFlagsEnum } from '../../shared/enums/feature-flags.enum';

export class FeatureFlagsState
  implements StateNode<Record<FeatureFlagsEnum, boolean> | null>
{
  key: string = 'featureFlags';
  mutable: boolean = true;
  observeInitial: boolean = true;
  toStore: StoreType = StoreType.LOCAL;
  defaultValue: () => Record<FeatureFlagsEnum, boolean> | null = () => null;
}
