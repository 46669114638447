import { Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { StaticRequirement, UnitType } from '../../../api';
import { RequirementsService } from '../../shared/services/requirements.service';
import { CapabilityCurveTypeEnum } from '../../shared/enums/capability-curve-type.enum';
import { RequirementPlotType } from '../../shared/enums/reqirement-plot-type.enum';
import { ConvertUnitValuesAction } from '../convert-unit-values/convert-unit-values.action';
import { ConceptUnitService } from '../../shared/services/unit.service';

type InputType = [any[], boolean?];
type ReturnType = [any, number, number, number, number];

@Injectable({
  providedIn: 'root',
})
export class ProcessResultDataAction
  implements AWFAction<InputType, ReturnType>
{
  private maxSpeed = 0;
  private maxTorque = 0;
  private maxPower = 0;
  private maxAcceleration = 0;
  private minSpeed: number = Infinity;
  private minTorque: number = Infinity;
  private minPower: number = Infinity;
  private minAcceleration: number = Infinity;

  constructor(
    private actions: ActionContributionsService,
    private requirementService: RequirementsService,
    private unitChoices: ConceptUnitService
  ) {}

  async execute(context: InputType): Promise<ReturnType> {
    const [results, isDynamic] = context;
    const data: Partial<any>[] = [];

    let convertedSpeeds: number[] = [];

    for (const req of results) {
      const color: string = 'grey';
      const lineColor: string = 'grey';

      [convertedSpeeds, this.minSpeed, this.maxSpeed] =
        await this.actions.execute(ConvertUnitValuesAction, [
          req.capability_curve.speeds,
          UnitType.SPEED,
          this.minSpeed,
          this.maxSpeed,
        ]);

      const convertedValues = await this.convertYAxisValues(req);

      const { convertedReqSpeeds, convertedReqValues } = isDynamic
        ? await this.convertDynamicRequirements(req)
        : await this.convertStaticRequirements(req);

      // Build data points for capability and requirement
      data.push(
        {
          type: 'scatter',
          x: convertedSpeeds,
          y: convertedValues,
          line: { color: lineColor },
          name: `${req.requirement.name} Capability`,
          _type: RequirementPlotType.CAPABILITY_CURVE,
          id: req.id,
        },
        {
          type: 'scatter',
          marker: isDynamic ? { color } : { size: 20, color },
          x: convertedReqSpeeds,
          y: convertedReqValues,
          name: `${req.requirement.name}`,
          _type: RequirementPlotType.REQUIREMENT,
          id: req.id,
        }
      );
    }

    return [
      data,
      this.maxSpeed,
      this.maxTorque,
      this.maxPower,
      this.maxAcceleration,
    ];
  }

  private async convertYAxisValues(req: any): Promise<number[]> {
    let convertedValues: number[] = [];
    switch (this.requirementService.requirementYAxis()) {
      case CapabilityCurveTypeEnum.POWER:
        [convertedValues, this.minPower, this.maxPower] =
          await this.actions.execute(ConvertUnitValuesAction, [
            req.capability_curve.powers,
            UnitType.POWER,
            this.minPower,
            this.maxPower,
          ]);
        break;
      case CapabilityCurveTypeEnum.TORQUE:
        [convertedValues, this.minTorque, this.maxTorque] =
          await this.actions.execute(ConvertUnitValuesAction, [
            req.capability_curve.torques,
            UnitType.TORQUE,
            this.minTorque,
            this.maxTorque,
          ]);
        break;
      case CapabilityCurveTypeEnum.ACCELERATION:
        if (req.capability_curve.accelerations) {
          [convertedValues, this.minAcceleration, this.maxAcceleration] =
            await this.actions.execute(ConvertUnitValuesAction, [
              req.capability_curve.accelerations,
              UnitType.ACCELERATION,
              this.minAcceleration,
              this.maxAcceleration,
            ]);
        }
        break;
      default:
        throw new Error('Unknown requirementYAxis value');
    }
    return convertedValues;
  }

  private async convertDynamicRequirements(req: any): Promise<any> {
    const [convertedReqSpeeds, ,] = await this.actions.execute(
      ConvertUnitValuesAction,
      [
        req.requirement.static_requirements.map(
          (staticReq: StaticRequirement) => staticReq.speed
        ),
        UnitType.SPEED,
        this.minSpeed,
        this.maxSpeed,
      ]
    );

    const [convertedReqValues, ,] = await this.actions.execute(
      ConvertUnitValuesAction,
      [
        req.requirement.static_requirements.map(
          (staticReq: StaticRequirement) => this.mapYAxisValue(staticReq)
        ),
        this.getUnitType(),
        this.minPower,
        this.maxPower,
      ]
    );

    return { convertedReqSpeeds, convertedReqValues };
  }

  private async convertStaticRequirements(req: any): Promise<any> {
    const convertedReqSpeeds = Array.isArray(req.requirement.speed)
      ? req.requirement.speed.map((speed: number) =>
          this.unitChoices.convertUnit(UnitType.SPEED, speed)
        )
      : [this.unitChoices.convertUnit(UnitType.SPEED, req.requirement.speed)];

    const yAxisValues = this.mapYAxisValue(req.requirement);
    const convertedReqValues = Array.isArray(yAxisValues)
      ? yAxisValues.map((value: number) =>
          this.unitChoices.convertUnit(this.getUnitType(), value)
        )
      : [this.unitChoices.convertUnit(this.getUnitType(), yAxisValues)];

    return { convertedReqSpeeds, convertedReqValues };
  }

  private mapYAxisValue(requirement: any): number[] {
    switch (this.requirementService.requirementYAxis()) {
      case CapabilityCurveTypeEnum.POWER:
        return requirement.total_tractive_power;
      case CapabilityCurveTypeEnum.TORQUE:
        return requirement.total_tractive_torque;
      case CapabilityCurveTypeEnum.ACCELERATION:
        return requirement.acceleration;
      default:
        throw new Error('Unknown requirementYAxis value');
    }
  }

  private getUnitType(): UnitType {
    switch (this.requirementService.requirementYAxis()) {
      case CapabilityCurveTypeEnum.POWER:
        return UnitType.POWER;
      case CapabilityCurveTypeEnum.TORQUE:
        return UnitType.TORQUE;
      case CapabilityCurveTypeEnum.ACCELERATION:
        return UnitType.ACCELERATION;
      default:
        throw new Error('Unknown requirementYAxis value');
    }
  }
}
