import { Injectable } from '@angular/core';
import { Job, JobApiService } from '@ansys/cloud-angular-client';
import {
  AWFAction,
  ActionContributionsService,
} from '@ansys/andromeda/contributions';
import { JobMessageData } from '@ansys/andromeda/sockets';
import { State } from '@ansys/andromeda/store';
import { lastValueFrom } from 'rxjs';
import { ActionsEnum } from '../../shared/enums';
import { JobState } from '../../state/lib/jobs.state';
import { ConceptPopulated } from '../../../api';

@Injectable({
  providedIn: 'root',
})
export class UpdateJobStatus
  implements AWFAction<[string, string], [string, string]>
{
  public readonly type = ActionsEnum.UPDATE_JOB_STATUS;

  constructor(private state: State) {}

  execute: (context: [string, string]) => Promise<[string, string]> = async (
    context: [string, string]
  ) => {
    const jobs: Job[] = await this.state.value(JobState);
    const [jobId, status] = context;
    const job = jobs.find((j) => j.jobId === jobId);
    if (job) {
      // temp resolve while we wait for the backend to fix the status/socket desync
      (job as any)['lastStatus'] = status;
      this.state.set(JobState, jobs);
    }

    return [jobId, status];
  };
}

@Injectable({
  providedIn: 'root',
})
export class AddJob implements AWFAction<string, Job[]> {
  public readonly type = ActionsEnum.ADD_JOB;

  constructor(
    private actions: ActionContributionsService,
    private jobApiService: JobApiService,
    private state: State
  ) {}

  execute: (jobId: string) => Promise<Job[]> = async (jobId: string) => {
    const jobs: Job[] = await this.state.value(JobState);
    const newJob = await lastValueFrom(
      this.jobApiService.jobLoadHandler({ jobId })
    );
    jobs.push(newJob);
    return this.actions.execute(SetJobs, jobs);
  };
}

@Injectable({
  providedIn: 'root',
})
export class SetJobs implements AWFAction<Job[], Job[]> {
  public readonly type = ActionsEnum.SET_JOBS;
  private jobs: Job[] = this?.state?.value(JobState) || [];

  constructor(private state: State) {}

  execute: (_jobs: Job[]) => Promise<Job[]> = async (_jobs?: Job[]) => {
    if (_jobs) {
      this.jobs = _jobs;
      this.state.set(JobState, this.jobs);
    }
    return this.jobs;
  };
}

export class UpdateJobProgress
  implements AWFAction<JobMessageData, [string, number]>
{
  public readonly type = ActionsEnum.UPDATE_JOB_PROGRESS;
  execute: (context: JobMessageData) => Promise<[string, number]> = async (
    context: JobMessageData
  ) => [context.jobId, (context['progress'] as number) * 100];
}

@Injectable({
  providedIn: 'root',
})
export class JobMetaData
  implements AWFAction<[string, string], Map<string, string>>
{
  public readonly type = ActionsEnum.SET_JOB_META;
  private jobNames: Map<string, string> = new Map();
  execute: (meta?: [string, string]) => Promise<Map<string, string>> = async (
    meta?: [string, string]
  ) => {
    if (meta) {
      const [jobId, jobName] = meta;
      this.jobNames.set(jobId, jobName);
    }
    return this.jobNames;
  };
}

@Injectable({
  providedIn: 'root',
})
export class LoadAllJobs implements AWFAction<ConceptPopulated, void> {
  public readonly type = ActionsEnum.LOAD_ALL_JOBS;

  constructor(
    private jobApiService: JobApiService,
    private actionService: ActionContributionsService
  ) {}

  execute: (concept: ConceptPopulated) => Promise<void> = async (
    concept: ConceptPopulated
  ) => {
    const jobs = (await Promise.all(
      concept.jobs_ids.map(async (id) => {
        return await lastValueFrom(
          this.jobApiService.jobLoadHandler({ jobId: id })
        ).catch((e) => {
          console.error(e);
        });
      })
    )) as Job[];

    // Clean up undefined jobs (possible problem with a previous delete job - removed from OCM but not ConceptEV
    const jobsClean = jobs.filter((job) => typeof job !== 'undefined') as Job[];
    this.actionService.execute(SetJobs, jobsClean);
  };
}

type LoadJobInputType = string;
type LoadJobReturnType = void;
@Injectable({
  providedIn: 'root',
})
export class LoadJob implements AWFAction<LoadJobInputType, LoadJobReturnType> {
  public readonly type = ActionsEnum.LOAD_JOB;

  constructor(
    private jobApiService: JobApiService,
    private actionService: ActionContributionsService,
    private state: State
  ) {}

  execute: (context: LoadJobInputType) => Promise<LoadJobReturnType> = async (
    jobId: LoadJobInputType
  ) => {
    const job = await lastValueFrom(
      this.jobApiService.jobLoadHandler({ jobId: jobId })
    ).catch((e) => {
      console.error(e);
    });

    if (!job) {
      return;
    }

    const jobs = this.state.value(JobState) || [];
    const jobsClean = jobs.filter((job) => job !== undefined) as Job[];
    const jobIndex = jobsClean.findIndex((j) => j.jobId === jobId);

    if (jobIndex !== -1 && job) {
      jobsClean[jobIndex] = job;
    } else if (job) {
      // jobsClean.push(job);
    }

    this.actionService.execute(SetJobs, jobsClean);
  };
}
