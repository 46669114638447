/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Condition on wheels which affect the rolling resistance coefficient.
 */
export enum WheelRollingResistanceConfigs {
    CAR_ON_ASPHALT = 'Car on asphalt',
    CAR_ON_CONCRETE = 'Car on concrete',
    CAR_ON_GRAVEL = 'Car on gravel',
}
