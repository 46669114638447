<div class="table-cont">
  <div class="table-actions">
    <concept-select
      *ngIf="section === sections.REQUIREMENT && result?.requirement_solved_type !== resultType.STATIC"
      label="Time"
      placeholder="Time Point"
      [items]="pointIndexes"
      [layout]="layout"
      [(selectedItems)]="selectedPointIndex"
      (selectedChanged)="changeTableComponentPoint($event)"
      ></concept-select>
    <awc-button
      *ngIf="section === sections.REQUIREMENT"
      text="Switch to graph"
      awcTooltip="Graph View"
      [suffixIcon]="tableIcon"
      [type]="altType"
      [iconOnly]="true"
      [size]="buttonSize"
      (clicked)="switchToGraphView()"></awc-button>
    <awc-button
      *ngIf="section === sections.REQUIREMENT"
      text="Blueprint"
      awcTooltip="Blueprint View"
      [suffixIcon]="blueprintIcon"
      [type]="altType"
      [iconOnly]="true"
      (clicked)="switchToBlueprintView()"></awc-button>
    <awc-button
      *ngIf="section === sections.REQUIREMENT "
      text="Download"
      awcTooltip="Download"
      [suffixIcon]="downloadIcon"
      [type]="altType"
      [iconOnly]="true"
      [size]="buttonSize"
      (clicked)="downloadData()"></awc-button>

  </div>
  <div [style.height]="maxHeight+'px'">
    <awc-table
      [style.height]="maxHeight+'px'"
      [columns]="columns"
      [rows]="rows"></awc-table>
  </div>
</div>
