/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MotorTorqueCurvesData } from './MotorTorqueCurvesData';
/**
 * Create a motor from torque speed curves.
 */
export type MotorTorqueCurves = {
    item_type?: MotorTorqueCurves.item_type;
    name?: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: MotorTorqueCurves.component_type;
    torque_curves: MotorTorqueCurvesData;
};
export namespace MotorTorqueCurves {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        MOTOR_TORQUE_CURVES = 'MotorTorqueCurves',
    }
}

