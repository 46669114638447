/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import {
  ArchitecturesService as API,
  ArchitectureInputIds,
  Concept,
  ConceptPopulated,
} from 'src/api';
import { PartServiceType } from '../types/part-service.type';
import { State } from '@ansys/andromeda/store';
import { SnackBarService } from '@ansys/andromeda/shared';
import { sectionState } from '../models/section-state';
import {
  ActiveTabState,
  SectionUpdateAction,
} from '@ansys/andromeda/workspace';
import { ConceptPartType } from '../types';
import { AnalyticEventEnum, ArchitectureTemplate } from '../enums';
import { EventBus } from '../tools/event-bus';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { ActiveConceptState } from 'src/app/state/lib/active-concept.state';
import { Subject, lastValueFrom } from 'rxjs';
import {
  AnalyticsEventPayload,
  SubmitAnalyticsEventAction,
} from '@ansys/andromeda/analytics';
import { NotificationService } from './notification.service';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ArchitectureMaxSpeedState } from '../../state/lib/architecture-max-speed.state';

/**
 */

@Injectable({ providedIn: 'root' })
export class ArchitectureService implements PartServiceType {
  get template(): ArchitectureTemplate {
    return this.activeArchitectureTemplate;
  }

  set template(template: ArchitectureTemplate) {
    this.activeArchitectureTemplate = template;
  }

  get architecture(): Signal<ArchitectureInputIds | undefined> {
    return this.activeArchitecture;
  }

  get frontWheelsCount(): Signal<number> {
    return this.frontWheels;
  }

  get rearWheelsCount(): Signal<number> {
    return this.rearWheels;
  }

  get frontMotorsCount(): Signal<number> {
    return this.frontMotors;
  }

  get rearMotorsCount(): Signal<number> {
    return this.rearMotors;
  }

  private frontWheels: WritableSignal<number> = signal(2);
  private rearWheels: WritableSignal<number> = signal(2);
  private frontMotors: WritableSignal<number> = signal(1);
  private rearMotors: WritableSignal<number> = signal(0);

  private activeArchitectureTemplate: ArchitectureTemplate =
    ArchitectureTemplate.SINGLE_FRONT;
  private activeArchitecture: WritableSignal<ArchitectureInputIds | undefined> =
    signal(undefined);
  public architectureUpdating: WritableSignal<boolean> = signal(false);
  private instanceId!: string;
  private bus = EventBus.getInstance();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private API: API,
    private state: State,
    private snackbar: SnackBarService,
    private actions: ActionContributionsService,
    private notificationService: NotificationService
  ) {}

  public async getArchitecture(concept: ConceptPopulated): Promise<void> {
    this.instanceId = concept.design_instance_id as string;
    if (concept.architecture_id) {
      this.architectureUpdating.set(true);
      const arch = await lastValueFrom(
        this.API.readArchitectureArchitecturesItemIdGet(
          concept.architecture_id,
          concept.design_instance_id
        )
      );

      this.activeArchitecture.set(arch);
      this.frontWheels.set(arch.number_of_front_wheels);
      this.rearWheels.set(arch.number_of_rear_wheels);
      this.frontMotors.set(arch.number_of_front_motors);
      this.rearMotors.set(arch.number_of_rear_motors);

      this.activeArchitectureTemplate = this.isDual(arch)
        ? ArchitectureTemplate.DUAL
        : ArchitectureTemplate.SINGLE_FRONT;
      this.architectureUpdating.set(false);
    }
  }

  public resetArchitecture(): void {
    this.activeArchitecture.set(undefined);
    this.frontWheels.set(2);
    this.rearWheels.set(2);
    this.frontMotors.set(1);
    this.rearMotors.set(0);
    this.activeArchitectureTemplate = ArchitectureTemplate.SINGLE_FRONT;
  }

  public addPart(item: ConceptPartType): Promise<any> {
    item;
    return new Promise((resolve) => resolve({}));
  }

  public updatePart(): Promise<unknown> {
    return new Promise((resolve) => {
      resolve({});
    });
  }

  public deletePart(): Promise<unknown> {
    return new Promise((resolve) => {
      resolve({});
    });
  }

  public alterWheelArchitecture(id: string, rear?: boolean): void {
    if (rear) {
      this.rearWheels.set(parseInt(id));
    } else {
      this.frontWheels.set(parseInt(id));
    }

    const eventData: AnalyticsEventPayload = {
      type: AnalyticEventEnum.ARCHITECTURE_UPDATE,
      data: {
        frontWheelsCount: this.frontWheelsCount(),
        rearWheelsCount: this.rearWheelsCount(),
        frontMotorsCount: this.frontMotorsCount(),
        rearMotorsCount: this.rearMotorsCount(),
      },
    };
    this.actions.execute(SubmitAnalyticsEventAction, eventData);
  }

  public alterMotorArchitecture(id: string, rear?: boolean): void {
    if (rear) {
      this.rearMotors.set(parseInt(id));
    } else {
      this.frontMotors.set(parseInt(id));
    }

    const eventData: AnalyticsEventPayload = {
      type: AnalyticEventEnum.ARCHITECTURE_UPDATE,
      data: {
        frontWheelsCount: this.frontWheelsCount(),
        rearWheelsCount: this.rearWheelsCount(),
        frontMotorsCount: this.frontMotorsCount(),
        rearMotorsCount: this.rearMotorsCount(),
      },
    };
    this.actions.execute(SubmitAnalyticsEventAction, eventData);
  }

  public displayData(): void {
    console.log('display component!');
  }

  isDual(architecture: ArchitectureInputIds): boolean {
    return !!(
      architecture.front_transmission_id && architecture.rear_transmission_id
    );
  }

  public async setArchitecture(
    architecture: ArchitectureInputIds,
    inputId?: string
  ): Promise<void> {
    if (this.checkArchStatus(architecture)) {
      const concept = this.state.value(ActiveConceptState);
      const updating = !!concept?.architecture_id;
      this.snackbar.info(`${updating ? 'Updating' : 'Creating'} Architecture`);

      if (['neglect', ''].includes(architecture.rear_clutch_id as string)) {
        architecture.rear_clutch_id = null;
      }
      if (['neglect', ''].includes(architecture.front_clutch_id as string)) {
        architecture.front_clutch_id = null;
      }
      if (['neglect', ''].includes(architecture.front_inverter_id as string)) {
        architecture.front_inverter_id = null;
      }
      if (['neglect', ''].includes(architecture.rear_inverter_id as string)) {
        architecture.rear_inverter_id = null;
      }
      if (architecture.front_transmission_id === 'in-wheel') {
        architecture.front_transmission_id = null;
      }
      if (architecture.rear_transmission_id === 'in-wheel') {
        architecture.rear_transmission_id = null;
      }
      const data = await lastValueFrom(
        this.API.createArchitecturesArchitecturesPost(this.instanceId, {
          ...architecture,
        } as ArchitectureInputIds)
      ).catch((error) => {
        this.notificationService.error('Error Creating Component', error);
        this.bus.dispatch('input:update:complete', [inputId, true]);
        throw new Error(error);
      });

      if (!data) {
        return;
      }

      sectionState.requirement = true;
      this.actions.execute(SectionUpdateAction);
      (concept as Concept).architecture_id = data.id;
      (concept as ConceptPopulated).architecture = data;
      this.state.set(ActiveConceptState, concept);
      this.snackbar.success(`Architecture ${updating ? 'Updated' : 'Created'}`);
      this.bus.dispatch('input:update:complete', [inputId, false]);
    }
  }

  private checkArchStatus(arch: ArchitectureInputIds): boolean {
    const hasFront =
      arch['number_of_front_motors'] > 0 ||
      arch['front_transmission_id'] ||
      arch['front_motor_id'] ||
      arch['front_inverter_id'];
    const hasRear =
      arch['number_of_rear_motors'] > 0 ||
      arch['rear_transmission_id'] ||
      arch['rear_motor_id'] ||
      arch['rear_inverter_id'];
    const missingList: string[] = [];

    if (!arch['battery_id']) {
      missingList.push('Battery');
    }

    if (
      !arch['front_transmission_id'] &&
      hasFront &&
      arch.number_of_front_motors !== arch.number_of_front_wheels
    ) {
      missingList.push('Front Transmission');
    }

    if (
      !arch['rear_transmission_id'] &&
      hasRear &&
      arch.number_of_rear_motors !== arch.number_of_rear_wheels
    ) {
      missingList.push('Rear Transmission');
    }

    if (!arch['front_motor_id'] && hasFront) {
      missingList.push('Front Motor');
    }

    if (!arch['rear_motor_id'] && hasRear) {
      missingList.push('Rear Motor');
    }

    if (missingList.length > 0) {
      this.snackbar.info(
        `Please select a ${missingList.slice(0, -1).join(', ')}${
          (missingList.length > 1 ? ' & ' : '') + missingList.slice(-1)
        }`
      );
      return false;
    }
    return true;
  }

  public async getArchitectureMaxSpeedArchitecturesMaxSpeedGet(
    wheelId: string
  ): Promise<void> {
    const concept = this.state.value(ActiveConceptState);
    if (!concept || !concept.architecture_id) {
      return;
    }

    const maxSpeed = await lastValueFrom(
      this.API.getArchitectureMaxSpeedArchitecturesMaxSpeedGet(
        wheelId,
        concept.architecture_id,
        concept.design_instance_id
      )
    );

    const tab = this.state.value(ActiveTabState);
    this.state.set(ArchitectureMaxSpeedState, maxSpeed, tab?.id);
  }

  public isSingleAxle(): boolean {
    const frontCount = this.frontMotorsCount();
    const rearCount = this.rearMotorsCount();

    return (
      (frontCount > 0 && rearCount === 0) || (frontCount === 0 && rearCount > 0)
    );
  }
}
