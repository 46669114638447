import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  ChangeDetectorRef,
  EmbeddedViewRef,
  Renderer2,
} from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';
import { FeatureFlagsEnum } from '../enums/feature-flags.enum';
import { FeatureFlagsState } from '../../state/lib/feature-flags.state';
import { State } from '@ansys/andromeda/store';
import { Subscription } from 'rxjs';
import { FeatureFlagBorderState } from '../../state/lib/feature-flag-border.state';

@Directive({
  selector: '[featureFlag]',
})
export class FeatureFlagDirective {
  @Input() featureFlag!: FeatureFlagsEnum;

  private flagsSubscription!: Subscription;
  private borderSubscription!: Subscription;
  private embeddedViewRef: EmbeddedViewRef<any> | null = null;

  constructor(
    private state: State,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (!this.embeddedViewRef) {
      this.embeddedViewRef = this.viewContainer.createEmbeddedView(
        this.templateRef
      );
    }

    this.updateView();

    // Subscribe to flag state changes
    this.flagsSubscription = this.state.get(FeatureFlagsState).subscribe(() => {
      this.updateView();
    });

    // Subscribe to border state changes
    this.borderSubscription = this.state
      .get(FeatureFlagBorderState)
      .subscribe(() => {
        this.updateView();
      });
  }

  ngOnDestroy(): void {
    this.flagsSubscription?.unsubscribe();
    this.borderSubscription?.unsubscribe();
    this.clearView();
  }

  private updateView(): void {
    const isFlagged = this.featureFlagService.isFlagged(this.featureFlag);
    const hasBorder = this.featureFlagService.hasBorder(this.featureFlag);

    if (!isFlagged) {
      this.clearView();
      return;
    }

    if (!this.embeddedViewRef) {
      this.embeddedViewRef = this.viewContainer.createEmbeddedView(
        this.templateRef
      );
      this.cdr.detectChanges();
    }

    const viewElement = this.embeddedViewRef?.rootNodes[0];
    if (!viewElement || !(viewElement instanceof HTMLElement)) {
      this.cdr.detectChanges();
      return;
    }

    try {
      if (hasBorder) {
        this.renderer.setStyle(
          viewElement,
          'box-shadow',
          '2px 2px 5px rgba(255, 191, 0, 0.5)'
        );
        this.renderer.setStyle(viewElement, 'border', '2px dashed #ffbf00');
      } else {
        this.renderer.removeStyle(viewElement, 'box-shadow');
        this.renderer.removeStyle(viewElement, 'border');
      }
    } catch (error) {
      console.error('Error while trying to set border', error);
    }

    this.cdr.detectChanges();
  }

  private clearView(): void {
    if (this.embeddedViewRef) {
      this.viewContainer.clear();
      this.embeddedViewRef = null;

      this.cdr.detectChanges();
    }
  }
}
