<awc-select
  label="Rolling Resistance Type"
  placeholder="Rolling Resistance..."
  [items]="rollingResistanceKeys"
  [selectedItems]="[rollingResistanceKey]"
  (selectionChanged)="selectionItems($event[0].id, 'rolling_resistance_key', 'rolling_resistance_coefficient')">
</awc-select>

<app-input
  label="Rolling Resistance"
  placeholder="Rolling Resistance..."
  [value]="rollingResistance"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_rolling_resistance_coefficient'"
  [updater]="update"
  [readonly]="rollingResistanceKey !== 'manual'"
  (update)="setPartValue(''+$event, 'rolling_resistance_coefficient', part.id+'_rolling_resistance_coefficient')">
</app-input>

<awc-select
  label="Traction Coefficient Type"
  placeholder="Traction Coefficient..."
  [items]="tractionCoefficientKeys"
  [selectedItems]="[tractionCoefficientKey]"
  (selectionChanged)="selectionItems($event[0].id, 'traction_coefficient_key', 'traction_coefficient')">
</awc-select>
<app-input
  label="Traction Coefficient"
  placeholder="Traction..."
  [value]="tractionCoefficient"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_traction_coefficient'"
  [updater]="update"
  [readonly]="tractionCoefficientKey !== 'manual'"
  (update)="setPartValue(''+$event, 'traction_coefficient', part.id+'_traction_coefficient')">
</app-input>

<app-input
  label="Rolling Radius"
  placeholder="Rolling Radius..."
  [value]="part.rolling_radius"
  [unit]="UnitType.LENGTH"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_rolling_radius'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'rolling_radius', part.id+'_rolling_radius'])"></app-input>
<app-input
  label="Mass"
  placeholder="Optional..."
  [value]="part.mass"
  [unit]="UnitType.MASS"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_mass'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit([''+$event, 'mass', part.id+'_mass'])"></app-input>
