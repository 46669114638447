import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { FeatureFlagsEnum } from '../enums/feature-flags.enum';
import { featureFlags } from '../consts/feature-flags.consts';
import { State } from '@ansys/andromeda/store';
import { FeatureFlagsState } from '../../state/lib/feature-flags.state';
import { environment } from '../../../environments/environment';
import { FeatureFlagBorderState } from '../../state/lib/feature-flag-border.state';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private useApiForFeatureFlags = false;
  private featureFlagsApiUrl = '';

  private defaultBorderState: Record<FeatureFlagsEnum, boolean | null> =
    Object.values(FeatureFlagsEnum).reduce(
      (acc, flag) => ({ ...acc, [flag]: null }),
      {} as Record<FeatureFlagsEnum, boolean | null>
    );

  constructor(private http: HttpClient, private state: State) {
    this.loadFlags();
  }

  loadFlags(): Observable<void> {
    if (this.useApiForFeatureFlags) {
      return this.http
        .get<{ [key: string]: boolean }>(
          `${environment.apiURL}${this.featureFlagsApiUrl}`
        )
        .pipe(
          tap((flags) => this.setFlags(flags)),
          map(() => undefined)
        );
    } else {
      this.setFlags(featureFlags);
      return of(undefined);
    }
  }

  public resetToDefault(): void {
    this.state.set(FeatureFlagsState, { ...featureFlags });
    this.state.set(FeatureFlagBorderState, { ...this.defaultBorderState });
  }

  public setFlag(flag: FeatureFlagsEnum, state: boolean): void {
    if (typeof this.state.value(FeatureFlagsState)?.[flag] === 'undefined') {
      this.state.set(FeatureFlagsState, { [flag]: state });
    } else {
      this.state.update(
        FeatureFlagsState,
        (flags) => flags && (flags[flag] = state)
      );
    }
  }

  public isFlagged(flag: string | FeatureFlagsEnum): boolean {
    const flags = this.state.value(FeatureFlagsState);
    return flags?.[flag as FeatureFlagsEnum] ?? false;
  }

  public setBorder(flag: FeatureFlagsEnum, hasBorder: boolean | null): void {
    this.state.update(FeatureFlagBorderState, (borderState) => {
      if (borderState) {
        borderState[flag] = !!hasBorder;
      }
    });
  }

  public hasBorder(flag: FeatureFlagsEnum | string): boolean {
    const borderState = this.state.value(FeatureFlagBorderState);
    return borderState?.[flag as FeatureFlagsEnum] ?? false;
  }

  private flagExists(flag: string | FeatureFlagsEnum): boolean {
    const flags = this.state.value(FeatureFlagsState);
    if (!flags) {
      return false;
    }
    return Object.prototype.hasOwnProperty.call(
      flags,
      flag as FeatureFlagsEnum
    );
  }

  /**
   * This will only add new flags to the FeatureFlagsState if it doesn't already exist
   */
  private setFlags(flags: { [key: string]: boolean }): void {
    Object.keys(flags).forEach((key) => {
      const flagKey = key as FeatureFlagsEnum;
      if (Object.values(FeatureFlagsEnum).includes(flagKey)) {
        if (!this.flagExists(flagKey)) {
          this.setFlag(flagKey, flags[key]);
        }
      }
    });
  }
}
