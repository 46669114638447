import { StateNode, StoreType } from '@ansys/andromeda/store';
import { FeatureFlagsEnum } from '../../shared/enums/feature-flags.enum';

export class FeatureFlagBorderState
  implements StateNode<Record<FeatureFlagsEnum, boolean> | null>
{
  key: string = 'featureFlagBorder';
  mutable: boolean = true;
  observeInitial: boolean = true;
  toStore: StoreType = StoreType.LOCAL;
  defaultValue: () => Record<FeatureFlagsEnum, boolean> | null = () => null;
}
