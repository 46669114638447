import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  Input,
  SimpleChanges,
} from '@angular/core';
import { UnitType } from '../../../../api';
import { toSN } from '../../../shared/pipes/scientific-notation/scientific-notation.pipe';
import { ArchitectureService } from '../../../shared/services/architecture.service';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ConfigurationsService } from '../../../shared/services/configurations.service';
import { SharedModule } from '../../../shared/shared.module';
import { TooltipDirective } from '@ansys/awc-angular/popouts';
import { CommonModule } from '@angular/common';
import { ActiveConceptState } from '../../../state/lib/active-concept.state';
import { State } from '@ansys/andromeda/store';
import { ArchitectureSelectedWheelState } from '../../../state/lib/architecture-selected-wheel.state';
import { ActiveTabState } from '@ansys/andromeda/workspace';
import { Subject, takeUntil } from 'rxjs';
import { ArchitectureMaxSpeedState } from '../../../state/lib/architecture-max-speed.state';

@Component({
  selector: 'app-architecture-data-panel',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, TooltipDirective, CommonModule],
  templateUrl: './architecture-data-panel.component.html',
  styleUrl: './architecture-data-panel.component.scss',
})
export class ArchitectureDataPanelComponent {
  @Input() onLoadMaxSpeed: boolean = false;
  protected readonly unitType = UnitType;
  protected componentsCost: string | number = 0;
  protected componentsMass: string | number = 0;
  protected maxSpeed: string | number = 0;
  protected wheels: AWCListItem[] = [];
  protected selectedWheel!: AWCListItem | undefined;
  protected loadingArchitecture: boolean = true;
  protected loadingMaxSpeed: boolean = true;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected architectureService: ArchitectureService,
    private configurationService: ConfigurationsService,
    private state: State,
    private cdr: ChangeDetectorRef
  ) {
    [, , this.wheels] = this.configurationService.configurationLists;
    effect(() => {
      const architecture = this.architectureService.architecture();

      if (architecture) {
        this.componentsCost = toSN(architecture.components_cost);
        this.componentsMass = toSN(architecture.components_mass);
      }

      this.loadingArchitecture =
        this.architectureService.architectureUpdating();

      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['onLoadMaxSpeed'] && changes['onLoadMaxSpeed'].currentValue) {
      this.loadMaxSpeed();
    }
  }

  ngAfterViewInit(): void {
    const tab = this.state.value(ActiveTabState);

    this.state
      .get(ArchitectureSelectedWheelState, tab?.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.selectedWheel = value;
        this.cdr.detectChanges();
      });

    this.state
      .get(ArchitectureMaxSpeedState, tab?.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.maxSpeed = toSN(value);
        this.cdr.detectChanges();
      });

    this.loadArchitectureData();
    this.loadMaxSpeed();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected async onWheelSelected(wheelId: any): Promise<void> {
    this.loadingMaxSpeed = true;
    await this.architectureService.getArchitectureMaxSpeedArchitecturesMaxSpeedGet(
      wheelId
    );
    const selectedWheel = this.wheels.find((wheel) => wheel.id === wheelId[0]);
    const tab = this.state.value(ActiveTabState);
    this.state.set(ArchitectureSelectedWheelState, selectedWheel, tab?.id);
    this.loadingMaxSpeed = false;
    this.cdr.detectChanges();
  }

  private async loadArchitectureData(): Promise<void> {
    const concept = this.state.value(ActiveConceptState);
    if (concept) {
      this.loadingArchitecture = true;
      await this.architectureService.getArchitecture(concept);
      this.loadingArchitecture = false;
      this.cdr.detectChanges();
    }
  }

  private async loadMaxSpeed(): Promise<void> {
    this.loadingMaxSpeed = true;
    const tab = this.state.value(ActiveTabState);
    const selectedWheel = this.state.value(
      ArchitectureSelectedWheelState,
      tab?.id
    );

    if (selectedWheel?.id) {
      await this.architectureService.getArchitectureMaxSpeedArchitecturesMaxSpeedGet(
        selectedWheel.id
      );
    }
    this.loadingMaxSpeed = false;
    this.cdr.detectChanges();
  }
}
