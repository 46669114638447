/* eslint-disable @typescript-eslint/naming-convention */
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Job,
  JobApiService,
  JobFilesApiService,
} from '@ansys/cloud-angular-client';
import { State, AuthTokenState } from '@ansys/andromeda/store';
import { Observable, lastValueFrom, tap } from 'rxjs';
import {
  JobsService,
  OpenAPIConfig,
  RequirementsSolved,
  UtilitiesService,
} from 'src/api';
import { ResultType } from 'src/app/components/widgets/results-list/results-list.component';
import { RequirementsService } from './requirements.service';
import { DataDisplayService, DataDisplayState } from './data-display.service';
import { environment } from 'src/environments/environment';
import { SnackBarService } from '@ansys/andromeda/shared';
import { request as __request } from '../../../api/core/request';

@Injectable({ providedIn: 'root' })
export class ResultsService {
  private solveAPI: string = environment.ocmUrl;

  constructor(
    private jobFilesService: JobFilesApiService,
    private jobService: JobsService,
    private jobApi: JobApiService,
    private requirementService: RequirementsService,
    private utilService: UtilitiesService,
    private state: State,
    private snackbar: SnackBarService,
    private displayService: DataDisplayService,
    private http: HttpClient
  ) {}

  async getResults(
    job: Job,
    retry: boolean = true,
    results: RequirementsSolved[] | null = null
  ): Promise<RequirementsSolved[]> {
    const validJob = await this.ensureJobHasSimulationId(job, retry);
    if (!results) {
      results = await this.downloadResults(validJob);
    }

    this.processResults(results);

    return results;
  }

  private async ensureJobHasSimulationId(
    job: Job,
    retry: boolean
  ): Promise<Job> {
    if (!job.simulations?.[0]?.simulationId) {
      if (retry) {
        const updatedJob = await lastValueFrom(
          this.jobApi.jobLoadHandler({ jobId: job.jobId as string })
        );
        return this.ensureJobHasSimulationId(updatedJob, false);
      } else {
        this.snackbar.error('No simulation id, please refresh the page');
        throw new Error('No simulation id');
      }
    }
    return job;
  }

  private processResults(results: RequirementsSolved[]): void {
    const staticResults = results.filter(
      (result) => result.requirement_solved_type === ResultType.STATIC
    );
    const dynamicResults = results.filter(
      (result) => result.requirement_solved_type === ResultType.DYNAMIC
    );
    const dcResults = results.filter(
      (result) => result.requirement_solved_type === ResultType.DRIVE_CYCLE
    );

    if (staticResults.length) {
      this.requirementService.processStaticResultData(staticResults);
    }
    if (dynamicResults.length) {
      this.requirementService.processDynamicResultData(dynamicResults);
    }
    if (dcResults.length) {
      this.requirementService.processDriveCycleResults(dcResults);
    }
  }

  async downloadResults(job: Job): Promise<RequirementsSolved[]> {
    const currentVersion = await lastValueFrom(
      this.utilService.getDataFormatVersionNumberUtilitiesDataFormatVersionGet()
    );

    const fileList = await lastValueFrom(
      this.jobFilesService.getFileListByJobIdSimulationId(
        job.jobId,
        job.simulations?.[0]?.simulationId
      )
    );
    const fileToDownload = fileList.find((file) =>
      file.fileName?.includes(
        `${job.simulations?.[0]?.simulationId}/output_file_v${currentVersion}`
      )
    );
    let fileName = fileToDownload?.fileName?.split('/')[1] ?? '';
    let fileSize = fileToDownload?.fileSize ?? 0;
    this.displayService.displayState = DataDisplayState.PROGRESS;
    this.displayService.updateProgressItem(0);

    if (!fileToDownload) {
      this.displayService.message = 'Converting results to latest version';
      const updateResults = await lastValueFrom(
        this.jobService.updateResultsFileDataFormatJobsDataCompatibilityConversionPost(
          fileList.reverse()[0]?.fileName?.split('/')[1] as string,
          job.designInstanceId as string,
          {
            job_id: job.jobId as string,
            simulation_id: job.simulations?.[0]?.simulationId as string,
            docker_tag: job.dockerTag as string,
            job_name: job.jobName as string,
          }
        )
      );
      fileName = updateResults.file_name;
      fileSize = updateResults.file_size;
    }

    if (job.simulations?.[0]?.simulationId && job.jobId) {
      this.displayService.message = 'Downloading Results';
      const file = await lastValueFrom(
        this.downloadFile(
          job.jobId,
          job.simulations?.[0]?.simulationId,
          fileName,
          fileSize
        )
      );

      return (
        (file as HttpResponse<unknown>)['body'] as RequirementsSolved[]
      ).map((result, index) => {
        result.id = index.toString();
        return result;
      });
    } else {
      throw new Error('No file to download');
    }
  }

  downloadFile(
    jobId: string,
    simulationId: string,
    fileName: string,
    size: number = 0
  ): Observable<HttpEvent<any>> {
    const midPoint = fileName.includes('converted') ? '' : 'decrypted/';
    return this.http
      .get(
        `${this.solveAPI}/job/files/${midPoint}${jobId}/${simulationId}/${fileName}`,
        {
          reportProgress: true,
          observe: 'events',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${this.state.value(AuthTokenState)}`,
          },
        }
      )
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.DownloadProgress) {
            this.displayService.updateProgressItem(
              (event.loaded / (size ?? 0)) * 100
            );
          }
        })
      );
  }
  /**
   * Request Job File
   * Get contents of console.log.
   * @param jobId
   * @param fileName
   * @returns string Successful Response
   * @throws ApiError
   */
  public getJobFile(jobId: string, fileName: string): Observable<object> {
    return this.http.get(`${this.solveAPI}/job/files/${jobId}/${fileName}`, {
      headers: {
        Accept: 'application/octet-stream',
        Authorization: `${this.state.value(AuthTokenState)}`,
      },
    });
  }

  /**
   * Cancel all simulations in job
   *
   * @param jobId
   */
  public cancelJob(jobId: string): Observable<object> {
    return this.http.post(`${this.solveAPI}/job/stop/`, { jobId });
  }
}
