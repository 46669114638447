/* eslint-disable @typescript-eslint/naming-convention */
import { TransmissionLossCoefficients } from 'src/api';

export const transmissionComponent: transmissionComponentType = {
  component_type:
    TransmissionLossCoefficients.component_type.TRANSMISSION_LOSS_COEFFICIENTS,
};

export type transmissionComponentType = TransmissionLossCoefficients & {
  default_values?: TransmissionLossCoefficients;
};
