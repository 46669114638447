<div class="solve-dialog">
  <app-input
    label="Name"
    placeholder="Name of study..."
    [value]="studyName"
    (update)="setStudyName($event)"></app-input>
  <awc-select
    label="Static Requirements"
    placeholder="No Static Requirements"
    [items]="staticRequirements"
    [layout]="layout"
    [multi]="true"
    [selectedItems]="selectedStaticItems"
    (selectionChanged)="selectionStaticChanged($event)"></awc-select>
  <awc-select
    label="Dynamic Requirements"
    placeholder="No Dynamic Requirements"
    [items]="dynamicRequirements"
    [layout]="layout"
    [multi]="true"
    [selectedItems]="selectedDynamicItems"
    (selectionChanged)="selectionDynamicChanged($event)"></awc-select>
  <awc-select
    label="Drive Cycles"
    placeholder="No Drive Cycles"
    [items]="dcRequirements"
    [layout]="layout"
    [multi]="true"
    [selectedItems]="selectedDCItems"
    (selectionChanged)="selectionDCChanged($event)"></awc-select>
  <awc-switch
    *ngIf="[authEnvironment.DEV, authEnvironment.TEST].includes(environment.type)"
    [checked]="extraMemory"
    [label]="'Extra Memory'"
    [awcTooltip]="'Extra Memory for large studies'"
    (changes)="memoryBoost($event)"></awc-switch>
  <awc-button
    text="Confirm"
    [type]="type"
    [size]="size"
    (clicked)="confirm()"></awc-button>
</div>
