/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Input values for transmission model for fixed efficiencies.
 */
export type TransmissionLossCoefficients = {
    item_type?: TransmissionLossCoefficients.item_type;
    name?: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: TransmissionLossCoefficients.component_type;
    gear_ratios?: Array<number>;
    headline_efficiencies?: Array<number>;
    max_torque?: number;
    max_speed?: number;
    static_drags?: Array<number>;
    friction_ratios?: Array<number>;
    shift_time?: number;
    moment_of_inertia_wheel_side?: number;
};
export namespace TransmissionLossCoefficients {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        TRANSMISSION_LOSS_COEFFICIENTS = 'TransmissionLossCoefficients',
    }
}

