import { Component, Inject } from '@angular/core';
import { ButtonComponent } from '@ansys/awc-angular/buttons';
import { InputComponent, InputType } from '@ansys/awc-angular/forms';
import { DIALOG_DATA, DIALOG_REF } from '@ansys/andromeda/shared';
import { Icons } from '@ansys/awc-angular/icons';

@Component({
  selector: 'app-update-one-input',
  standalone: true,
  imports: [ButtonComponent, InputComponent],
  templateUrl: './update-one-input.component.html',
  styleUrl: './update-one-input.component.scss',
})
export class UpdateOneInputComponent {
  protected submitting: boolean = false;
  protected value: string = this.data.value;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly Icons = Icons;

  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      value: string;
      label: string;
      inputType: InputType;
      placeholder: string;
      onConfirm: (value: string) => void;
      onCancel?: () => void;
    },
    @Inject(DIALOG_REF) public dialogRef: any
  ) {}

  protected confirm(): void {
    if (this.submitting) {
      return;
    }

    this.submitting = true;
    this.data.onConfirm?.(this.value);
  }

  protected cancel(): void {
    if (this.data.onCancel) {
      this.data.onCancel();
    } else {
      this.dialogRef.close();
    }
  }
}
