import { LibraryObject } from '@ansys/cloud-angular-client';
import { Injectable } from '@angular/core';
import {
  AWCTableCell,
  TableCellAlign,
  TableCellTypes,
} from '@ansys/awc-angular/tables';
import { TableColumn } from '@ansys/andromeda/contributions';

@Injectable({ providedIn: 'root' })
export class LibraryTypeColumn implements TableColumn {
  public header: string = 'Type';
  public id: string = 'library-table-type';
  public priority: number = 350;
  public type: TableCellTypes = TableCellTypes.TEXT;
  public align: TableCellAlign = TableCellAlign.LEFT;
  private typeMap: Map<string, string> = new Map([
    ['aero', 'Aero Configuration'],
    ['mass', 'Mass Configuration'],
    ['wheel', 'Wheel Configuration'],
    ['ancillary_load', 'Ancillary Load Configuration'],
    ['deceleration_limit', 'Deceleration Limit Configuration'],
    ['clutch', 'Clutch Component'],
    ['battery', 'Battery Component'],
    ['inverter', 'Inverter Component'],
    ['motor', 'Motor Component'],
    ['transmission', 'Transmission Component'],
    ['drive_cycle', 'Drive Cycle'],
    ['static_requirement', 'Static Requirement'],
    ['dynamic_requirement', 'Dynamic Requirement'],
  ]);
  public render(data: LibraryObject): Promise<AWCTableCell> {
    const meta = JSON.parse(data?.metadata ?? '{}');
    return Promise.resolve({
      value: this.typeMap.get(meta?.type) ?? 'Concept Resource',
    });
  }
}
