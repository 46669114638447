import { TableColumn } from '@ansys/andromeda/contributions';
import {
  AWCTableCell,
  TableCellAlign,
  TableCellTypes,
} from '@ansys/awc-angular/tables';
import { Icons } from '@ansys/awc-angular/icons';
import { DriveCycleTableType } from '../../shared/types/drive-cycle-table.type';

export class DriveCycleSelectedColumn implements TableColumn {
  public header: string = '';
  public id: string = 'selected';
  public type: TableCellTypes = TableCellTypes.ICON;
  public priority: number = 20;
  public align: TableCellAlign = TableCellAlign.CENTER;
  public width: number = 50;

  public async render(data: DriveCycleTableType): Promise<AWCTableCell> {
    const [icon, external] = data.selected ? [Icons.CHECK, false] : ['', false];
    return Promise.resolve({
      value: icon,
      externalSrc: external,
    });
  }
}
