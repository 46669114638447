/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Disconnect clutch In DB.
 */
export type DisconnectClutchInputInDB = {
    item_type?: DisconnectClutchInputInDB.item_type;
    name?: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: 'ClutchInput';
    efficiency?: number;
    switch_energy?: number;
    engaged_power?: number;
    _id?: string;
    id?: string;
};
export namespace DisconnectClutchInputInDB {
    export enum item_type {
        COMPONENT = 'component',
    }
}

