/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComponentAxle } from './ComponentAxle';
import type { ComponentSide } from './ComponentSide';
/**
 * Solved battery node.
 */
export type SolvedBattery = {
    name: string;
    axle?: ComponentAxle;
    side?: ComponentSide;
    mass?: number;
    cost?: number;
    in_powers: Array<number>;
    out_powers: Array<number>;
    losses: Array<number>;
    losses_ratio: Array<number>;
    solved_component_type?: SolvedBattery.solved_component_type;
    in_voltages: Array<number>;
    out_voltages: Array<number>;
    currents?: Array<number>;
};
export namespace SolvedBattery {
    export enum solved_component_type {
        BATTERY = 'battery',
    }
}

