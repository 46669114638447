import { TableColumn } from '@ansys/andromeda/contributions';
import {
  AWCTableCell,
  TableCellAlign,
  TableCellTypes,
} from '@ansys/awc-angular/tables';

export class DriveCycleNameColumn implements TableColumn {
  align: TableCellAlign = TableCellAlign.LEFT;
  header: string = 'Name';
  id: string = 'name';
  type: TableCellTypes = TableCellTypes.TEXT;
  priority: number = 50;

  public async render(data: any): Promise<AWCTableCell> {
    return { value: data.text };
  }
}
