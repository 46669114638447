import {
  TabSectionType,
  WorkspaceTab,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import { Icons } from '@ansys/awc-angular/icons';
import { ConceptSections } from '../../../../shared/enums';
import { AWFSection } from '@ansys/andromeda/core';
import { IconType } from '@ansys/awc-angular/icons';

@AWFSection({
  route: ConceptSections.COMPONENT,
})
export class ComponentSection implements TabSectionType {
  public type: ConceptSections = ConceptSections.COMPONENT;
  public title: string = 'Component';
  public icon: Icons = Icons.MODELER;

  async getTitle(
    tab: WorkspaceTab,
    section: WorkspaceTabSection
  ): Promise<string> {
    return this.title;
  }

  async getIcon(
    tab: WorkspaceTab,
    section: WorkspaceTabSection
  ): Promise<IconType> {
    return { icon: this.icon };
  }
}
