import { StateNode, StoreType } from '@ansys/andromeda/store';
import { batteryLookupHeadersConst } from '../../shared/consts/battery-lookup-headers.const';

export class SelectedBatteryLookupColumnsState implements StateNode<string[]> {
  key: string = 'selectedBatteryLookupColumnsState';
  mutable: boolean = true;
  observeInitial: boolean = true;
  toStore: StoreType = StoreType.LOCAL;
  defaultValue: () => string[] = () =>
    batteryLookupHeadersConst.map((header) => header.id);
}
