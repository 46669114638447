export enum MotorArchitectureComponentEnum {
  CLUTCH_FRONT = 'clutchFront',
  CLUTCH_REAR = 'clutchRear',
  TRANSMISSION_FRONT = 'transmissionFront',
  TRANSMISSION_REAR = 'transmissionRear',
  MOTOR_FRONT = 'motorFront',
  MOTOR_REAR = 'motorRear',
  INVERTER_FRONT = 'inverterFront',
  INVERTER_REAR = 'inverterRear',
  BATTERY = 'battery',
}
