import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import { DIALOG_DATA } from '@ansys/andromeda/shared';
import { type DriveCycleSolved } from '../../../../api';
import {
  AWCTableCellText,
  AWCTableColumns,
  AWCTableRow,
  TableCellAlign,
  TableCellComponent,
  TableCellTypes,
  TableComponent,
} from '@ansys/awc-angular/tables';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { DividerComponent } from '@ansys/awc-angular/layouts';
import { style } from '@angular/animations';

@Component({
  selector: 'app-drive-cycle-warning-dialog',
  standalone: true,
  imports: [
    TableComponent,
    CdkFixedSizeVirtualScroll,
    CommonModule,
    DividerComponent,
    TableCellComponent,
    CdkVirtualScrollViewport,
    ScrollingModule,
  ],
  templateUrl: './drive-cycle-warning-dialog.component.html',
  styleUrls: ['./drive-cycle-warning-dialog.component.scss'],
})
export class DriveCycleWarningDialogComponent implements OnInit {
  protected height: number = 0;
  protected columns: AWCTableColumns[] = [
    { id: 'point', header: 'Point', align: TableCellAlign.LEFT, width: 200 },
    { id: 'warning', header: 'Warning', align: TableCellAlign.LEFT },
  ];
  protected rows: AWCTableRow[] = [];
  protected readonly style = style;
  protected loading: boolean = true;

  constructor(
    @Inject(DIALOG_DATA)
    private data: { result: DriveCycleSolved },
    private _cdr: ChangeDetectorRef
  ) {
    const warnings = this.data.result.warnings ?? {};

    this.rows = Object.entries(warnings).map(([point, warning], index) => {
      return {
        id: index.toString(),
        cells: [
          {
            value: point,
            type: TableCellTypes.TEXT,
          } as AWCTableCellText,
          {
            value: warning,
            type: TableCellTypes.TEXT,
          } as AWCTableCellText,
        ],
      };
    });
  }

  ngOnInit(): void {
    this.calculateHeight();

    setTimeout(() => {
      this.loading = false;
      this._cdr.detectChanges();
    });
  }

  @HostListener('window:resize')
  onResize(): void {
    this.calculateHeight();
  }

  private async calculateHeight(): Promise<void> {
    const viewportHeight = window.innerHeight;
    this.height = Math.max(viewportHeight * 0.8 - 170, 0);
  }
}
