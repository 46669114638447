import { StateNode } from '@ansys/andromeda/store';
import { ConceptPopulated } from 'src/api';

export class ActiveConceptState
  implements StateNode<ConceptPopulated | undefined>
{
  key: string = 'activeConcept';
  mutable: boolean = true;
  observeInitial: boolean = true;
  defaultValue: () => ConceptPopulated | undefined = () => undefined;
}
