/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
  Input,
  Signal,
  Inject,
} from '@angular/core';
import {
  ActionContributionsService,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import { State } from '@ansys/andromeda/store';
import { ButtonSize, ButtonType } from '@ansys/awc-angular/buttons';
import { ColorBackground, Size } from '@ansys/awc-angular/core';
import { Unit } from '@ansys/awc-angular/design';
import { IconType, Icons } from '@ansys/awc-angular/icons';
import { Grid, GridFillMode } from '@ansys/awc-angular/layouts';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { Subject, Subscription, takeUntil } from 'rxjs';
import {
  ArchitectureTemplate,
  ConceptSections,
  ConceptSubSection,
} from 'src/app/shared/enums/concept.enum';
import { ConfigurationSelection } from 'src/app/shared/types/configurations.type';
import { ComponentsService } from 'src/app/shared/services/components.service';
import { ConfigurationsService } from 'src/app/shared/services/configurations.service';
import {
  DataDisplayService,
  DataDisplayState,
  DataDisplayType,
} from 'src/app/shared/services/data-display.service';
import { RequirementsService } from 'src/app/shared/services/requirements.service';
import { SectionCardTab } from '../section-card/types/section-card-tab.type';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { PlotConfigType } from 'src/app/shared/enums';
import { SelectionService } from 'src/app/shared/services/selection.service';
import { ConfigurationSelected, gridGap } from 'src/app/shared/types';
import { backgroundMap } from './lib/background-map';
import { panelTabs } from './lib/panel-tabs';
import { BatteryLookupTableID } from 'src/api';
import {
  ActiveSectionState,
  ActiveTabState,
  SectionSelectAction,
} from '@ansys/andromeda/workspace';
import { UpdateInputs } from '../../../actions';
import { AWFLocation, AWF_LOCATION } from '@ansys/andromeda/shared';
import { MotorLossTypeState } from 'src/app/state/lib/motor-loss-type.state';
import { MotorSpeedState } from 'src/app/state/lib/motor-speed.state';
import { JobLoadingStatus } from '../../../shared/enums/job-loading-status.enum';
import { GetDriveCycleDataAction } from '../../../actions/get-drive-cycle-data/get-drive-cycle-data.action';
import { InverterComponentOptionsState } from '../../../state/lib/inverter-component-options.state';

@Component({
  selector: 'data-display-panel',
  templateUrl: './data-display-panel.component.html',
  styleUrls: ['./data-display-panel.component.scss'],
})
export class DataDisplayPanelComponent implements OnDestroy, OnInit {
  @Input() public type!: ConceptSections;
  @Input() template!: ArchitectureTemplate | undefined;

  readonly ConceptSubSection = ConceptSubSection;
  readonly ConceptSections = ConceptSections;
  readonly Icons = Icons;

  protected loading: boolean = true;
  protected readonly DataDisplayState = DataDisplayState;
  protected configComplete: boolean = false;
  protected selectedSection!: ConceptSections;
  protected showConfigurationHelper: boolean = false;
  protected selectedConfigurations: ConfigurationSelected = {
    aero: false,
    mass: false,
    wheel: false,
  };
  protected paddingZero = Size._0x;
  protected filteredItems: AWCTreeItem[] = [];
  protected selectedItem!: AWCTreeItem;
  protected config: Grid = {
    columnGap: gridGap,
    columns: [GridFillMode.FILL, { value: 320, unit: Unit.PX }],
    rowGap: gridGap,
    rows: [GridFillMode.FILL],
  };
  protected goToButtonType: ButtonType = ButtonType.PRIMARY;
  protected size: ButtonSize = ButtonSize.LARGE;
  protected resultsIcon: IconType = {
    icon: Icons.RESULTS,
  };
  protected showResultsButton: boolean = true;
  protected resultsActive: boolean = false;
  protected bgImage: string | undefined;
  protected subsections: SectionCardTab[] = panelTabs;
  protected selectedSubSection: ConceptSubSection = ConceptSubSection.MAIN;
  protected plotConfigType: PlotConfigType = PlotConfigType.NONE;
  protected activeResult: any;
  protected maxHeight!: Signal<number>;
  protected color: ColorBackground = ColorBackground.PRIMARY_DEFAULT;
  protected maxSpeedLoad: boolean = false;
  private sectionChange!: Subscription | undefined;
  private bgMap: Map<ConceptSections, string> = backgroundMap;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected dataDisplay: DataDisplayService,
    private state: State,
    private _cdr: ChangeDetectorRef,
    private configService: ConfigurationsService,
    private componentService: ComponentsService,
    private requirementService: RequirementsService,
    private layoutService: LayoutService,
    private selectionService: SelectionService,
    private actions: ActionContributionsService,
    @Inject(AWF_LOCATION) private location: AWFLocation
  ) {
    this.maxHeight = this.layoutService.cardHeight;
    this.itemSelectionChangedSubscribe();
    this.sectionChangedSubscribe();
    this.updateInputsSubscribe();

    this.requirementService.jobResultLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: JobLoadingStatus) => {
        if (this.type === ConceptSections.REQUIREMENT) {
          this.dataDisplay.setFromJobStatus(status);
          this._cdr.markForCheck();
        }
      });

    this.requirementService.selectedResult
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        this.activeResult = result;
        this.plotConfigType = PlotConfigType.REQUIREMENTS;
      });

    this.dataDisplay.stateChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => this._cdr.detectChanges());
      });
  }

  ngOnInit(): void {
    this.bgImage = this.bgMap.get(this.type);
    // Since Architecture type doesn't use a TreeView, we can go straight to DataDisplayState.ARCHITECTURE
    if (this.type === ConceptSections.ARCHITECTURE) {
      this.dataDisplay.displayState = DataDisplayState.ARCHITECTURE;
    }
  }

  ngOnDestroy(): void {
    this.sectionChange?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected goToResults(): void {
    const section = this.state.value(ActiveSectionState);
    if (section && section.type === ConceptSections.REQUIREMENT) {
      this.actions.execute(SectionSelectAction, {
        sectionId: section.id,
        urlParams: ['results'],
      });
    }
  }
  protected handleLoadMaxSpeed(): void {
    this.maxSpeedLoad = true;
    setTimeout(() => {
      this.maxSpeedLoad = false;
    });
  }

  private updateInputsSubscribe(): void {
    this.actions
      .get(UpdateInputs)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.filteredItems.length) {
          this.resetConfigHelp();
          this.filteredItems = this.selectionService.getSelectedItems() ?? [];
          this.handleSelection();
        }
      });
  }

  private sectionChangedSubscribe(): void {
    this.actions
      .get(SectionSelectAction)
      .pipe(takeUntil(this.destroy$))
      .subscribe((section?: WorkspaceTabSection) => {
        if (!section) return;
        this.handleSectionChange(section);
        this._cdr.detectChanges();
      });

    const section = this.state.value(ActiveSectionState);
    if (section) {
      this.handleSectionChange(section);
    }
  }

  private itemSelectionChangedSubscribe(): void {
    this.selectionService.sectionSelectionChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((items: AWCListItem[]) => {
        this.selectedSection = this.state.value(ActiveSectionState)
          ?.type as ConceptSections;
        this.dataDisplay.displayState = DataDisplayState.LOADING;

        if (this.selectedSection === ConceptSections.ARCHITECTURE) {
          this.dataDisplay.displayState = DataDisplayState.ARCHITECTURE;
        }
        if (this.selectedSection === ConceptSections.CONFIGURATIONS) {
          this.dataDisplay.displayState = DataDisplayState.CONFIG_HELP;
        }
        this.filteredItems = items as AWCTreeItem[];
        this.plotConfigType = PlotConfigType.NONE;
        this.handleSelection();
      });
  }

  private handleSectionChange(section: WorkspaceTabSection): void {
    if (
      this.selectedSection === ConceptSections.REQUIREMENT &&
      section?.urlParams?.length
    ) {
      if (section.urlParams[0] === 'results') {
        this.showResultsButton = false;
      } else {
        this.showResultsButton = true;
        this.activeResult = undefined;
      }
    } else {
      this.showResultsButton = true;
      this.activeResult = undefined;
    }
  }

  private async handleSelection(): Promise<void> {
    if (!this.filteredItems || !this.filteredItems.length) {
      const sectionState = this.dataDisplay.getHelpSection(
        this.selectedSection
      );

      if (this.dataDisplay.displayState !== sectionState) {
        this.dataDisplay.displayState = this.dataDisplay.getHelpSection(
          this.selectedSection
        );
        this._cdr.markForCheck();
      }
      return;
    }

    if (this.selectedSection === ConceptSections.CONFIGURATIONS) {
      this.handleConfigurationSelection();
    } else if (this.selectedSection === ConceptSections.COMPONENT) {
      this.handleComponentSelection();
    } else if (this.selectedSection === ConceptSections.REQUIREMENT) {
      await this.handleRequirementSelection();
    }
    this._cdr.markForCheck();
  }

  /**
   * Resets the configuration helper model
   */
  private resetConfigHelp(): void {
    this.selectedConfigurations = {
      aero: false,
      mass: false,
      wheel: false,
    };
  }

  /**
   * Handles the component selection. If there is no data to display, it will show the no data display.
   */
  private handleComponentSelection(): void {
    this.resetConfigHelp();
    this.dataDisplay.displayState = DataDisplayState.LOADING;
    const selectedItem = this.filteredItems[0];
    this.selectedItem = selectedItem;

    if (!selectedItem || !selectedItem.userData) return;
    const displayType = this.dataDisplay.getDisplayType(
      selectedItem.userData['component_type'] as string
    );
    if (displayType === DataDisplayType.NONE) {
      this.dataDisplay.displayState = DataDisplayState.NO_DATA;
    }
    if (displayType === DataDisplayType.LOOKUP) {
      this.componentService
        .buildBatteryLookup(selectedItem.userData as BatteryLookupTableID)
        .then(() => {
          this.dataDisplay.displayState = DataDisplayState.TABLE_DISPLAY;
        });
    }
    if (displayType === DataDisplayType.LOSSMAP) {
      if (selectedItem.userData['component_type'] === 'MotorLabModel') {
        this.plotConfigType = PlotConfigType.LAB_MOTOR;
      }
      if (selectedItem?.userData['gear_ratios']) {
        this.plotConfigType = PlotConfigType.GEARS;
      }
      if (selectedItem.userData['voltages']) {
        this.plotConfigType = PlotConfigType.VOLTAGE;
      }
      if (selectedItem.userData['component_type'] === 'InverterAnalytical') {
        this.plotConfigType = PlotConfigType.INVERTER_LOSSES;
      }

      const activeTab = this.state.value(ActiveTabState);
      let extOpts: any = {};

      if (selectedItem.userData['component_type'] === 'MotorLabModel') {
        extOpts = {
          lossType: this.state.value(MotorLossTypeState, activeTab?.id || ''),
          speed: this.state.value(MotorSpeedState, activeTab?.id || ''),
        };
      }

      if (selectedItem.userData['component_type'] === 'InverterAnalytical') {
        extOpts = {
          ...extOpts,
          ...this.state.value(InverterComponentOptionsState),
        };
      }

      this.componentService
        .displayData(selectedItem.id as string, {
          type: selectedItem.parent?.id ?? 'motor',
          ...extOpts,
        })
        .then(() => {
          this.dataDisplay.displayState = DataDisplayState.GRAPH_DISPLAY;
        })
        .catch(() => {
          // do nothing
        });
    }
  }

  private async handleRequirementSelection(): Promise<void> {
    this.resetConfigHelp();
    const selectedItem = this.filteredItems[0];
    if (!selectedItem || !selectedItem.userData) return;
    const selectedSection = this.state.value(ActiveSectionState);
    const resultsTab: boolean = !!(
      selectedSection?.urlParams && selectedSection.urlParams[0] === 'results'
    );
    if (
      selectedItem.userData['requirement_type'] === 'drive_cycle' &&
      !resultsTab &&
      this.dataDisplay.displayState !== DataDisplayState.LOADING
    ) {
      this.dataDisplay.displayState = DataDisplayState.LOADING;
      const success = await this.actions.execute(GetDriveCycleDataAction, [
        selectedItem.userData['drive_cycle_id'] as string,
        selectedItem.id,
      ]);

      if (success) {
        this.dataDisplay.displayState = DataDisplayState.GRAPH_DISPLAY;
      }
    } else {
      this.dataDisplay.displayState = this.dataDisplay.getHelpSection(
        this.selectedSection
      );
    }
    this.tryCDR();
  }

  private tryCDR(): void {
    try {
      setTimeout(() => this._cdr.detectChanges());
    } catch (error) {
      this._cdr.markForCheck();
    }
  }

  /**
   * Handles the configuration selection. If there are less than 3 configurations selected, it will show the helper.
   * If there are 3 or more, it will send for a display data request.
   */
  private handleConfigurationSelection(): void {
    this.plotConfigType = PlotConfigType.CONFIGURATIONS;
    const configIDs: ConfigurationSelection =
      this.configService.setConfigurationIDs(this.filteredItems);

    if (!configIDs.aero || !configIDs.mass || !configIDs.wheel) {
      this.dataDisplay.displayState = DataDisplayState.CONFIG_HELP;
      this.filteredItems.forEach((item: AWCTreeItem) => {
        if (!item.userData) return;
        switch (item.userData['_parentID']) {
          case 'aero':
          case 'mass':
          case 'wheel':
            this.selectedConfigurations[item.userData['_parentID']] = true;
            break;
        }
      });
    } else {
      this.resetConfigHelp();
      this.dataDisplay.displayState = DataDisplayState.LOADING;
      this.configService
        .displayData(configIDs)
        .then(() => {
          this.dataDisplay.displayState = DataDisplayState.GRAPH_DISPLAY;
        })
        .catch(() => {
          //do nothing
        });
    }
  }
}
