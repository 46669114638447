import {
  TabSectionType,
  WorkspaceTab,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import { ConceptSections } from '../../../../shared/enums';
import { AWFSection } from '@ansys/andromeda/core';
import { IconType } from '@ansys/awc-angular/icons';

@AWFSection({
  route: ConceptSections.CONFIGURATIONS,
})
export class VehicleSection implements TabSectionType {
  public type: ConceptSections = ConceptSections.CONFIGURATIONS;
  public title: string = 'Vehicle';
  public src: string = 'assets/icons/vehicle.svg';

  async getTitle(
    tab: WorkspaceTab,
    section: WorkspaceTabSection
  ): Promise<string> {
    return this.title;
  }

  async getIcon(
    tab: WorkspaceTab,
    section: WorkspaceTabSection
  ): Promise<IconType> {
    return {
      src: this.src,
    };
  }
}
