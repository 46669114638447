/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Aero Configuration.
 */
export type Aero = {
    item_type?: Aero.item_type;
    name?: string;
    drag_coefficient_front?: number; // TODO: Don't remove this property via codegen
    drag_coefficient_rear?: (number | null);
    cross_sectional_area?: number;
    config_type?: Aero.config_type;
};
export namespace Aero {
    export enum item_type {
        CONFIG = 'config',
    }
    export enum config_type {
        AERO = 'aero',
    }
}

