<div class="arch-panel-component-information">
  <div class="isolation-box">
    <awc-select
      label="Wheel Configuration"
      placeholder="Optional..."
      [awcTooltip]="'The wheel used to get the max vehicle speed'"
      [items]="wheels"
      [selectedItems]="[selectedWheel?.id ?? ' ']"
      (selectedItemsChange)="onWheelSelected($event)"
    ></awc-select>
    <app-input
      label="Max speed"
      [awcTooltip]="'The max speed of the vehicle based upon the component rotational speed limits'"
      [value]="selectedWheel?.id ? maxSpeed : null"
      [placeholder]="'Please select a wheel'"
      [unit]="unitType.SPEED"
      [loading]="loadingMaxSpeed"
      [readonly]="true"
    ></app-input>
  </div>

    <app-input
      label="Component cost (excluding wheels)"
      [value]="componentsCost"
      [loading]="loadingArchitecture"
      [readonly]="true"></app-input>
    <app-input
      label="Component mass (excluding wheels)"
      [value]="componentsMass"
      [loading]="loadingArchitecture"
      [unit]="unitType.MASS"
      [readonly]="true"></app-input>
</div>
