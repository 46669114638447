import { StateNode } from '@ansys/andromeda/store';

export class ConceptMetaState
  implements StateNode<{ projectTitle: string; projectBranch: string }>
{
  key: string = 'concept-meta';
  mutable: boolean = true;
  observeInitial: boolean = false;
  defaultValue: () => { projectTitle: string; projectBranch: string } = () => {
    return {
      projectTitle: 'Project',
      projectBranch: 'Branch',
    };
  };
}
