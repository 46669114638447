/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  ApiError,
  ArchitecturesService,
  BatteryFixedVoltages,
  BatteryLookupTableData,
  BatteryLookupTableID,
  ComponentFileType,
  ComponentInDB,
  ComponentsService as API,
  ConceptPopulated,
  InverterAnalytical,
  MotorCTCP,
  MotorLabID,
  MotorLossMap,
  MotorLossMapID,
  MotorTorqueCurves,
  TransmissionLossCoefficients,
  TransmissionLossMap,
  UnitType,
} from 'src/api';
import { ConceptPartType, PartServiceType } from '../types';
import { State } from '@ansys/andromeda/store';
import { PlotService } from './plot.service';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import {
  ActiveSectionState,
  ActiveTabState,
  SectionSelectAction,
  SectionUpdateAction,
} from '@ansys/andromeda/workspace';
import { sectionState } from '../models/section-state';
import {
  AWCTableColumns,
  AWCTableRow,
  TableCellTypes,
} from '@ansys/awc-angular/tables';
import { ConceptUnitService } from './unit.service';
import {
  ActionContributionsService,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import { lastValueFrom } from 'rxjs';
import { AnalyticEventEnum, ConceptInputType, ConceptSections } from '../enums';
import { SnackBarService } from '@ansys/andromeda/shared';
import { FileService } from './file.service';
import { ActiveConceptState } from 'src/app/state/lib/active-concept.state';
import { ComponentsState } from 'src/app/state/lib/components.state';
import { SelectedItemState } from 'src/app/state/lib/selected-item.state';
import { NotificationService } from './notification.service';
import {
  AnalyticsEventPayload,
  SubmitAnalyticsEventAction,
} from '@ansys/andromeda/analytics';
import { ScientificNotationPipe } from '../pipes/scientific-notation/scientific-notation.pipe';
import { HttpResponse } from '@angular/common/http';
import { PlotTypeEnum } from '../enums/plot-type.enum';
import { LossTypeEnum } from '../enums/loss-type.enum';
import {
  UploadFileResponseObjectType,
  UploadFileResponseType,
} from '../types/upload-file-response.type';
import { InverterLossesType } from '../enums/inverter-losses-type';
import { DownloadFilesAction } from '../../actions';
import { ComposeInverterLossesAction } from '../../actions/compose-invert-loss/compose-inverter-losses.action';

export type ComponentType =
  | BatteryFixedVoltages
  | MotorCTCP
  | MotorTorqueCurves
  | MotorLossMap
  | TransmissionLossCoefficients
  | TransmissionLossMap
  | InverterAnalytical;

export type MotorType = MotorLossMap | MotorLossMapID;

/**
 */

interface LossMapGrid {
  losses: [number[]];
  losses_total?: [number[]];
  losses_iron?: [number[]];
  speeds: number[];
  torques: number[];
  currents?: number[];
  phase_advances?: number[];
  slips?: number[];
  losses_switching?: number[];
  losses_conduction?: number[];
  losses_dc_harness?: number[];
  losses_ac_harness?: number[];
}

type DisplayDataOptions = {
  voltage?: number;
  gearRatio?: number;
  type?: string;
  lossType?: InverterLossesType | string;
  speed?: number;
  dc_current?: number | null;
  power_factor?: number | null;
  phase_current_max?: number | null;
  frequency?: number | null;
};

@Injectable({ providedIn: 'root' })
export class ComponentsService implements PartServiceType {
  private instanceId!: string;
  private activeSection!: string;

  constructor(
    private API: API,
    private state: State,
    private plotService: PlotService,
    private unitService: ConceptUnitService,
    private architectureAPI: ArchitecturesService,
    private notificationService: NotificationService,
    private uploadService: FileService,
    private snackbar: SnackBarService,
    private actions: ActionContributionsService,
    private unitChoices: ConceptUnitService,
    private snPipe: ScientificNotationPipe
  ) {
    this.state
      .get(ActiveConceptState)
      .subscribe((concept?: ConceptPopulated) => {
        if (concept) {
          this.instanceId = concept.design_instance_id as string;
        }
      });
    this.activeSection = this.state.value(ActiveSectionState)?.type || '';
    this.actions
      .get(SectionSelectAction)
      .subscribe((section?: WorkspaceTabSection) => {
        if (section) {
          this.activeSection = section.type;
        }
      });
  }

  public async addPart(
    item: ConceptPartType,
    file?: File,
    type?: ComponentFileType | string,
    uploadResponse?: UploadFileResponseType
  ): Promise<ComponentInDB> {
    let resp: ComponentInDB;
    if (file) {
      resp = await this.addPartFromFile(
        item,
        file,
        type as ComponentFileType,
        uploadResponse
      );
    } else {
      resp = await lastValueFrom(
        this.API.createComponentsPost(this.instanceId, {
          ...item,
        } as ComponentType)
      ).catch((err: ApiError) => {
        const message = err.body?.message ?? 'Error Creating Component!';
        this.snackbar.error(message);
        throw new Error(err.body);
      });
    }

    const eventData: AnalyticsEventPayload = {
      type: AnalyticEventEnum.COMPONENT_ADDED,
      data: { component_type: resp.component_type },
    };
    this.actions.execute(SubmitAnalyticsEventAction, eventData);

    this.snackbar.success('Component Created!');
    return resp;
  }

  public async updatePart(item: AWCTreeItem): Promise<ComponentInDB> {
    const id = item.userData ? (item.userData['id'] as string) : null;
    if (!id) throw new Error('No ID provided');
    this.snackbar.info('Updating Component!');
    const resp = await lastValueFrom(
      this.API.updateComponentsItemIdPut(
        id,
        this.instanceId,
        item.userData as ComponentType
      )
    ).catch((err) => {
      this.notificationService.error('Error Updating Component', err);
      throw new Error(err);
    });
    item.text = resp.name;
    item.userData = {
      ...item.userData,
      ...resp,
    };
    if (item.parent) {
      (item.parent as any).dirty = true;
    }

    this.toUpdateArchitecture(item);

    const tab = this.state.value(ActiveTabState);
    const currentState: AWCTreeItem[] = this.state.value(
      ComponentsState,
      tab?.id
    );
    const index = currentState.findIndex(
      (stateItem) => stateItem.id === item.id
    );
    currentState[index] = item;
    this.state.set(ComponentsState, currentState, tab?.id);

    if (item.userData?.['component_type']) {
      const eventData: AnalyticsEventPayload = {
        type: AnalyticEventEnum.COMPONENT_UPDATED,
        data: { config_type: item.userData?.['component_type'] },
      };
      this.actions.execute(SubmitAnalyticsEventAction, eventData);
    }

    this.snackbar.success('Component Updated!');
    return resp;
  }

  public async buildBatteryLookup(
    battery: BatteryLookupTableID
  ): Promise<[AWCTableColumns[], AWCTableRow[]]> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<[AWCTableColumns[], AWCTableRow[]]>(async (resolve) => {
      const columns: AWCTableColumns[] = [
        {
          id: 'voltage',
          header: `Voltage (${this.unitChoices.getChoice(UnitType.VOLTAGE)})`,
        },
        {
          id: 'usable_charge',
          header: `Usable Charge (${this.unitChoices.getChoice(
            UnitType.ELECTRICAL_ENERGY
          )})`,
        },
        {
          id: 'state_of_charge',
          header: `State of Charge (${this.unitChoices.getChoice(
            UnitType.RATIO
          )})`,
        },
        {
          id: 'power_limit_charge',
          header: `Power Limit (${this.unitChoices.getChoice(
            UnitType.ELECTRICAL_POWER
          )})`,
        },
        {
          id: 'internal_resistance',
          header: `Internal Resistance (${this.unitChoices.getChoice(
            UnitType.RESISTANCE
          )})`,
        },
      ];
      const batteryData: BatteryLookupTableData = await lastValueFrom(
        this.API.calcDisplayDataComponentsGetDisplayDataPost(
          battery.id ?? '',
          this.instanceId
        )
      );
      const rows: AWCTableRow[] = batteryData.voltage.map(
        (v: number, index: number) => {
          return {
            id: index.toString(),
            cells: [
              {
                value: this.snPipe.transform(v),
                type: TableCellTypes.NUMBER,
              },
              {
                value: this.snPipe.transform(batteryData.usable_charge[index]),
                type: TableCellTypes.NUMBER,
              },
              {
                value: this.snPipe.transform(
                  batteryData.state_of_charge[index]
                ),
                type: TableCellTypes.NUMBER,
              },
              {
                value: this.snPipe.transform(
                  batteryData.power_limit_charge[index]
                ),
                type: TableCellTypes.NUMBER,
              },
              {
                value: this.snPipe.transform(
                  batteryData.internal_resistance[index]
                ),
                type: TableCellTypes.NUMBER,
              },
            ],
          };
        }
      );
      this.plotService.setTableData([columns, rows, [], []]);
      resolve([columns, rows]);
    });
  }

  public displayData(
    displayPartId?: string,
    opts?: DisplayDataOptions
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.API.calcDisplayDataComponentsGetDisplayDataPost(
        displayPartId as string,
        this.instanceId,
        this.buildRequestPayload(opts)
      ).subscribe(async (resp: LossMapGrid) => {
        const lossType = this.getLossType(resp, opts);
        const { x, y, x2data, y2data } = this.getAxisData(resp, opts, lossType);
        const zAxis = (lossType ?? resp.losses) as [never[]];
        const plotType = Array.isArray(zAxis?.[0])
          ? PlotTypeEnum.CONTOUR
          : PlotTypeEnum.SCATTER;

        const data = this.buildPlotData(
          plotType,
          x,
          y,
          zAxis,
          x2data,
          y2data,
          opts
        );
        const layout = this.getLayout(resp, opts);

        if (this.isCurrentSelection(displayPartId)) {
          this.plotService.setGraphData(data);
          this.plotService.updateGraphLayout(layout);
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  private buildRequestPayload(opts?: DisplayDataOptions): Record<string, any> {
    return {
      voltage: opts?.voltage,
      gear_ratio: opts?.gearRatio,
      speed: opts?.speed,
      dc_current: opts?.dc_current ?? undefined,
      power_factor: opts?.power_factor ?? undefined,
      phase_current_max: opts?.phase_current_max ?? undefined,
      frequency: opts?.frequency ?? undefined,
    };
  }

  private getLossType(
    resp: LossMapGrid,
    opts?: DisplayDataOptions
  ): number[] | number[][] | undefined {
    if (opts?.type === ConceptInputType.INVERTER) {
      return this.getInverterLossType(
        resp,
        opts?.lossType as InverterLossesType
      );
    } else if (opts?.type === ConceptInputType.MOTOR) {
      return opts?.lossType === LossTypeEnum.IRON
        ? resp.losses_iron
        : resp.losses_total;
    } else if (opts?.type === ConceptInputType.TRANSMISSION) {
      return resp.losses_total;
    }
    return undefined;
  }

  private buildPlotData(
    plotType: PlotTypeEnum,
    x: any,
    y: any,
    zAxis: any,
    x2data?: any,
    y2data?: any,
    opts?: DisplayDataOptions
  ): any[] {
    const commonData = {
      type: plotType,
      colorscale: 'Electric',
      x,
      y,
      z: zAxis,
      colorbar: {
        title: `Loss (${this.unitService.getChoice(
          opts?.type === 'transmission' ? UnitType.TORQUE : UnitType.POWER
        )})`,
      },
    };

    const y2 = y2data
      ? {
          ...commonData,
          x: x2data,
          y: y2data,
          line: { color: '#1f77b4' },
        }
      : {};

    return [commonData, y2];
  }

  private isCurrentSelection(displayPartId?: string): boolean {
    const activeSection = this.state.value(ActiveSectionState);
    const currentSelection = this.state.value(
      SelectedItemState,
      activeSection?.id
    );
    return (
      displayPartId === currentSelection?.id &&
      this.activeSection === ConceptSections.COMPONENT
    );
  }

  private getInverterLossType(
    resp: LossMapGrid,
    lossTypeKey?: InverterLossesType
  ): number[] | number[][] {
    switch (lossTypeKey) {
      case InverterLossesType.SWITCHING:
        return resp.losses_switching || [];
      case InverterLossesType.CONDUCTION:
        return resp.losses_conduction || [];
      case InverterLossesType.DC_HARNESS:
        return resp.losses_dc_harness || [];
      case InverterLossesType.AC_HARNESS:
        return resp.losses_ac_harness || [];
      default:
        return resp.losses_total || [];
    }
  }

  private getAxisData(
    resp: LossMapGrid,
    opts?: DisplayDataOptions,
    lossType?: number[] | number[][] | undefined
  ): { x: number[]; y: number[]; x2data?: number[]; y2data?: number[] } {
    const x = (resp.currents ?? resp.speeds) as number[];
    let y: number[];
    let x2data: number[] | undefined;
    let y2data: number[] | undefined;

    if (opts?.type === ConceptInputType.INVERTER) {
      y = lossType as number[];
    } else if (opts?.type === ConceptInputType.MOTOR) {
      y = resp.phase_advances ?? resp.slips ?? resp.torques ?? [];
      if (
        'generating_torques' in resp &&
        Array.isArray(resp.generating_torques) &&
        'generating_speeds' in resp &&
        Array.isArray(resp.generating_speeds)
      ) {
        y2data = resp.generating_torques;
        x2data = resp.generating_speeds;
      }
    } else {
      y = resp.phase_advances ?? resp.slips ?? resp.torques ?? [];
    }

    return { x, y, x2data, y2data };
  }

  private getLayout(resp: LossMapGrid, opts?: DisplayDataOptions): any {
    let yTitle = '';
    if (resp.phase_advances) {
      yTitle = `Phase Advance (${this.unitService.getChoice(
        UnitType.GRADIENT
      )})`;
    } else if (resp.slips) {
      yTitle = 'Slip';
    } else {
      yTitle = `Torque (${this.unitService.getChoice(UnitType.TORQUE)})`;
    }

    if (opts?.type === ConceptInputType.INVERTER) {
      switch (opts?.lossType) {
        case InverterLossesType.SWITCHING:
          yTitle = 'Switching Losses';
          break;
        case InverterLossesType.CONDUCTION:
          yTitle = 'Conduction Losses';
          break;
        case InverterLossesType.AC_HARNESS:
          yTitle = 'AC Harness Losses';
          break;
        case InverterLossesType.DC_HARNESS:
          yTitle = 'DC Harness Losses';
          break;
        default:
          yTitle = 'Total Losses';
          break;
      }
      yTitle += ` (${this.unitService.getChoice(UnitType.POWER)})`;
    }

    let showlegend = true;
    if (
      (opts?.type === ConceptInputType.MOTOR &&
        !resp.currents &&
        !resp.phase_advances &&
        !resp.slips) ||
      opts?.type === ConceptInputType.INVERTER
    ) {
      showlegend = false;
    }

    const currentLabel = `Current (${this.unitService.getChoice(
      UnitType.CURRENT
    )})`;

    return {
      xaxis: {
        range: [],
        title: resp.currents
          ? `${
              opts?.type === ConceptInputType.INVERTER ? 'Phase' : 'Stator'
            } ${currentLabel}`
          : `Speed (${this.unitService.getChoice(UnitType.ANGULAR_SPEED)})`,
      },
      yaxis: {
        range: [],
        title: yTitle,
      },
      showlegend,
    };
  }

  public async exportInverterData(
    displayPartId?: string,
    opts?: DisplayDataOptions
  ): Promise<void> {
    const lossData = await lastValueFrom(
      this.API.calcDisplayDataComponentsGetDisplayDataPost(
        displayPartId as string,
        this.instanceId,
        {
          voltage: opts?.voltage,
          gear_ratio: opts?.gearRatio,
          speed: opts?.speed,
          dc_current: opts?.dc_current || undefined,
          power_factor: opts?.power_factor || undefined,
          phase_current_max: opts?.phase_current_max || undefined,
          frequency: opts?.frequency || undefined,
        }
      )
    );

    const csvContent = await this.actions.execute(ComposeInverterLossesAction, [
      lossData,
    ]);

    await this.actions.execute(DownloadFilesAction, [
      'Inverter Losses Data.csv',
      csvContent,
    ]);
  }

  public deletePart(item: AWCTreeItem): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const id = item.userData ? (item.userData['id'] as string) : null;
      if (!id) throw new Error('No ID provided');
      this.API.deleteComponentsItemIdDelete(id, this.instanceId).subscribe(
        () => {
          const tab = this.state.value(ActiveTabState);
          const currentState: AWCTreeItem[] = this.state.value(
            ComponentsState,
            tab?.id
          );
          const index = currentState.findIndex(
            (stateItem) => stateItem.id === item.id
          );
          currentState.splice(index, 1);
          this.state.set(ComponentsState, currentState, tab?.id);

          if (item.userData?.['component_type']) {
            const eventData: AnalyticsEventPayload = {
              type: AnalyticEventEnum.COMPONENT_DELETED,
              data: { config_type: item.userData?.['component_type'] },
            };
            this.actions.execute(SubmitAnalyticsEventAction, eventData);
          }

          this.snackbar.success('Component Deleted!');
          resolve({});
        },
        (err) => {
          const message = err.body?.message ?? 'Error Deleting Component!';
          this.snackbar.error(message);
          reject();
          throw new Error(err);
        }
      );
    });
  }

  private async addPartFromFile(
    item: ConceptPartType,
    file: File,
    type?: ComponentFileType,
    uploadResponse?: UploadFileResponseType
  ): Promise<ComponentInDB> {
    const name = item.name || 'New Component';
    let id;
    let responseObject!: UploadFileResponseObjectType;

    if (!uploadResponse) {
      const uploadResp = await this.uploadFilePart(file, type);
      [id, responseObject] = uploadResp.body;
    } else {
      [id, responseObject] = [uploadResponse.id, uploadResponse.responseObject];
    }

    const resp = await lastValueFrom(
      this.API.createComponentsPost(this.instanceId, {
        ...item,
        name,
        mass: (item as MotorType).mass,
        cost: (item as MotorType).cost,
        inverter_losses_included: (item as MotorType).inverter_losses_included,
        component_type: (item as MotorLossMapID).component_type,
        data_id: id as string,
        max_speed: (item as MotorLabID).max_speed ?? responseObject?.max_speed,
        rotor_temp:
          (item as MotorLabID).rotor_temp ?? responseObject?.rotor_temp,
        stator_current_limit:
          (item as MotorLabID).stator_current_limit ??
          responseObject?.stator_current_limit,
        stator_winding_temp:
          (item as MotorLabID).stator_winding_temp ??
          responseObject?.stator_winding_temp,
      } as never)
    ).catch((err) => {
      this.notificationService.error('Error Creating Component', err);
      throw new Error(err);
    });
    this.snackbar.success('Component Created!');
    return resp;
  }

  public async uploadFilePart(
    file: File,
    type?: ComponentFileType
  ): Promise<HttpResponse<any>> {
    return await lastValueFrom(
      this.uploadService.uploadFile(
        type as ComponentFileType,
        this.instanceId,
        file
      )
    ).catch((err) => {
      this.notificationService.error('Error Uploading File', err);
      throw new Error(err);
    });
  }

  private async toUpdateArchitecture(item: AWCTreeItem): Promise<void> {
    const concept = this.state.value(ActiveConceptState);
    const architecture = concept?.architecture;
    let toUpdateArchitecture = false;
    if (!architecture) return;

    if (item.parent?.id === ConceptInputType.TRANSMISSION) {
      toUpdateArchitecture =
        architecture.front_transmission_id === item.id ||
        architecture.rear_transmission_id === item.id;
    }
    if (item.parent?.id === ConceptInputType.MOTOR) {
      toUpdateArchitecture =
        architecture.front_motor_id === item.id ||
        architecture.rear_motor_id === item.id;
    }
    if (item.parent?.id === ConceptInputType.BATTERY) {
      toUpdateArchitecture = architecture.battery_id === item.id;
    }
    if (item.parent?.id === ConceptInputType.INVERTER) {
      toUpdateArchitecture =
        architecture.front_inverter_id === item.id ||
        architecture.rear_inverter_id === item.id;
    }
    if (item.parent?.id === ConceptInputType.CLUTCH) {
      toUpdateArchitecture =
        architecture.front_clutch_id === item.id ||
        architecture.rear_clutch_id === item.id;
    }
    if (toUpdateArchitecture) {
      concept.architecture = await lastValueFrom(
        this.architectureAPI.updateArchitectureArchitecturesItemIdPut(
          architecture.id as string,
          this.instanceId,
          architecture
        )
      );
      this.state.set(ActiveConceptState, concept);
    }
  }

  public shouldUpdateSection(item: ConceptPartType, parent?: string): boolean {
    const tab = this.state.value(ActiveTabState);
    const currentState = this.state.value(ComponentsState, tab?.id || '') || [];
    const transmissionCheck =
      currentState.find(
        (part: AWCTreeItem) =>
          part.userData && part.userData['_parentID'] === 'transmission'
      ) || parent === 'transmission';
    const motorCheck =
      currentState.find(
        (part: AWCTreeItem) =>
          part.userData && part.userData['_parentID'] === 'motor'
      ) || parent === 'motor';
    const batteryCheck =
      currentState.find(
        (part: AWCTreeItem) =>
          part.userData && part.userData['_parentID'] === 'battery'
      ) || parent === 'battery';

    if (transmissionCheck && motorCheck && batteryCheck) {
      sectionState.architecture = true;
      this.actions.execute(SectionUpdateAction);
    }
    return !!(transmissionCheck && motorCheck && batteryCheck);
  }

  /**
   * Gets component relative to the config id and design id
   * @param {string} id - ID of the component to get
   * @param {string} designId - ID of the design to get the component from
   * @returns {void}
   */
  public async get(id: string, designId: string): Promise<ComponentInDB> {
    return await lastValueFrom(this.API.readComponentsItemIdGet(id, designId));
  }
}
