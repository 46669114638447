import { StateNode } from '@ansys/andromeda/store';
import { AWCTreeItem } from '@ansys/awc-angular/trees';

export class ComponentsState implements StateNode<AWCTreeItem[]> {
  key: string = 'components';
  mutable: boolean = true;
  observeInitial: boolean = true;
  defaultValue: () => AWCTreeItem[] = () => [];
  onChange: (state: any) => void = (state) => {};
}
