/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StaticRequirementAccelerationIds, UnitType, WheelInDB } from 'src/api';
import { InputTypes } from '../../../forms/input/input.component';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { defaultPartsMap } from '../../../../dialogs/add-part/lib/default-parts-map';
import { PartType } from '../../../../../shared/enums';
export type SetPart = [value: string, type: string, id: string, index?: number];

@Component({
  selector: 'app-wheels-inputs',
  templateUrl: './wheels.component.html',
  styleUrls: ['../inputs.scss'],
})
export class WheelsComponent {
  @Input() part!: WheelInDB;
  @Input() update!: boolean;
  @Input() readonly!: boolean;
  @Output() setPart: EventEmitter<SetPart> = new EventEmitter<SetPart>();
  @Output() forceUpdate: EventEmitter<string> = new EventEmitter<string>();

  [key: string]: any;
  protected UnitType = UnitType;
  protected InputTypes = InputTypes;
  protected rollingResistanceKeys: AWCListItem[] = [
    {
      id: 'manual',
      text: 'Manual',
    },
  ];
  protected rollingResistanceKey: string = this.rollingResistanceKeys[0].id;
  protected tractionCoefficientKeys: AWCListItem[] = [
    {
      id: 'manual',
      text: 'Manual',
    },
  ];
  protected tractionCoefficientKey: string = this.tractionCoefficientKeys[0].id;
  protected rollingResistance: string = '';
  protected tractionCoefficient: string = '';
  private defaultValues: any;
  private defaultRollingResistance: string = ''; // '0.02';
  private defaultTractionCoefficient: string = ''; // '0.9';

  ngOnInit(): void {
    this.initializeDefaultValues();
    this.setInitialValues();
    this.updateSelectionItems(false);
  }

  protected selectionItems(
    $event: string,
    type: 'rolling_resistance_key' | 'traction_coefficient_key',
    valueType: 'rolling_resistance_coefficient' | 'traction_coefficient',
    triggerUpdate: boolean = true
  ): void {
    const isRollingResistance = type === 'rolling_resistance_key';

    const defaultValuesMap = isRollingResistance
      ? this.defaultValues?.rolling_resistance_key
      : this.defaultValues?.tractions_key;

    const defaultValue = isRollingResistance
      ? this.defaultRollingResistance
      : this.defaultTractionCoefficient;

    const keyProperty = isRollingResistance
      ? 'rollingResistanceKey'
      : 'tractionCoefficientKey';

    const valueProperty = isRollingResistance
      ? 'rollingResistance'
      : 'tractionCoefficient';

    const partKey = isRollingResistance
      ? 'rolling_resistance_key'
      : 'traction_coefficient_key';

    const partValue = isRollingResistance
      ? 'rolling_resistance_coefficient'
      : 'traction_coefficient';

    this[keyProperty] = $event;
    this[valueProperty] = defaultValuesMap?.[$event] || defaultValue;

    if ($event === 'manual') {
      this.part[partValue] = +this[valueProperty];
      this.part[partKey] = undefined as never;

      if (triggerUpdate) {
        this.setPart.emit([
          this[valueProperty],
          valueType,
          this.part.id + '_' + valueType,
        ]);
      }
    } else {
      this.part[partValue] = undefined;
      this.part[partKey] = $event as never;

      if (triggerUpdate) {
        this.setPart.emit([$event, partKey, this.part.id + '_' + partKey]);
      }
    }

    if (triggerUpdate) {
      this.forceUpdate.emit('');
    }
  }

  protected setPartValue(value: string, type: string, id: string): void {
    switch (type) {
      case 'rolling_resistance_coefficient':
        this.part.rolling_resistance_coefficient = +value;
        this.part.rolling_resistance_key = undefined as never; // Clear the key since it's now manual
        break;
      case 'traction_coefficient':
        this.part.traction_coefficient = +value;
        this.part.traction_coefficient_key = undefined as never; // Clear the key since it's now manual
    }

    this.setPart.emit([value, type, id]);
  }

  private initializeDefaultValues(): void {
    this.defaultValues = (
      defaultPartsMap.get(PartType.WHEEL) as { wheel_constants_options: any }
    ).wheel_constants_options;

    this.setupDefaultKeys('rolling_resistance_key', 'manual');
    this.setupDefaultKeys('tractions_key', 'manual');
  }

  private setupDefaultKeys(keyName: string, manualKey: string): void {
    if (this.defaultValues?.[keyName]) {
      const defaultKeys = Object.keys(this.defaultValues[keyName]).map(
        (key) => ({
          id: key,
          text: key,
        })
      );

      if (keyName === 'rolling_resistance_key') {
        this.rollingResistanceKeys = [
          { id: manualKey, text: 'Manual' },
          ...defaultKeys,
        ];
      } else if (keyName === 'tractions_key') {
        this.tractionCoefficientKeys = [
          { id: manualKey, text: 'Manual' },
          ...defaultKeys,
        ];
      }
    }
  }

  private setInitialValues(): void {
    if (this.update) {
      this.setValuesFromPart();
    } else {
      this.setDefaultValues();
    }
  }

  private setValuesFromPart(): void {
    this.rollingResistanceKey = this.part.rolling_resistance_key || 'manual';
    this.rollingResistance =
      this.part.rolling_resistance_coefficient?.toString() || '';

    this.tractionCoefficientKey =
      this.part.traction_coefficient_key || 'manual';
    this.tractionCoefficient = this.part.traction_coefficient?.toString() || '';
  }

  private setDefaultValues(): void {
    this.rollingResistanceKey = this.part.rolling_resistance_key || 'manual';
    this.rollingResistance =
      this.part.rolling_resistance_coefficient?.toString() ||
      this.defaultRollingResistance;

    this.tractionCoefficientKey =
      this.part.traction_coefficient_key || 'manual';
    this.tractionCoefficient =
      this.part.traction_coefficient?.toString() ||
      this.defaultTractionCoefficient;
  }

  private updateSelectionItems(triggerUpdate: boolean = true): void {
    if (this.rollingResistanceKey !== 'manual') {
      this.selectionItems(
        this.rollingResistanceKey,
        'rolling_resistance_key',
        'rolling_resistance_coefficient',
        triggerUpdate
      );
    }

    if (this.tractionCoefficientKey !== 'manual') {
      this.selectionItems(
        this.tractionCoefficientKey,
        'traction_coefficient_key',
        'traction_coefficient',
        triggerUpdate
      );
    }
  }
}
