import { Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { UnitType } from '../../../api';
import { AWCTableColumns, AWCTableRow } from '@ansys/awc-angular/tables';
import { SolvedComponent } from '../../components/widgets/charts/blueprint-display/blueprint-display.component';
import { BuildComponentCellAction } from '../lib/build-component-cell.action';
import { ResultType } from '../../components/widgets/results-list/results-list.component';
import { ConceptUnitService } from '../../shared/services/unit.service';
import { PlotService } from '../../shared/services/plot.service';
import { solvedComponentHeadersMap } from '../../shared/maps/solved-component-headers.map';

type InputType = [any, number, string[], string[]];
type ReturnType = void;

/**
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class SolvedComponentTableAction
  implements AWFAction<InputType, ReturnType>
{
  constructor(
    private actions: ActionContributionsService,
    private unitChoices: ConceptUnitService,
    private plotService: PlotService
  ) {}

  async execute(context: InputType): Promise<ReturnType> {
    const [result, index, columnFilter, requirementColumns] = context;

    const headers = solvedComponentHeadersMap(this.unitChoices);

    const columns: AWCTableColumns[] = [
      { id: 'name', header: 'Component' },
    ].concat(
      columnFilter.map((filter) => ({
        id: filter,
        header: headers.get(filter) || filter,
      }))
    );

    let rows: AWCTableRow[] = [];
    if (!result) {
      return;
    }
    rows = await Promise.all(
      result.solved_components.map(
        async (component: SolvedComponent, componentIndex: number) => {
          const cells = await this.actions.execute(BuildComponentCellAction, [
            component,
            columnFilter,
            result.requirement_solved_type === ResultType.STATIC ? 0 : index,
          ]);

          return {
            id: componentIndex.toString(),
            cells,
          };
        }
      )
    );
    this.plotService.setTableData([
      columns,
      rows,
      requirementColumns,
      [index.toString()],
    ]);
  }
}
