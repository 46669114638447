import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  MotorLab,
  MotorLossMap,
  MotorTorqueCurves,
  UnitType,
} from '../../../../../../api';
import { InputTypes } from '../../../forms/input/input.component';
import { LabelPosition, SwitchSize } from '@ansys/awc-angular/forms';
import { SetPart } from '../mass/mass.component';
import { ComponentWidth } from '@ansys/awc-angular/core';
import { Icons } from '@ansys/awc-angular/icons';
import { UploadFileResponseType } from '../../../../../shared/types/upload-file-response.type';

@Component({
  selector: 'app-motor-inputs',
  templateUrl: './motor.component.html',
  styleUrls: ['../inputs.scss'],
})
export class MotorComponent {
  @Input() part!: MotorLossMap | MotorLab | MotorTorqueCurves | any;
  @Input() update!: boolean;
  @Input() readonly!: boolean;
  @Input() public fileResponse?: UploadFileResponseType;
  @Input() public forceEnable!: boolean;
  @Output() setPart: EventEmitter<SetPart> = new EventEmitter<SetPart>();
  @Output() forceUpdate: EventEmitter<string> = new EventEmitter<string>();
  @Output() public partChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateInput: EventEmitter<string> =
    new EventEmitter<string>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected UnitType = UnitType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected InputTypes = InputTypes;
  protected size: SwitchSize = SwitchSize.MEDIUM;
  protected label: string = 'Inverter Losses Included';
  protected labelPosition: LabelPosition = LabelPosition.BEFORE;
  protected awcSizing: ComponentWidth = ComponentWidth.FILL;
  protected awcTooltip: string = 'Include Losses';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly Icons = Icons;

  ngOnChanges(changes: any): void {
    if (changes.fileResponse && this.fileResponse?.responseObject) {
      this.part = {
        ...this.part,
        ...this.fileResponse?.responseObject,
      };

      // Emit the file response to the parent component
      for (const [key, value] of Object.entries(
        this.fileResponse?.responseObject
      )) {
        this.setPart.emit([value, key, `${this.part.id}_${key}`]);
      }
    }
  }

  /**
   * Toggle include inverter losses
   */
  protected toggleIncludeLossMaps($event: boolean): void {
    this.part.inverter_losses_included = $event;
    this.forceUpdate.emit('');
  }
}
