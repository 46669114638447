import { Injectable } from '@angular/core';
import { AWFAction } from '@ansys/andromeda/contributions';
import { UnitType } from '../../../api';
import { InverterLossesType } from '../../shared/enums/inverter-losses-type';
import { ConceptUnitService } from '../../shared/services/unit.service';

type InputType = any;
type ReturnType = string;

/**
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class ComposeInverterLossesAction
  implements AWFAction<InputType, ReturnType>
{
  readonly reportAnalysis = false;

  private readonly lossTypeHeaders: Record<string, string> = {
    currents: `Phase Current (${this.unitService.getChoice(UnitType.CURRENT)})`,
    [InverterLossesType.TOTAL]: `Total Losses (${this.unitService.getChoice(
      UnitType.POWER
    )})`,
    [InverterLossesType.SWITCHING]: `Switching Losses (${this.unitService.getChoice(
      UnitType.POWER
    )})`,
    [InverterLossesType.CONDUCTION]: `Conduction Losses (${this.unitService.getChoice(
      UnitType.POWER
    )})`,
    [InverterLossesType.AC_HARNESS]: `AC Harness Losses (${this.unitService.getChoice(
      UnitType.POWER
    )})`,
    [InverterLossesType.DC_HARNESS]: `DC Harness Losses (${this.unitService.getChoice(
      UnitType.POWER
    )})`,
  };

  constructor(private unitService: ConceptUnitService) {}

  async execute(context: InputType): Promise<ReturnType> {
    const [lossData] = context;

    // Convert loss types to human-readable headers
    const headers = Object.keys(lossData)
      .map((lossType) => this.lossTypeHeaders[lossType] || lossType) // Fallback to original key if no mapping exists
      .join(',');

    // Get the maximum length of values array for any loss type
    const maxLength = Math.max(
      ...Object.values(lossData).map((values) => (values as number[]).length)
    );

    // Create rows where each row corresponds to values for all loss types
    const rows = Array.from({ length: maxLength }, (_, i) => {
      return Object.keys(lossData)
        .map((lossType: string) => lossData[lossType][i] ?? '')
        .join(',');
    });

    return headers + '\n' + rows.join('\n');
  }
}
