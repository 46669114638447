/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { Aero } from '../models/Aero';
import type { AncillaryLoad } from '../models/AncillaryLoad';
import type { BatteryFixedVoltages } from '../models/BatteryFixedVoltages';
import type { BatteryLookupTable } from '../models/BatteryLookupTable';
import type { BatteryLookupTableID } from '../models/BatteryLookupTableID';
import type { DecelerationLimit } from '../models/DecelerationLimit';
import type { DisconnectClutchInput } from '../models/DisconnectClutchInput';
import type { InverterAnalytical } from '../models/InverterAnalytical';
import type { Mass } from '../models/Mass';
import type { MotorCTCP } from '../models/MotorCTCP';
import type { MotorLab } from '../models/MotorLab';
import type { MotorLabID } from '../models/MotorLabID';
import type { MotorLossMap } from '../models/MotorLossMap';
import type { MotorLossMapID } from '../models/MotorLossMapID';
import type { MotorTorqueCurves } from '../models/MotorTorqueCurves';
import type { MotorTorqueCurvesID } from '../models/MotorTorqueCurvesID';
import type { TransmissionLossCoefficients } from '../models/TransmissionLossCoefficients';
import type { TransmissionLossMap } from '../models/TransmissionLossMap';
import type { TransmissionLossMapID } from '../models/TransmissionLossMapID';
import type { TransmissionNeglect } from '../models/TransmissionNeglect';
import type { WheelInput } from '../models/WheelInput';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class LibraryService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Add To Library
     * Upload a config or component to the library from the db.
     * @param itemId
     * @param accountId
     * @param productId
     * @param description
     * @param designInstanceId
     * @param name
     * @returns any[] Successful Response
     * @throws ApiError
     */
    public addToLibraryLibraryFromIdPost(
        itemId: string,
        accountId: string,
        productId: string,
        description: string,
        designInstanceId: string,
        name?: (string | null),
    ): Observable<any[]> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/library:from_id',
            query: {
                'item_id': itemId,
                'account_id': accountId,
                'product_id': productId,
                'description': description,
                'name': name,
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add To Library Direct
     * Upload a config or component directly to the library.
     * @param accountId
     * @param productId
     * @param description
     * @param designInstanceId
     * @param requestBody
     * @returns any[] Successful Response
     * @throws ApiError
     */
    public addToLibraryDirectLibraryDirectUploadPost(
        accountId: string,
        productId: string,
        description: string,
        designInstanceId: string,
        requestBody: ((BatteryFixedVoltages | BatteryLookupTable | MotorCTCP | MotorTorqueCurves | MotorLossMap | MotorLab | TransmissionLossCoefficients | TransmissionLossMap | TransmissionNeglect | InverterAnalytical | DisconnectClutchInput | MotorLossMapID | MotorLabID | MotorTorqueCurvesID | BatteryLookupTableID | TransmissionLossMapID) | (Aero | Mass | WheelInput | DecelerationLimit | AncillaryLoad)),
    ): Observable<any[]> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/library:direct_upload',
            query: {
                'account_id': accountId,
                'product_id': productId,
                'description': description,
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get From Library
     * Download item from library and convert to user units.
     *
     * Return as a dictionary with the id removed. Note that the object id and blob id are
     * identical so can just download directly from the blob API.
     * @param objectId
     * @param designInstanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getFromLibraryLibraryObjectIdGet(
        objectId: string,
        designInstanceId: string,
    ): Observable<Record<string, any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/library/{object_id}',
            path: {
                'object_id': objectId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
}
