<app-input
  label="Cross Sectional Area"
  placeholder="Cross Sectional Area..."
  [value]="part.cross_sectional_area || '0'"
  [type]="InputTypes.NUMBER"
  [unit]="UnitType.AREA"
  [id]="part.id+'_cross_sectional_area'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit(['' + $event, 'cross_sectional_area', part.id+'_cross_sectional_area'])"></app-input>
<app-input
  label="Drag Coefficient (Forwards)"
  placeholder="Drag Coefficient (Forwards)..."
  [value]="part.drag_coefficient_front"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_drag_coefficient_front'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit(['' + $event, 'drag_coefficient_front', part.id+'_drag_coefficient_front'])"></app-input>
<app-input
  label="Drag Coefficient (Reverse)"
  placeholder="Optional..."
  [value]="part.drag_coefficient_rear"
  [type]="InputTypes.NUMBER"
  [id]="part.id+'_drag_coefficient_rear'"
  [updater]="update"
  [readonly]="readonly"
  (update)="setPart.emit(['' + $event, 'drag_coefficient_rear', part.id+'_drag_coefficient_rear'])"></app-input>
