<div class="developer-container">
  <div class="description">Enable and Disable feature flags</div>
  <div class="page">
    <div class="container">
      <awc-card [elevation]="elevation" [borderRadius]="borderRadius" [awcSizing]="sizing">
        <div class="card-content">
          <div class="card-title">
            <span>Features</span>
            <span class="desc">Full features within the application, end to end workflows and similar.</span>
          </div>

          <div *ngFor="let feature of features" class="row">
<!--            <awc-checkbox-->
<!--              [checked]="featureFlagsService.hasBorder(feature)"-->
<!--              (checkedChange)="borderToggled(feature, !!$event)"-->
<!--            ></awc-checkbox>-->
            <awc-label
              [size]="size"
              [text]="feature.replaceAll('-', ' ').replaceAll('feature', '') | titlecase"
            ></awc-label>
            <awc-switch
              [checked]="featureFlagsService.isFlagged(feature)"
              [disabled]="!featureFlagsEnabled"
              (checkedChange)="flagToggled(feature, $event)"
            ></awc-switch>
          </div>
        </div>
      </awc-card>

      <awc-card [elevation]="elevation" [borderRadius]="borderRadius" [awcSizing]="sizing">
        <div class="card-content">
          <div class="card-title">
            <span>Interactions</span>
            <span class="desc">Individual interaction points within the application</span>
          </div>

          <div *ngFor="let subview of subviews" class="row">
<!--            <awc-checkbox-->
<!--              [checked]="featureFlagsService.hasBorder(subview)"-->
<!--              (checkedChange)="borderToggled(subview, !!$event)"-->
<!--            ></awc-checkbox>-->
            <awc-label
              [size]="size"
              [text]="subview.replaceAll('-', ' ').replaceAll('interaction', '') | titlecase"
            ></awc-label>
            <awc-switch
              [checked]="featureFlagsService.isFlagged(subview)"
              [disabled]="!featureFlagsEnabled"
              (checkedChange)="flagToggled(subview, $event)"
            ></awc-switch>
          </div>
        </div>
      </awc-card>

      <awc-card [elevation]="elevation" [borderRadius]="borderRadius" [awcSizing]="sizing">
        <div class="card-content">
          <div class="card-title">
            <span>Views</span>
            <span class="desc"
            >UI elements that consist whole views, partial views or a collection of multiple interactions</span
            >
          </div>

          <div *ngFor="let view of views" class="row">
<!--            <awc-checkbox-->
<!--              [checked]="featureFlagsService.hasBorder(view)"-->
<!--              (checkedChange)="borderToggled(view, !!$event)"-->
<!--            ></awc-checkbox>-->
            <awc-label [size]="size" [text]="view.replaceAll('-', ' ').replaceAll('view', '') | titlecase"></awc-label>
            <awc-switch
              [checked]="featureFlagsService.isFlagged(view)"
              [disabled]="!featureFlagsEnabled"
              (checkedChange)="flagToggled(view, $event)"
            ></awc-switch>
          </div>
        </div>
      </awc-card>
    </div>
  </div>
  <div>
    <awc-button
      [text]="'Reset to Default'"
      (clicked)="onResetToDefault()"
    ></awc-button>
  </div>
</div>
