<ng-container *ngIf="!loading else loadingTemplate">
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="steps.START">
      <div class="button-cont">
        <awc-button
          text="Add New"
          [suffixIcon]="suffixIcon"
          [type]="type"
          [size]="size"
          (clicked)="stepWizard()"></awc-button>
        <awc-button
          text="Import from Library"
          [suffixIcon]="libraryIcon"
          [type]="type"
          [size]="size"
          [awcTooltip]="'Coming Soon!'"
          [disabled]="true"
          (clicked)="clickedLibrary()"></awc-button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="steps.MIDDLE">
      <awc-select
        [label]="label"
        [placeholder]="placeholder"
        [items]="items"
        [iconButton]="iconButton"
        [disabled]="disableDropdown"
        [layout]="layout"
        [selectedItems]="selectedItems"
        (iconButtonAction)="iconButtonAction()"
        (selectionChanged)="selectionChanged($event)"></awc-select>
    </ng-container>
    <ng-container *ngSwitchCase="steps.END">
      <app-part-inputs [(part)]="defaultPart" [type]="data.parent.id" (partsLoaded)="partsLoaded = $event" [fileResponse]="uploadedFilePart"></app-part-inputs>
      <awc-button
        class="part-edit-inputs__confirm"
        [text]="uploadingFilePart ? 'Uploading File...' : 'Confirm Add'"
        [suffixIcon]="uploadingFilePart ? spinnerIconType : (uploadedFile) ? checkIconType : suffixIcon"
        [type]="type"
        [size]="size"
        [disabled]="uploadingFilePart || !partsLoaded"
        (clicked)="confirm()"></awc-button>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loadingTemplate>
  <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
    <awc-icon [icon]="spinner"></awc-icon>
  </div>
</ng-template>
<input #fileUpload type="file" (click)="uploadClick()" (change)="importFile($event)" style="display:none" [accept]="fileTypeAcceptance">
