import { Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { UnitType } from '../../../api';
import { ConceptUnitService } from '../../shared/services/unit.service';
import { ConvertUnitValuesAction } from '../convert-unit-values/convert-unit-values.action';
import { RequirementPlotType } from '../../shared/enums/reqirement-plot-type.enum';
import { RequirementsService } from '../../shared/services/requirements.service';

// TODO: Don't use any[] in InputType or ReturnType
type InputType = any[];
type ReturnType = [any, number, number, number];

/**
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class ProcessStaticResultDataAction
  implements AWFAction<InputType, ReturnType>
{
  // This can be used to track end user operations and can be used to analyze the user behavior.
  readonly reportAnalysis = false;

  constructor(
    private actions: ActionContributionsService,
    private requirementService: RequirementsService,
    private unitChoices: ConceptUnitService
  ) {}

  async execute(results: InputType): Promise<ReturnType> {
    const data: any[] = [];
    let maxSpeed = 0;
    let maxTorque = 0;
    let maxPower = 0;
    let minSpeed = Infinity;
    let minTorque = Infinity;
    let minPower = Infinity;
    let convertedSpeeds: number[] = [];
    let convertedValues: number[] = [];

    for (const req of results) {
      const color: string = 'grey';
      const lineColor: string = 'grey';

      [convertedSpeeds, minSpeed, maxSpeed] = await this.actions.execute(
        ConvertUnitValuesAction,
        [req.capability_curve.speeds, UnitType.SPEED, minSpeed, maxSpeed]
      );

      const convertedReqSpeeds = this.unitChoices.convertUnit(
        UnitType.SPEED,
        req.requirement.speed
      );
      maxSpeed = Math.max(maxSpeed, convertedReqSpeeds * 1.05);

      // Convert units based on the requirementYAxis value
      let convertedRequirement: number = 0;
      let convertedRequirementPower: number = 0;
      let convertedRequirementTorque: number = 0;

      switch (this.requirementService.requirementYAxis()) {
        case 'power':
          [convertedValues, minPower, maxPower] = await this.actions.execute(
            ConvertUnitValuesAction,
            [req.capability_curve.powers, UnitType.POWER, minPower, maxPower]
          );

          convertedRequirementPower = convertedRequirement =
            this.unitChoices.convertUnit(
              UnitType.POWER,
              req.requirement.total_tractive_power
            );
          break;

        case 'torque':
          [convertedValues, minTorque, maxTorque] = await this.actions.execute(
            ConvertUnitValuesAction,
            [
              req.capability_curve.torques,
              UnitType.TORQUE,
              minTorque,
              maxTorque,
            ]
          );

          convertedRequirementTorque = convertedRequirement =
            this.unitChoices.convertUnit(
              UnitType.TORQUE,
              req.requirement.total_tractive_torque
            );
          break;

        default:
          throw new Error('Unknown requirementYAxis value');
      }

      maxTorque = Math.max(maxTorque, convertedRequirementTorque * 1.05);
      maxPower = Math.max(maxPower, convertedRequirementPower * 1.05);
      data.push(
        {
          type: 'scatter',
          x: convertedSpeeds,
          y: convertedValues,
          line: { color: lineColor },
          name: `${req.requirement.name} Capability`,
          _type: RequirementPlotType.CAPABILITY_CURVE,
          id: req.id,
        },
        {
          type: 'scatter',
          marker: { size: 20, color: color },
          x: [convertedReqSpeeds] as never[],
          y: [convertedRequirement] as never[],
          name: `${req.requirement.name}`,
          _type: RequirementPlotType.REQUIREMENT,
          id: req.id,
        }
      );
    }

    return [data, maxSpeed, maxTorque, maxPower];
  }
}
