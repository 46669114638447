/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComponentAxle } from './ComponentAxle';
import type { ComponentSide } from './ComponentSide';
/**
 * Solved wheel node.
 */
export type SolvedWheel = {
    name: string;
    axle?: ComponentAxle;
    side?: ComponentSide;
    mass?: number;
    cost?: number;
    in_powers: Array<number>;
    out_powers: Array<number>;
    losses: Array<number>;
    losses_ratio: Array<number>;
    solved_component_type?: SolvedWheel.solved_component_type;
    speeds: Array<number>;
    in_torques: Array<number>;
    out_torques: Array<number>;
};
export namespace SolvedWheel {
    export enum solved_component_type {
        WHEEL = 'wheel',
    }
}

