import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '@ansys/andromeda/shared';
import { type DriveCycleSolved } from '../../../../api';
import {
  AWCTableCellText,
  AWCTableColumns,
  AWCTableRow,
  TableCellAlign,
  TableCellTypes,
  TableComponent,
} from '@ansys/awc-angular/tables';

@Component({
  selector: 'app-drive-cycle-warning-dialog',
  standalone: true,
  imports: [TableComponent],
  templateUrl: './drive-cycle-warning-dialog.component.html',
  styleUrls: ['./drive-cycle-warning-dialog.component.scss'],
})
export class DriveCycleWarningDialogComponent implements OnInit {
  protected height: number = 400; // TODO: Why does this have to hardcoded?
  protected columns: AWCTableColumns[] = [
    { id: 'point', header: 'Point', align: TableCellAlign.LEFT, width: 200 },
    { id: 'warning', header: 'Warning', align: TableCellAlign.LEFT },
  ];
  protected rows: AWCTableRow[] = [];

  constructor(
    @Inject(DIALOG_DATA)
    private data: { result: DriveCycleSolved }
  ) {}

  ngOnInit(): void {
    const warnings = this.data.result.warnings;

    this.rows = Object.entries(warnings).map(([point, warning], index) => {
      return {
        id: index.toString(),
        cells: [
          {
            value: point,
            type: TableCellTypes.TEXT,
          } as AWCTableCellText,
          {
            value: warning,
            type: TableCellTypes.TEXT,
          } as AWCTableCellText,
        ],
      };
    });
  }
}
