/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Surface conditions that affect the traction coefficient.
 */
export enum SurfaceConditionTractionConfigs {
    SNOW = 'Snow',
    WET = 'Wet',
    DRY = 'Dry',
}
