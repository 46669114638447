/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Disconnect clutch input.
 */
export type DisconnectClutchInput = {
    item_type?: DisconnectClutchInput.item_type;
    name?: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: DisconnectClutchInput.component_type;
    efficiency?: number;
    switch_energy?: number;
    engaged_power?: number;
};
export namespace DisconnectClutchInput {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        CLUTCH_INPUT = 'ClutchInput',
    }
}

