import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SessionModule } from '@ansys/andromeda/auth';
import { PageComponent } from '@ansys/andromeda/shared';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
// import { provideActions } from '@ansys/andromeda/providers';
// import { DashboardModule } from '@ansys/andromeda/dashboard';
import { PagesModule } from './pages/pages.module';
import { TokensModule } from './tokens/tokens.module';
import { ActionsModule } from './actions/actions.module';
import { GraphQLModule } from '@ansys/portal-bff-angular-client';
import { AWFConfig } from '@ansys/awf-js/core';
import { ConceptTab } from './tabs/concept-tab/concept-tab';
import { WorkspacePage } from './pages/workspace/workspace.page';
import { DashboardMainTab } from '@ansys/andromeda/dashboard';

@NgModule({
  declarations: [],
  exports: [PageComponent],
  imports: [
    // DashboardModule,
    CommonModule,
    BrowserAnimationsModule,
    GraphQLModule,
    PageComponent,
    SessionModule,
    PagesModule,
    TokensModule,
    ActionsModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AndromedaModule {
  constructor() {}
}

@AWFConfig({
  providers: [],
  imports: [WorkspacePage, ConceptTab, DashboardMainTab],
})
export class AndromedaConfig {}
