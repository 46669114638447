import { TableColumn } from '@ansys/andromeda/contributions';
import { TableCellAlign, TableCellTypes } from '@ansys/awc-angular/tables';

export class DriveCycleMenuColumn implements TableColumn {
  align: TableCellAlign = TableCellAlign.RIGHT;
  id: string = 'drive-cycle-menu';
  type: TableCellTypes = TableCellTypes.MENU_BAR;
  menuId: string = 'drive-cycle-menu';
  width: number = 80;
  priority: number = 500;
}
