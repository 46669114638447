import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { ConceptPartType } from './concept-part.type';
import { ComponentFileType } from '../../../api';
import { UploadFileResponseType } from './upload-file-response.type';
import { Observable } from 'rxjs';

export interface PartServiceType {
  addPart: (
    item: ConceptPartType,
    file?: File,
    type?: ComponentFileType | string,
    uploadedFile?: UploadFileResponseType
  ) => Promise<unknown>;
  uploadFilePart?: (file: File, type?: ComponentFileType) => Promise<unknown>;
  deletePart: (item: AWCTreeItem) => Promise<unknown>;
  updatePart: (item: AWCTreeItem) => Promise<unknown>;
  displayData: () => void;
  shouldUpdateSection?: (part: ConceptPartType, parentId?: string) => boolean;
  updatedItem$?: Observable<AWCTreeItem | null>;
}
