/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { FlexLayout } from '@ansys/awc-angular/core';
import { IconComponent, Icons } from '@ansys/awc-angular/icons';
import { Subscription } from 'rxjs';
import { UnitType } from 'src/api';
import { UpdateInputs } from '../../../../actions';
import { ScientificNotationPipe } from 'src/app/shared/pipes/scientific-notation/scientific-notation.pipe';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';
import { EventBus } from 'src/app/shared/tools/event-bus';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@ansys/andromeda/shared';

export enum InputTypes {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  PASSWORD = 'password',
  TEL = 'tel',
  URL = 'url',
  SEARCH = 'search',
  DATE = 'date',
  TIME = 'time',
  RATIO = 'ratio',
  SELECT = 'select',
}
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ScientificNotationPipe],
})
export class InputComponent implements OnChanges, OnInit {
  @Input() label!: string;
  @Input() altLabel!: string;
  @Input() placeholder!: string;
  @Input() type: InputTypes = InputTypes.TEXT;
  @Input() value!: string | number | undefined | null;
  @Input() altValue!: string | number | undefined | null;
  @Input() id!: string;
  @Input() unit!: UnitType;
  @Input() updater: boolean = false;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() disableRounding: boolean = false;
  @Input() layout: FlexLayout = FlexLayout.COLUMN;
  @Input() active: boolean = false;
  @Input() loading: boolean = false;
  @Output() update: EventEmitter<string | number> = new EventEmitter<
    string | number
  >();
  @Output() setLabelWidth: EventEmitter<number> = new EventEmitter<number>();
  // viewchild label element width
  @ViewChild('labelEl') labelElement!: ElementRef;
  readonly InputTypes = InputTypes;
  protected spinner: Icons = Icons.SPINNER;
  protected warning: Icons = Icons.WARNING;
  protected chevron: Icons = Icons.DOWN_ARROW;
  protected errState: boolean = false;
  protected unitStr!: string;
  protected ratioValue!: number;
  private bus: EventBus = EventBus.getInstance();
  private initialValue: string | number = '';
  private updateInput!: Subscription;
  constructor(
    private _cdr: ChangeDetectorRef,
    private snPipe: ScientificNotationPipe,
    private unitService: ConceptUnitService,
    private actions: ActionContributionsService
  ) {
    if (this.disabled) {
      this.readonly = true;
    }

    this.bus.register(
      'input:update:complete',
      (update: [id: string, err: boolean]) => {
        const [id, err] = update;
        if (id && id === this.id) {
          this.errState = err;
        }
        this.loading = false;
        this._cdr.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.updateInput?.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.transformUnitValue();
      if (this.type === InputTypes.RATIO) {
        this.setRatioValue(this.value as number);
      }
      this._cdr.detectChanges();
    }
  }
  ngOnInit(): void {
    this.updateInput = this.actions.get(UpdateInputs).subscribe(() => {
      this.transformUnitValue();
      this._cdr.detectChanges();
    });
    this.transformUnitValue();
  }
  ngAfterViewInit(): void {
    if (this.labelElement) {
      this.setLabelWidth.emit(this.labelElement.nativeElement.offsetWidth);
    }
  }
  protected checkInput($event: Event): void {
    const value = ($event.target as HTMLInputElement).value;
    if (this.type === InputTypes.RATIO) {
      this.setRatioValue(1 * Number(value));
    }
  }
  protected stopProp($event: Event): void {
    $event.stopPropagation();
  }
  protected updateValue($event: Event | FocusEvent): void {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    const value = ($event.target as HTMLInputElement).value;
    if (value === this.initialValue) return;
    if (this.updater) {
      this.loading = true;
      this._cdr.detectChanges();
    }
    // reset initial value? or just check if it's different?
    this.initialValue = value;
    this.update.emit(value);
  }
  protected setInitial($event: Event): void {
    this.initialValue = ($event.target as HTMLInputElement).value;
  }
  private transformUnitValue(): void {
    if (this.unit) {
      this.unitStr = this.unitService.getChoice(this.unit) || '';
    }
    if (this.type === InputTypes.NUMBER && !this.disableRounding) {
      this.value = this.snPipe.transform(this.value);
    }
  }
  private setRatioValue(value: number): void {
    const max = this.unitService.getChoice(this.unit) === '%' ? 100 : 1;
    const transformedValue =
      this.snPipe.transform(max - ((value as number) ?? 0)) ?? 0;
    this.ratioValue = +transformedValue;
  }
}
