/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MotorLabData } from './MotorLabData';
/**
 * Create Motor From Lab Model.
 */
export type MotorLab = {
    item_type?: MotorLab.item_type;
    name?: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: MotorLab.component_type;
    lab_data: MotorLabData;
    max_speed: number;
    stator_winding_temp?: (number | null);
    rotor_temp?: (number | null);
    stator_current_limit?: (number | null);
    inverter_losses_included?: boolean;
};
export namespace MotorLab {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        MOTOR_LAB_MODEL = 'MotorLabModel',
    }
}

