/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Inject,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  RequirementsService as API,
  Concept,
  ConceptPopulated,
  DriveCycleInDB,
  DriveCycleRequirementIds,
  DriveCyclesService,
  DynamicRequirementInputsIds,
  JobsService,
  Requirement,
  StaticRequirementAccelerationIds,
  UnitType,
} from 'src/api';
import { PartServiceType } from '../types';
import { SelectedAccountState, State } from '@ansys/andromeda/store';
import { PlotService } from './plot.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import {
  AWFLocation,
  AWF_LOCATION,
  SnackBarService,
} from '@ansys/andromeda/shared';
import { AccountService } from '@ansys/andromeda/dashboard';
import { JobApiService } from '@ansys/cloud-angular-client';
import { ResultType } from 'src/app/components/widgets/results-list/results-list.component';
import { DataDisplayService, DataDisplayState } from './data-display.service';
import { ConceptUnitService } from './unit.service';
import { ConceptPartType } from '../types';
import { AnalyticEventEnum } from '../enums';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { JobMetaData } from '../../actions';
import { ActiveConceptState } from 'src/app/state/lib/active-concept.state';
import { RequirementItemState } from 'src/app/state/lib/requirements.state';
import { DriveCycleAxisState } from 'src/app/state/lib/drive-cycle-axis.state';
import { NotificationService } from './notification.service';
import {
  AnalyticsEventPayload,
  SubmitAnalyticsEventAction,
} from '@ansys/andromeda/analytics';
import { ProcessStaticResultDataAction } from '../../actions/process-static-result-data/process-static-result-data.action';
import { JobLoadingStatus } from '../enums/job-loading-status.enum';
import { ProcessDynamicResultDataAction } from '../../actions/process-dynamic-result-data/process-dynamic-result-data.action';
import { ProcessDriveCycleResultDataAction } from '../../actions/process-drive-cycle-result-data/process-drive-cycle-result-data.action';
import { AddDriveCycleAxisAction } from '../../actions/add-drive-cycle-axis/add-drive-cycle-axis.action';
import { SelectResultAction } from '../../actions/select-result/select-result.action';
import { SolvedComponentTableAction } from '../../actions/solved-component-table/solved-component-table.action';
import { driveCycleColumnsModel } from '../models/plot-data/drive-cycle-columns.model';
import { staticColumnsModel } from '../models/plot-data/static-columns.model';
import { PlotState } from '../../state/lib/plot.state';
import { Layout } from 'plotly.js';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { CapabilityCurveTypeEnum } from '../enums/capability-curve-type.enum';
import { ProcessResultDataAction } from '../../actions/process-result-data/process-result-data.action';

@Injectable({ providedIn: 'root' })
export class RequirementsService implements PartServiceType {
  public get columns(): string[] {
    return this.requirementColumns;
  }

  private $jobStatus = new BehaviorSubject<string>('');
  public jobStatus = this.$jobStatus.asObservable();
  public selectFromPlot: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  public selectedTimeIndex: BehaviorSubject<number | string> =
    new BehaviorSubject<number | string>(0);
  public jobResultLoading = new BehaviorSubject<JobLoadingStatus>(
    JobLoadingStatus.IDLE
  );

  public get data(): any[] {
    return this.mergedData;
  }
  private updatedItemSubject = new BehaviorSubject<AWCTreeItem | null>(null);
  updatedItem$ = this.updatedItemSubject.asObservable();

  private $selectedResult = new BehaviorSubject<any>(null);
  public selectedResult = this.$selectedResult.asObservable();
  protected staticDynamicDefaultLayout!: Partial<Layout>;
  protected dynamicResultData: any[] = [];
  protected staticResultData: any[] = [];
  protected mergedDriveCycles: any[] = [];
  protected driveCycleLayout: any[] = [];
  private requirementColumns: string[] = [];
  private conceptSignal = this.state.signal(ActiveConceptState);
  public mergedData: any[] = [];
  requirementYAxisSignal: WritableSignal<string> = signal(
    this.getRequirementYAxis()[0].id
  );
  get requirementYAxis(): WritableSignal<string> {
    return this.requirementYAxisSignal;
  }

  constructor(
    private api: API,
    private state: State,
    private plotService: PlotService,
    private jobService: JobsService,
    private snackbar: SnackBarService,
    private driveCycleService: DriveCyclesService,
    private accountService: AccountService,
    private dataDisplay: DataDisplayService,
    private unitChoices: ConceptUnitService,
    private jobApi: JobApiService,
    private actions: ActionContributionsService,
    private notifications: NotificationService,
    @Inject(AWF_LOCATION) private location: AWFLocation
  ) {}

  public async get(id: string, instanceId: string): Promise<Requirement> {
    return await lastValueFrom(
      this.api.readRequirementsItemIdGet(id, instanceId)
    );
  }

  public async addPart(item: ConceptPartType): Promise<any> {
    type requirementType =
      | DriveCycleRequirementIds
      | DynamicRequirementInputsIds
      | StaticRequirementAccelerationIds;
    const instanceID = (this.conceptSignal() as ConceptPopulated)
      .design_instance_id as string;
    const part = await lastValueFrom(
      this.api.createRequirementsPost(instanceID, {
        ...(item as requirementType),
      })
    ).catch((err) => {
      this.notifications.error('Error Creating Requirement', err);
      throw new Error(err);
    });

    const type = (item as any).requirement_type;
    if (type) {
      const eventData: AnalyticsEventPayload = {
        type: AnalyticEventEnum.REQUIREMENT_ADDED,
        data: { component_type: type },
      };
      this.actions.execute(SubmitAnalyticsEventAction, eventData);
    }

    return part;
  }

  public async addDriveCycleFromFile(
    name: string = 'Drive Cycle',
    file: File
  ): Promise<DriveCycleInDB> {
    const instanceID = (this.conceptSignal() as ConceptPopulated)
      .design_instance_id as string;
    return await lastValueFrom(
      this.driveCycleService.createFromFileDriveCyclesFromFilePost(
        name,
        instanceID,
        {
          file,
        }
      )
    ).catch((err) => {
      this.notifications.error('Error Creating Drive Cycle', err);
      throw new Error(err);
    });
  }

  public resetDefaultLayoutRange(): void {
    this.plotService.updateGraphLayout({
      xaxis: { range: [0, 0] },
      yaxis: { range: [0, 0] },
    });
  }

  public async deletePart(item: AWCTreeItem): Promise<unknown> {
    const id = item.userData ? (item.userData['id'] as string) : null;
    if (!id) {
      throw new Error('No ID provided');
    }

    const instanceID = (this.conceptSignal() as ConceptPopulated)
      .design_instance_id as string;
    const resp = await lastValueFrom(
      this.api.deleteRequirementsItemIdDelete(id, instanceID)
    ).catch((err) => {
      this.notifications.error('Error Deleting Requirement', err);
      throw new Error(err);
    });
    const currentState = this.state.value(
      RequirementItemState,
      this.location.tab?.id
    );
    const index = currentState.findIndex(
      (stateItem) => stateItem.id === item.id
    );
    currentState.splice(index, 1);
    this.state.set(RequirementItemState, currentState, this.location.tab?.id);

    if (item.userData?.['requirement_type']) {
      const eventData: AnalyticsEventPayload = {
        type: AnalyticEventEnum.REQUIREMENT_DELETED,
        data: { component_type: item.userData?.['requirement_type'] },
      };
      this.actions.execute(SubmitAnalyticsEventAction, eventData);
    }

    this.snackbar.success('Requirement Deleted!');
    return resp;
  }

  public async updatePart(item: AWCTreeItem): Promise<unknown> {
    const id = item.userData ? (item.userData['id'] as string) : null;
    if (!id) {
      throw new Error('No ID provided');
    }

    const instanceID = (this.conceptSignal() as ConceptPopulated)
      .design_instance_id as string;
    const resp = await lastValueFrom(
      this.api.updateRequirementsItemIdPut(
        id,
        instanceID,
        item.userData as
          | StaticRequirementAccelerationIds
          | DynamicRequirementInputsIds
      )
    ).catch((err) => {
      this.snackbar.error('Error Updating Requirement!');
      throw new Error(err);
    });
    item.text =
      (resp as DynamicRequirementInputsIds | StaticRequirementAccelerationIds)
        ?.name || 'Drive Cycle';
    item.userData = {
      ...item.userData,
      ...resp,
    };
    if (item.parent) {
      (item.parent as any).dirty = true;
    }

    const currentState = this.state.value(
      RequirementItemState,
      this.location.tab?.id
    );
    const index = currentState.findIndex(
      (stateItem) => stateItem.id === item.id
    );
    currentState[index] = item;
    this.state.set(RequirementItemState, currentState, this.location.tab?.id);

    if (item.userData?.['requirement_type']) {
      const eventData: AnalyticsEventPayload = {
        type: AnalyticEventEnum.REQUIREMENT_UPDATED,
        data: { component_type: item.userData?.['requirement_type'] },
      };
      this.actions.execute(SubmitAnalyticsEventAction, eventData);
    }

    this.updatedItemSubject.next(item);

    this.snackbar.success('Requirement Updated!');
    return resp;
  }

  public setJobStatus(status: string): void {
    this.$jobStatus.next(status);
  }

  public async solveRequirements(
    name: string,
    reqs: string[],
    extraMemory: boolean = false
  ): Promise<void> {
    reqs.length && (await this.fireJob(reqs, name, extraMemory));
  }

  protected async fireJob(
    requirements: string[],
    name: string,
    extraMemory: boolean = false
  ): Promise<void> {
    const concept = this.state.value(ActiveConceptState);
    const instanceID = this.conceptSignal()?.design_instance_id as string;
    const [job, uploaded_file] = await lastValueFrom(
      this.jobService.createRequirementJobJobsPost(
        instanceID,
        this.state.value(SelectedAccountState),
        {
          job_name: name,
          requirement_ids: requirements,
          architecture_id: (concept as Concept)?.architecture_id as string,
          design_instance_id: (concept as Concept)
            ?.design_instance_id as string,
        }
      )
    ).catch((e) => {
      this.snackbar.error('Cannot create job');
      throw new Error(e);
    });

    const hpc = await this.accountService.getDefaultHPCAccount();

    await lastValueFrom(
      this.jobService.startJobJobsStartPost(
        instanceID,
        this.state.value(SelectedAccountState),
        {
          job,
          uploaded_file,
          account_id: this.state.value(SelectedAccountState),
          hpc_id: hpc.hpcId as string,
          extra_memory: extraMemory,
        }
      )
    ).catch((e) => {
      this.snackbar.error('Cannot start job');
      throw new Error(e);
    });

    await lastValueFrom(
      this.jobApi.updateJobNameHandler({ jobId: job.id, jobName: name })
    ).catch((e) => {
      console.error(e);
    });

    this.actions.execute(JobMetaData, [job.id, name]);
  }

  public async processDynamicResultData(results: any[]): Promise<void> {
    const [data, maxSpeed, maxTorque, maxPower, maxAcceleration] =
      // await this.actions.execute(ProcessDynamicResultDataAction, results);
      await this.actions.execute(ProcessResultDataAction, [results, true]);

    this.setStaticDynamicDefaultLayout(
      maxSpeed,
      maxTorque,
      maxPower,
      maxAcceleration
    );
    this.dynamicResultData = data;
    this.mergedData = [...this.dynamicResultData, ...this.staticResultData];
  }

  public resetMergedData(): void {
    this.mergedData = [];
    this.staticResultData = [];
    this.dynamicResultData = [];
  }

  public setSelectedResult(result: any): void {
    this.$selectedResult.next(result);
  }

  public async selectResult(
    result: any,
    driveCycle: boolean,
    selectedJob: string,
    getSelectedJob: () => string
  ): Promise<void> {
    this.state.reset(PlotState);
    this.plotService.setGraphData([]);
    const plotData = await this.actions.execute(SelectResultAction, [
      result,
      driveCycle,
    ]);

    // also set the table data here from the solved components
    if (selectedJob.length && selectedJob === getSelectedJob()) {
      this.resetRequirementColumns(
        result.requirement_solved_type === ResultType.DRIVE_CYCLE
      );
      this.solvedComponentTable(result);
      this.plotService.setGraphData(plotData as any);

      this.setSelectedResult(result);

      if (driveCycle) {
        this.plotService.updateGraphLayout(
          this.filterDriveCycleLayout(result.id)
        );
        this.actions.execute(AddDriveCycleAxisAction, [
          result,
          this.state.value(DriveCycleAxisState, result.id) || [],
        ]);
      } else {
        this.plotService.updateGraphLayout(this.staticDynamicDefaultLayout);
      }
    }
  }

  private resetRequirementColumns(driveCycleResult?: boolean): void {
    this.requirementColumns = driveCycleResult
      ? driveCycleColumnsModel
      : staticColumnsModel;
  }

  public async processStaticResultData(results: any[]): Promise<void> {
    const [data, maxSpeed, maxTorque, maxPower, maxAcceleration] =
      // await this.actions.execute(ProcessStaticResultDataAction, results);
      await this.actions.execute(ProcessResultDataAction, [results]);

    this.setStaticDynamicDefaultLayout(
      maxSpeed,
      maxTorque,
      maxPower,
      maxAcceleration
    );

    this.staticResultData = data;
    this.mergedData = [...this.staticResultData, ...this.dynamicResultData];
  }

  private setStaticDynamicDefaultLayout(
    maxSpeed: number,
    maxTorque: number,
    maxPower: number,
    maxAcceleration: number
  ): void {
    const currentTopX = this.state.value(PlotState).layout?.xaxis?.range?.[1];
    const currentTopY = this.state.value(PlotState).layout?.yaxis?.range?.[1];

    // TODO: Move the following to an Action
    let title: string;
    switch (this.requirementYAxis()) {
      case CapabilityCurveTypeEnum.POWER:
        title = `Total Tractive Power (${this.unitChoices.getChoice(
          UnitType.POWER
        )})`;
        break;
      case CapabilityCurveTypeEnum.TORQUE:
        title = `Total Tractive Torque (${this.unitChoices.getChoice(
          UnitType.TORQUE
        )})`;
        break;
      case CapabilityCurveTypeEnum.ACCELERATION:
        title = `Acceleration (${this.unitChoices.getChoice(
          UnitType.ACCELERATION
        )})`;
        break;
      default:
        throw new Error('Unknown requirementYAxis value');
    }

    let maxRange: number;
    switch (this.requirementYAxis()) {
      case CapabilityCurveTypeEnum.POWER:
        maxRange = maxPower;
        break;
      case CapabilityCurveTypeEnum.TORQUE:
        maxRange = maxTorque;
        break;
      case CapabilityCurveTypeEnum.ACCELERATION:
        maxRange = maxAcceleration;
        break;
      default:
        throw new Error('Unknown requirementYAxis value');
    }

    this.staticDynamicDefaultLayout = {
      xaxis: {
        range: [0, Math.max(maxSpeed, currentTopX)],
        title: `Speed (${this.unitChoices.getChoice(UnitType.SPEED)})`,
      },
      yaxis: {
        range: [0, Math.max(maxRange, currentTopY)],
        title,
      },
      showlegend: true,
    };
  }

  public async displayData(): Promise<void> {
    // return this.solveRequirements();
  }

  public async processDriveCycleResults(results: any[]): Promise<void> {
    const [data, layouts] = await this.actions.execute(
      ProcessDriveCycleResultDataAction,
      results
    );
    this.mergedDriveCycles = data;
    this.driveCycleLayout = layouts;
  }

  filterDriveCycleLayout(id: string): any {
    return this.driveCycleLayout.find((layout) => layout.id === id);
  }

  public openTableFromPlot(data: any): void {
    if (data.data.id !== this.$selectedResult.value.id) {
      this.selectFromPlot.next(data.data.id);
    } else {
      this.dataDisplay.displayState = DataDisplayState.BLUEPRINT_DISPLAY;
      this.selectedTimeIndex.next(data.pointIndex);
    }
  }

  public async solvedComponentTable(
    result: any = this.$selectedResult.value,
    index: number = parseInt(this.selectedTimeIndex.value as string),
    columnFilter?: string[]
  ): Promise<void> {
    if (!columnFilter) {
      columnFilter = this.requirementColumns;
    } else {
      this.requirementColumns = columnFilter;
    }

    this.actions.execute(SolvedComponentTableAction, [
      result,
      index,
      columnFilter,
      this.requirementColumns,
    ]);
  }

  public filterDriveCycleData(id: string): Partial<any[]> {
    return this.mergedDriveCycles.filter((data) => {
      return data.id === id && data.x.length > 0 && data.y.length > 0;
    });
  }

  public getRequirementYAxis(
    capabilityCurve?: Record<string, number[]>
  ): AWCListItem[] {
    const acceleration = capabilityCurve?.['accelerations']
      ? {
          text: 'Acceleration',
          id: CapabilityCurveTypeEnum.ACCELERATION,
        }
      : null;

    return [
      {
        text: 'Torque',
        id: CapabilityCurveTypeEnum.TORQUE,
      },
      {
        text: 'Power',
        id: CapabilityCurveTypeEnum.POWER,
      },
      ...(acceleration ? [acceleration] : []),
    ] as AWCListItem[];
  }
}
