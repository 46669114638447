import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  ActionContributionsService,
  WorkspaceTab,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import {
  ActiveTabState,
  TabSectionAwareComponent,
} from '@ansys/andromeda/workspace';
import { State } from '@ansys/andromeda/store';
import { ConceptPopulated } from 'src/api';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import {
  Design,
  DesignInstance,
  OpenAPI,
  ProjectApiService,
} from '@ansys/cloud-angular-client';
import { Typography } from '@ansys/awc-angular/core';
import { LoadingSkeletonType } from '@ansys/awc-angular/loaders';
import { Icons } from '@ansys/awc-angular/icons';
import {
  GetDesignAction,
  GetDesignInstanceAction,
} from '@ansys/andromeda/dashboard';
import { OpenAPI as _APICONFIG } from 'src/api';
import { ActiveConceptState } from 'src/app/state/lib/active-concept.state';
import { ConceptService } from '../../../shared/services/concept.service';
import { ConceptEnum } from '../../../tabs/concept-tab/enums/concept.enum';
import { FeatureFlagsEnum } from '../../../shared/enums/feature-flags.enum';

@Component({
  selector: 'app-concept-title',
  templateUrl: './concept-title.component.html',
  styleUrls: ['./concept-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConceptTitleComponent extends TabSectionAwareComponent {
  protected display: boolean = false;
  protected projectTitle: string = 'Project';
  protected branchTitle: string = 'Branch';
  protected versionTitle: string = 'Version';
  protected loading: boolean = false;
  protected width: number = 100;
  protected lineHeight: Typography = Typography.CAPTION;
  protected type: LoadingSkeletonType = LoadingSkeletonType.LINE;
  protected chevron: Icons = Icons.RIGHT_ARROW;
  protected readonly featureFlagsEnum = FeatureFlagsEnum;
  private destroy$$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private state: State,
    private projectApi: ProjectApiService,
    private _cdr: ChangeDetectorRef,
    protected conceptService: ConceptService,
    private actions: ActionContributionsService
  ) {
    super();
    this.state.get(ActiveTabState).subscribe(() => {
      this.loading = true;
      this._cdr.markForCheck();
    });

    this.state
      .get(ActiveConceptState)
      .pipe(takeUntil(this.destroy$$))
      .subscribe((concept) => {
        this.projectTitle = 'Project';
        this.loading = true;
        if (!concept) return;
        this._cdr.detectChanges();
        this.getProjectTitle((concept as ConceptPopulated).project_id).then(
          async (title) => {
            this.projectTitle = title;
            this.loading = false;
            const instance = (await this.actions.execute(
              GetDesignInstanceAction,
              concept.design_instance_id
            )) as DesignInstance;
            const design = (await this.actions.execute(
              GetDesignAction,
              instance.designId
            )) as Design;
            this.versionTitle = instance.designInstanceTitle ?? '';
            this.branchTitle = design.designTitle ?? '';
            this._cdr.detectChanges();
          }
        );
      });
  }

  override ngOnDestroy(): void {
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }

  public override onTabChanged(tab: WorkspaceTab): void {
    this.display = tab.type === ConceptEnum.TAB_TYPE;
    this._cdr.detectChanges();
  }

  public override onSectionChanged(section: WorkspaceTabSection): void {
    section;
    // do nothing
  }

  private async getProjectTitle(projectId: string): Promise<string> {
    if (!OpenAPI.TOKEN) {
      OpenAPI.TOKEN = _APICONFIG.TOKEN;
    }
    const projectLoaded = await lastValueFrom(
      this.projectApi.projectLoadHandler({
        projectId: projectId,
      })
    );

    const title = projectLoaded.projectTitle || 'Project';
    return title;
  }
}
