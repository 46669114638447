/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MotorLossMapData } from './MotorLossMapData';
/**
 * Motor in Database.
 */
export type MotorLossMapInDB = {
    item_type?: MotorLossMapInDB.item_type;
    name?: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: 'MotorLossMap';
    loss_map: MotorLossMapData;
    poles?: number;
    inverter_losses_included?: boolean;
    _id?: string;
};
export namespace MotorLossMapInDB {
    export enum item_type {
        COMPONENT = 'component',
    }
}

