import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { ProjectApiService } from '@ansys/cloud-angular-client';
import { DIALOG_DATA } from '@ansys/andromeda/shared';
import { State } from '@ansys/andromeda/store';
import {
  ButtonComponent,
  ButtonSize,
  ButtonType,
} from '@ansys/awc-angular/buttons';
import { FlexLayout } from '@ansys/awc-angular/core';
import { Icons, IconType } from '@ansys/awc-angular/icons';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { lastValueFrom } from 'rxjs';
import { ActiveTabState } from '@ansys/andromeda/workspace';
import { RequirementItemState } from 'src/app/state/lib/requirements.state';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { RequirementType } from 'src/app/shared/enums';
import { environment } from '../../../../environments/environment';
import { AuthEnvironment } from '@ansys/andromeda/auth';
import { DefaultProjectFileName } from '../../../actions/lib/common-strings.action';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { InputComponent } from '../../widgets/forms/input/input.component';
import { SelectComponent, SwitchComponent } from '@ansys/awc-angular/forms';
import { TooltipDirective } from '@ansys/awc-angular/popouts';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-start-solve',
  templateUrl: './start-solve.component.html',
  styleUrls: ['./start-solve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    SelectComponent,
    ButtonComponent,
    SwitchComponent,
    TooltipDirective,
  ],
})
export class StartSolveComponent implements OnInit {
  protected type: ButtonType = ButtonType.PRIMARY;
  protected size: ButtonSize = ButtonSize.MEDIUM;
  protected suffixIcon: IconType = { icon: Icons.ONSCALE };
  protected staticRequirements: AWCListItem[] = [];
  protected dynamicRequirements: AWCListItem[] = [];
  protected dcRequirements: AWCListItem[] = [];
  protected layout: FlexLayout = FlexLayout.COLUMN;
  protected selectedStaticItems: string[] = [];
  protected selectedDynamicItems: string[] = [];
  protected selectedDCItems: string[] = [];
  protected studyName: string = '';
  protected readonly environment = environment;
  protected readonly authEnvironment = AuthEnvironment;
  protected extraMemory: boolean = false;

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private state: State,
    private projectApi: ProjectApiService,
    private _cdr: ChangeDetectorRef,
    private actions: ActionContributionsService
  ) {
    const tab = this.state.value(ActiveTabState);
    const requirements = this.state.value(RequirementItemState, tab?.id) || [];
    requirements?.forEach((req: AWCTreeItem) => {
      const reqClone = { ...req };
      const reqType = req?.userData?.['requirement_type'];
      if (reqType === RequirementType.STATIC_ACCELERATION) {
        this.staticRequirements.push(reqClone);
        this.selectedStaticItems.push(reqClone.id);
      } else if (reqType === RequirementType.DYNAMIC_INPUT) {
        this.dynamicRequirements.push(reqClone);
        this.selectedDynamicItems.push(reqClone.id);
      } else if (reqType === RequirementType.DRIVE_CYCLE) {
        if (!reqClone.text) {
          reqClone.text = 'Drive Cycle';
        }
        this.dcRequirements.push(reqClone);
        this.selectedDCItems.push(reqClone.id);
      }
    });
  }
  async ngOnInit(): Promise<void> {
    this.studyName = await this.actions.execute(DefaultProjectFileName);

    this._cdr.detectChanges();
  }

  protected confirm(): void {
    if (
      !this.studyName ||
      (!this.selectedStaticItems.length &&
        !this.selectedDynamicItems.length &&
        !this.selectedDCItems.length)
    ) {
      return;
    }

    this.data.confirm(
      this.studyName,
      [
        ...this.selectedStaticItems,
        ...this.selectedDynamicItems,
        ...this.selectedDCItems,
      ],
      this.extraMemory
    );
  }

  protected setStudyName(name: string | number): void {
    this.studyName = name as string;
  }

  protected selectionStaticChanged(items: AWCListItem[]): void {
    this.selectedStaticItems = items.map((item) => item.id);
  }

  protected selectionDynamicChanged(items: AWCListItem[]): void {
    this.selectedDynamicItems = items.map((item) => item.id);
  }

  protected selectionDCChanged(items: AWCListItem[]): void {
    this.selectedDCItems = items.map((item) => item.id);
  }

  protected memoryBoost(memoryToggle: boolean): void {
    this.extraMemory = memoryToggle; //(event.target as HTMLInputElement).checked;
  }
}
