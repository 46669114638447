import {
  ActionContributionsService,
  AndromedaMenuItem,
} from '@ansys/andromeda/contributions';
import { Icons, IconType } from '@ansys/awc-angular/icons';
import { DriveCyclesService } from '../../../api';
import { Injectable } from '@angular/core';
import { ActiveConceptState } from '../../state/lib/active-concept.state';
import { State } from '@ansys/andromeda/store';
import {
  DialogService,
  SnackBarService,
  TableRefreshDataAction,
} from '@ansys/andromeda/shared';
import { ConfirmComponent } from '../../components/dialogs/confirm/confirm.component';
import { lastValueFrom } from 'rxjs';
import { LoadingComponent } from '../../components/dialogs/loading/loading.component';
import { TableEnum } from '../../shared/enums/table.enum';
import { AlertComponent } from '../../components/dialogs/alert/alert.component';

export class DriveCycleMenu implements AndromedaMenuItem {
  id: string = 'drive-cycle';
  priority: number = 50;
  type: string = 'drive-cycle';
  prefixIcon: IconType = { icon: Icons.MORE };
  showOnHover: boolean = false;
}

@Injectable({
  providedIn: 'root',
})
export class DriveCycleDeleteMenuItem implements AndromedaMenuItem {
  id: string = 'drive-cycle-delete';
  priority: number = 50;
  type: string = 'drive-cycle-delete';
  prefixIcon: IconType = { icon: Icons.DELETE };
  showOnHover: boolean = false;
  text: string = 'Delete Item';
  context: any;

  constructor(
    private state: State,
    private driveCycleService: DriveCyclesService,
    private snackbar: SnackBarService,
    private action: ActionContributionsService,
    private dialogService: DialogService
  ) {}

  async execute(): Promise<void> {
    const concept = this.state.value(ActiveConceptState);
    if (!concept) return;
    const driveCycleId = this.context.id;

    if (this.context.selected) {
      this.dialogService.open(AlertComponent, {
        title: 'Cannot delete selected drive cycle',
        data: {
          message: 'Please select another drive cycle to delete.',
        },
      });
    } else {
      const dialog = this.dialogService.open(ConfirmComponent, {
        title: 'Are you sure you want to delete this Drive Cycle?',
        data: {
          onConfirm: async () => {
            dialog.close();
            const loadingDialog = this.dialogService.open(LoadingComponent);
            lastValueFrom(
              this.driveCycleService.deleteDriveCyclesItemIdDelete(
                driveCycleId,
                concept.design_instance_id
              )
            )
              .then(() => {
                this.snackbar.success('Drive Cycle Deleted');
                this.action.execute(
                  TableRefreshDataAction,
                  TableEnum.DRIVE_CYCLE
                );

                loadingDialog.close();
              })
              .catch(() => {
                this.snackbar.error('Failed to delete Drive Cycle');
              });
          },
          onCancel: () => {
            dialog.close();
          },
        },
      });
    }
  }

  render(): AndromedaMenuItem {
    return this;
  }
}
