export enum PlotConfigType {
  CONFIGURATIONS = 'configurations',
  REQUIREMENTS = 'requirements',
  GEARS = 'gears',
  VOLTAGE = 'voltage',
  LAB_MOTOR = 'lab_motor',
  INVERTER_LOSSES = 'inverter_losses',
  NONE = 'none',
  BATTERY_LOOKUP_TABLE = 'battery_lookup_table',
}
