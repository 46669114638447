import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  WorkspaceTab,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import {
  ActiveTabState,
  TabSectionAwareComponent,
} from '@ansys/andromeda/workspace';
import { State } from '@ansys/andromeda/store';
import { Subject } from 'rxjs';
import { Typography } from '@ansys/awc-angular/core';
import {
  LoadingSkeletonComponent,
  LoadingSkeletonType,
} from '@ansys/awc-angular/loaders';
import { Icons } from '@ansys/awc-angular/icons';
import { ConceptService } from '../../../shared/services/concept.service';
import { ConceptEnum } from '../../../tabs/concept-tab/enums/concept.enum';
import { FeatureFlagsEnum } from '../../../shared/enums/feature-flags.enum';
import { TooltipDirective } from '@ansys/awc-angular/popouts';
import { CommonModule } from '@angular/common';
import { FeatureFlagModule } from '../../../shared/modules/feature-flag.module';
import { InputComponent } from '@ansys/awc-angular/forms';
import { ConceptMetaState } from '../../../state/lib/concept-meta.state';

@Component({
  selector: 'app-concept-title',
  templateUrl: './concept-title.component.html',
  styleUrls: ['./concept-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TooltipDirective,
    LoadingSkeletonComponent,
    CommonModule,
    FeatureFlagModule,
    InputComponent,
  ],
})
export class ConceptTitleComponent extends TabSectionAwareComponent {
  protected display: boolean = false;
  protected projectTitle: string = 'Project';
  protected branchTitle: string = 'Branch';
  protected versionTitle: string = 'Version';
  protected loading: boolean = false;
  protected width: number = 100;
  protected lineHeight: Typography = Typography.CAPTION;
  protected type: LoadingSkeletonType = LoadingSkeletonType.LINE;
  protected chevron: Icons = Icons.RIGHT_ARROW;
  protected readonly featureFlagsEnum = FeatureFlagsEnum;
  protected isEditing: boolean = false;
  protected editField: string = '';
  protected editValue: string = '';
  private destroy$$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private state: State,
    private _cdr: ChangeDetectorRef,
    protected conceptService: ConceptService
  ) {
    super();
    this.state.get(ActiveTabState).subscribe(() => {
      this.loading = true;
      this._cdr.markForCheck();
    });

    this.state.get(ConceptMetaState).subscribe((conceptMetaState) => {
      if (conceptMetaState) {
        this.projectTitle = conceptMetaState.projectTitle;
        this.branchTitle = conceptMetaState.projectBranch;
        this.loading = false;
        this._cdr.detectChanges();
      }
    });
  }

  override ngOnDestroy(): void {
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }

  public override onTabChanged(tab: WorkspaceTab): void {
    this.display = tab.type === ConceptEnum.TAB_TYPE;
    this._cdr.detectChanges();
  }

  public override onSectionChanged(section: WorkspaceTabSection): void {
    section;
    // do nothing
  }

  protected onEditTitle(field: string): void {
    this.editField = field;
    switch (this.editField) {
      case 'project':
        this.editValue = this.projectTitle;
        break;
      case 'branch':
        this.editValue = this.branchTitle;
        break;
      case 'version':
        this.editValue = this.versionTitle;
        break;
    }
    this.isEditing = true;
  }

  protected finishEditing(): void {
    switch (this.editField) {
      case 'project':
        this.projectTitle = this.editValue;
        this.conceptService.updateProject(this.projectTitle);
        break;
      case 'branch':
        this.branchTitle = this.editValue;
        this.conceptService.updateFile(this.branchTitle);
        break;
      case 'version':
        this.versionTitle = this.editValue;
        break;
    }
    this.isEditing = false;
  }

  protected cancelEditing(): void {
    this.isEditing = false;
  }
}
