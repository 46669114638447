<div #markerEl cdkDragBoundary=".drag-zone" *ngIf="marker?.display" cdkDrag class="dragger" [ngClass]="[marker.type, _theme || '']" (cdkDragMoved)="setMarkerPositions($event, marker)" (cdkDragEnded)="endDrag($event, marker)">
  <div class="back-drop"></div>
  <div class="marker-title" *ngIf="marker.type === DataType.COMPONENT">
    <awc-icon  [icon]="componentIcon" [src]="componentSrc" [size]="smallsize"></awc-icon>
    <span>
      {{marker.title}}
    </span>
  </div>
  <div class="marker-data">
    <ng-container *ngFor="let value of marker?.data">
      <div class="data-input" >
        <span class="label">{{value.label}}:</span>
        <span>
          <span>{{value.value}}</span>
          <span class="unit" *ngIf="value.unit">&nbsp;{{value.unit | unitFromType }}</span>
        </span>
      </div>
    </ng-container>
  </div>
</div>
