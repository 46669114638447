/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { Body_create_from_file_drive_cycles_from_file_post } from '../models/Body_create_from_file_drive_cycles_from_file_post';
import type { Body_upload_drive_cycle_file_drive_cycles_upload_file_post } from '../models/Body_upload_drive_cycle_file_drive_cycles_upload_file_post';
import type { DriveCycle } from '../models/DriveCycle';
import type { DriveCycleInDB } from '../models/DriveCycleInDB';
import type { DriveCycleS3 } from '../models/DriveCycleS3';
import type { DriveCycleS3InDB } from '../models/DriveCycleS3InDB';
import type { FileParameters } from '../models/FileParameters';
import type { SubmittedJob } from '../models/SubmittedJob';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class DriveCyclesService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Create
     * Create from parameters.
     * @param designInstanceId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createDriveCyclesPost(
        designInstanceId: string,
        requestBody: (DriveCycle | DriveCycleS3),
    ): Observable<(DriveCycleInDB | DriveCycleS3InDB)> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/drive_cycles',
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read
     * Get from ID.
     * @param itemId
     * @param designInstanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public readDriveCyclesItemIdGet(
        itemId: string,
        designInstanceId: string,
    ): Observable<(DriveCycleInDB | DriveCycleS3)> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/drive_cycles/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * Update with new parameters.
     * @param itemId
     * @param designInstanceId
     * @param requestBody
     * @returns DriveCycleInDB Successful Response
     * @throws ApiError
     */
    public updateDriveCyclesItemIdPut(
        itemId: string,
        designInstanceId: string,
        requestBody: (DriveCycle | DriveCycleS3),
    ): Observable<DriveCycleInDB> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/drive_cycles/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete
     * Delete by ID.
     * @param itemId
     * @param designInstanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteDriveCyclesItemIdDelete(
        itemId: string,
        designInstanceId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/drive_cycles/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Drive Cycle Names
     * Get a dict of drive cycle names by ID.
     * @param designInstanceId
     * @param skip
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public listDriveCycleNamesDriveCyclesNamesGet(
        designInstanceId: string,
        skip?: number,
        limit: number = 100,
    ): Observable<Record<string, any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/drive_cycles:names',
            query: {
                'design_instance_id': designInstanceId,
                'skip': skip,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Drive Cycle Data
     * Get a list of drive cycle data dicts.
     * @param designInstanceId
     * @param skip
     * @param limit
     * @returns any Successful Response
     * @throws ApiError
     */
    public listDriveCycleDataDriveCyclesDataGet(
        designInstanceId: string,
        skip?: number,
        limit: number = 100,
    ): Observable<Array<Record<string, any>>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/drive_cycles:data',
            query: {
                'design_instance_id': designInstanceId,
                'skip': skip,
                'limit': limit,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Create From File
     * Create a requirement from file.
     *
     * It can be quite difficult to add anything else to this:
     * https://stackoverflow.com/questions/65504438/how-to-add-both-file-and-json-body-in-a-fastapi-post-request
     * @param driveCycleName
     * @param designInstanceId
     * @param formData
     * @returns DriveCycleInDB Successful Response
     * @throws ApiError
     */
    public createFromFileDriveCyclesFromFilePost(
        driveCycleName: string,
        designInstanceId: string,
        formData: Body_create_from_file_drive_cycles_from_file_post,
    ): Observable<DriveCycleInDB> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/drive_cycles:from_file',
            query: {
                'drive_cycle_name': driveCycleName,
                'design_instance_id': designInstanceId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upload Drive Cycle File
     * Create job for a drive cycle initial processing.
     * @param fileParameters
     * @param designInstanceId
     * @param formData
     * @returns SubmittedJob Successful Response
     * @throws ApiError
     */
    public uploadDriveCycleFileDriveCyclesUploadFilePost(
        fileParameters: FileParameters,
        designInstanceId: string,
        formData: Body_upload_drive_cycle_file_drive_cycles_upload_file_post,
    ): Observable<SubmittedJob> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/drive_cycles:upload_file',
            query: {
                'file_parameters': fileParameters,
                'design_instance_id': designInstanceId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Calc Image Data
     * Calculate component data from an ID.
     * @param driveCycleId
     * @param designInstanceId
     * @returns DriveCycle Successful Response
     * @throws ApiError
     */
    public calcImageDataDriveCyclesImageDataPost(
        driveCycleId: string,
        designInstanceId: string,
    ): Observable<DriveCycle> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/drive_cycles:image_data',
            query: {
                'drive_cycle_id': driveCycleId,
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
}
