<div>
  @if (resource) {
    <app-part-inputs [(part)]="resource" [type]="parentType" [readonly]="!data.editable?.()" [update]="data.editable?.() ?? false" (updateInput)="changeResource($event, resource)" [forceEnable]="true"></app-part-inputs>
  }

  @if (data.editable?.()) {
    <div class="actions">
      <awc-button
        text="Finish Edit"
        [suffixIcon]="suffixIcon"
        [type]="type"
        [size]="size"
        (clicked)="cancelEdit()"></awc-button>
    </div>
  }
</div>
