/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AWFNotificationProvider,
  NOTIFICATION_PROVIDER,
  SnackBarService,
} from '@ansys/andromeda/shared';
import { Icons } from '@ansys/awc-angular/icons';
import { ApiError } from 'src/api';

/**
 * This service will return the appropriate service for the section type. This is used to abstract the logic of getting the correct service from the section type away from the shared component.
 */

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private snackbar: SnackBarService,
    @Inject(NOTIFICATION_PROVIDER)
    private notifications: AWFNotificationProvider
  ) {}

  public error(message: string, err: ApiError): void {
    this.snackbar.duration = 8000;
    let subText = '';
    try {
      subText = this.getSubText(err);

      // If message too long we need to split it and add a new line so the snackbar doesnt try split on words - BLERGH
      if (`${message}: ${subText}`.length > 70) {
        const splitStr = subText.split(' ');
        const strArr = [];
        let n = message.length;
        for (let i = 0; i < splitStr.length; i++) {
          n += splitStr[i].length + 1;
          if (n < 70) {
            strArr.push(splitStr[i]);
          } else {
            n = 0;
            strArr.push('\n');
            strArr.push(splitStr[i]);
          }
        }
        subText = strArr.join(' ');
      }
      this.snackbar.error(`${message}: ${subText}`);
    } catch (e) {
      subText = this.getSubText(err);
      this.snackbar.error(`${message}: ${subText}`);
    }

    this.notifications.addNotification({
      text: message,
      subText: subText,
      prefixIcon: {
        icon: Icons.WARNING,
      },
    });
    // reset to default duration;
    this.snackbar.duration = 4000;
  }

  private getSubText(err: ApiError): string {
    let subText = '';
    try {
      subText = err.body?.detail?.[0].msg || err.body?.message;
      if (!subText) {
        const httpError = err as unknown as HttpErrorResponse;
        const errorContent = httpError?.error;

        subText =
          typeof errorContent === 'object'
            ? errorContent?.message || 'Internal Server Error'
            : errorContent || 'Internal Server Error';
      }
    } catch (e) {
      console.error(e);
      subText = 'Internal Server Error';
    }

    return subText;
  }
}
