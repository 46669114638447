import { PagesEnum } from '../pages.enum';
import { AWFPage, Page } from '@ansys/andromeda/core';
import {
  WorkspaceLayout,
  WorkspaceLayoutSlots,
} from '@ansys/andromeda/workspace';
import { ConceptNavigationToolsComponent } from 'src/app/components/widgets/concept-navigation-tools/concept-navigation-tools.component';
import { ConceptTitleComponent } from 'src/app/components/widgets/concept-title/concept-title.component';

@AWFPage({
  route: PagesEnum.WORKSPACE,
  fullPath: true,
  rootPage: true,
  layout: [
    {
      content: WorkspaceLayout,
      slots: [
        // {
        //   slot: WorkspaceLayoutSlots.PROFILE_TOOLS,
        //   component: ProfileToolsComponent,
        // },
        {
          slot: WorkspaceLayoutSlots.RIGHT_NAV,
          component: ConceptNavigationToolsComponent,
        },
        // {
        //   slot: WorkspaceLayoutSlots.TAB_ACTIONS,
        //   component: TabActionsComponent,
        // },
        {
          slot: WorkspaceLayoutSlots.SECTION_HEADER,
          component: ConceptTitleComponent,
        },
      ],
    },
  ],
})
export class WorkspacePage implements Page {
  type = PagesEnum.WORKSPACE;
  title = 'Workspace';
  description = 'Workspace';
}
