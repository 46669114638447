<div class="relative-cont">
    @if (step === wizardProgress.START) {
        <awc-select
                [placeholder]="placeholder"
                [items]="itemTypes"
                [iconButton]="addGeometry"
                [selectedItems]="[selectedItemType]"
                [layout]="layout"
                (iconButtonAction)="confirmItemType()"
                (selectionChanged)="selectionItemTypeChanged($event[0])"></awc-select>
    }

    @else if (step === wizardProgress.END) {
        @if (selectedItemType === conceptSections.CONFIGURATIONS) {
            <awc-select
                    [placeholder]="placeholder"
                    [items]="configItems"
                    [iconButton]="addGeometry"
                    [selectedItems]="[]"
                    [layout]="layout"
                    (iconButtonAction)="confirmItem()"
                    (selectionChanged)="selectionItemChanged($event[0])"></awc-select>
        }

        @if (selectedItemType === conceptSections.COMPONENT) {
            <awc-select
                    [placeholder]="placeholder"
                    [items]="componentItems"
                    [iconButton]="addGeometry"
                    [selectedItems]="[]"
                    [layout]="layout"
                    (iconButtonAction)="confirmItem()"
                    (selectionChanged)="selectionItemChanged($event[0])"></awc-select>
        }
    }
</div>

<!--<div>-->
<!--  <awc-button-->
<!--    class="part-edit-inputs__confirm"-->
<!--    [text]="'Confirm Add'"-->
<!--    [suffixIcon]="suffixIcon"-->
<!--    [type]="button.PRIMARY"-->
<!--    [size]="size"-->
<!--    (clicked)="confirm()"></awc-button>-->
<!--</div>-->
