import { AWCListItem } from '@ansys/awc-angular/lists';

export const batteryLookupHeadersConst: AWCListItem[] = [
  {
    id: 'voltage',
    text: 'Voltage', // (${this.unitChoices.getChoice(UnitType.VOLTAGE)})
  },
  {
    id: 'usable_charge',
    text: 'Usable Charge', // (${this.unitChoices.getChoice(UnitType.ELECTRICAL_ENERGY )})
  },
  {
    id: 'state_of_charge',
    text: 'State of Charge', // (${this.unitChoices.getChoice( UnitType.RATIO )})
  },
  {
    id: 'power_limit_charge',
    text: 'Power Acceptance Limit', // (${this.unitChoices.getChoice( UnitType.ELECTRICAL_POWER )})
  },
  {
    id: 'power_limit_discharge',
    text: 'Power Release Limit', // (${this.unitChoices.getChoice( UnitType.ELECTRICAL_POWER )})
  },
  {
    id: 'internal_resistance_charge',
    text: 'Internal Resistance Charge', // (${this.unitChoices.getChoice( UnitType.RESISTANCE )})
  },
  {
    id: 'internal_resistance_discharge',
    text: 'Internal Resistance Discharge', // (${this.unitChoices.getChoice( UnitType.RESISTANCE )})
  },
];
