/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, WritableSignal, signal } from '@angular/core';
import { ConceptSections } from '../enums/concept.enum';
import { BehaviorSubject } from 'rxjs';
import { ProgressBarItem } from '@ansys/awc-angular/loaders';
import { JobLoadingStatus } from '../enums/job-loading-status.enum';
export enum DataDisplayType {
  NONE = 'none',
  LOSSMAP = 'lossmap',
  LOOKUP = 'lookup',
}
export enum DataDisplayState {
  LOADING = 'loading',
  PROGRESS = 'progress',
  CONFIG_HELP = 'config_help',
  REQUIREMENT_HELP = 'requirement_help',
  COMPONENT_HELP = 'component_help',
  GRAPH_DISPLAY = 'graph_display',
  TABLE_DISPLAY = 'table_display',
  BLUEPRINT_DISPLAY = 'blueprint_display',
  NO_DATA = 'no_data',
  ARCHITECTURE = 'architecture',
}
/**
 */

@Injectable({ providedIn: 'root' })
export class DataDisplayService {
  public stateChanged: BehaviorSubject<DataDisplayState> =
    new BehaviorSubject<DataDisplayState>(DataDisplayState.LOADING);
  public set displayType(type: DataDisplayType) {
    this.$displayType = type;
  }
  public get displayType(): DataDisplayType {
    return this.$displayType;
  }
  public set displayState(state: DataDisplayState) {
    this.stateChanged.next(state);
    this.$displayState = state;
  }
  public get displayState(): DataDisplayState {
    return this.$displayState;
  }
  public set message(message: string) {
    this.$message = message;
  }
  public get message(): string {
    return this.$message;
  }
  public progress: WritableSignal<number> = signal(0);
  public progressItem: WritableSignal<ProgressBarItem[]> = signal([
    {
      color: 'primary-default',
      label: '',
      tooltipValue: '',
      percentage: this.progress(),
    },
  ]);
  private $displayType: DataDisplayType = DataDisplayType.NONE;
  private $displayState: DataDisplayState = DataDisplayState.LOADING;
  private $message: string = '';

  private displayTypeMap: Map<string, DataDisplayType> = new Map([
    ['TransmissionFixedEfficiency', DataDisplayType.NONE],
    ['MotorLossMap', DataDisplayType.LOSSMAP],
    ['MotorLossMapID', DataDisplayType.LOSSMAP],
    ['MotorLabModel', DataDisplayType.LOSSMAP],
    ['MotorLabID', DataDisplayType.LOSSMAP],
    ['TransmissionLossMap', DataDisplayType.LOSSMAP],
    ['TransmissionLossMapID', DataDisplayType.LOSSMAP],
    ['TransmissionLossCoefficients', DataDisplayType.LOSSMAP],
    ['MotorTorqueCurveID', DataDisplayType.LOSSMAP],
    ['InverterAnalytical', DataDisplayType.LOSSMAP],
    ['BatteryLookup', DataDisplayType.LOOKUP],
    ['BatteryFixedVoltages', DataDisplayType.NONE],
    ['BatteryLookupData', DataDisplayType.LOOKUP],
    ['BatteryLookupTableID', DataDisplayType.LOOKUP],
  ]);
  private helpSectionMap: Map<ConceptSections, DataDisplayState> = new Map([
    [ConceptSections.CONFIGURATIONS, DataDisplayState.CONFIG_HELP],
    [ConceptSections.COMPONENT, DataDisplayState.COMPONENT_HELP],
    [ConceptSections.ARCHITECTURE, DataDisplayState.ARCHITECTURE],
    [ConceptSections.REQUIREMENT, DataDisplayState.REQUIREMENT_HELP],
  ]);

  public getDisplayType(componentType: string): DataDisplayType {
    this.displayType =
      this.displayTypeMap.get(componentType) || DataDisplayType.NONE;
    return this.displayType;
  }
  public getHelpSection(type: ConceptSections): DataDisplayState {
    return this.helpSectionMap.get(type) || DataDisplayState.LOADING;
  }
  public setFromJobStatus(status: JobLoadingStatus): void {
    if (status === JobLoadingStatus.LOADING) {
      this.displayState = DataDisplayState.LOADING;
    }
    if (status === JobLoadingStatus.PROGRESS) {
      this.displayState = DataDisplayState.PROGRESS;
    }
    if (status === JobLoadingStatus.FINISHED) {
      this.displayState = DataDisplayState.GRAPH_DISPLAY;
    }
    //flawed
    if (status === JobLoadingStatus.IDLE) {
      this.displayState = DataDisplayState.REQUIREMENT_HELP;
    }
  }
  public updateProgressItem(progress: number): void {
    this.progress.set(progress);
    this.progressItem.set([
      {
        color: 'primary-default',
        label: `${Math.round(progress)}%`,
        tooltipValue: `${Math.round(progress)}%`,
        percentage: progress,
      },
    ]);
  }
}
