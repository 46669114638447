import { LibraryObject } from '@ansys/cloud-angular-client';
import { Injectable } from '@angular/core';
import {
  AWCTableCell,
  TableCellAlign,
  TableCellTypes,
} from '@ansys/awc-angular/tables';
import { TableColumn } from '@ansys/andromeda/contributions';

@Injectable({ providedIn: 'root' })
export class ProjectLogoColumn implements TableColumn {
  public header: string = '';
  public id: string = 'project-table-logo';
  public priority: number = 0;
  public type: TableCellTypes = TableCellTypes.ICON;
  public align: TableCellAlign = TableCellAlign.CENTER;
  public width: number = 50;

  public render(data: LibraryObject): Promise<AWCTableCell> {
    return Promise.resolve({
      value: 'assets/icons/concept_ev.svg',
      externalSrc: true,
    });
  }
}
