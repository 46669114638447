import { Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { RequirementPlotType } from '../../shared/enums/reqirement-plot-type.enum';
import { UnitType } from '../../../api';
import { RequirementsService } from '../../shared/services/requirements.service';
import { ConceptUnitService } from '../../shared/services/unit.service';
import { State } from '@ansys/andromeda/store';
import { PlotService } from '../../shared/services/plot.service';

type InputType = [any, boolean];
type ReturnType = any[];

/**
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class SelectResultAction implements AWFAction<InputType, ReturnType> {
  constructor(
    private requirementService: RequirementsService,
    private unitChoices: ConceptUnitService
  ) {}

  async execute(context: InputType): Promise<ReturnType> {
    const [result, driveCycle] = context;

    const id = result.id;
    let plotData = driveCycle
      ? this.requirementService.filterDriveCycleData(id)
      : this.requirementService.mergedData;

    plotData.forEach((data) => {
      if (data._type === RequirementPlotType.REQUIREMENT) {
        if (data.id !== id) {
          data.marker.color = 'grey';
        } else if (result.feasible === true) {
          data.marker.color = 'green';
        } else if (result.feasible === false) {
          data.marker.color = 'red';
        }
      }
      if (data.line && data._type === RequirementPlotType.CAPABILITY_CURVE) {
        data.line.color = data.id !== id ? 'grey' : 'orange';
      }
    });

    // append traction limit
    if (result.traction_limit) {
      const convertedSpeed = this.unitChoices.convertUnit(
        UnitType.SPEED,
        result.traction_limit.speed
      );
      const convertedTorque = this.unitChoices.convertUnit(
        UnitType.TORQUE,
        result.traction_limit.total_tractive_torque
      );
      plotData = [...plotData];
      plotData.push({
        type: 'scatter',
        x: [convertedSpeed] as never[],
        y: [convertedTorque] as never[],
        name: 'Traction Limit',
        marker: { size: 20, color: 'red', symbol: 'x' },
        _type: RequirementPlotType.EXTRA,
        id: result.id,
      });
    }

    if (result.traction_limits) {
      plotData = [...plotData];
      const x: number[] = [];
      const y: number[] = [];
      let name: string = '';
      result.traction_limits.forEach((limit: any) => {
        if (!limit) return;
        const convertedSpeed = this.unitChoices.convertUnit(
          UnitType.SPEED,
          limit.speed
        );
        const convertedTorque = this.unitChoices.convertUnit(
          UnitType.TORQUE,
          limit.total_tractive_torque
        );
        x.push(convertedSpeed);
        y.push(convertedTorque);
        name = (limit as any).name;
      });

      plotData.push({
        type: 'scatter',
        x,
        y,
        name,
        marker: { size: 10, color: 'red', symbol: 'x' },
        _type: RequirementPlotType.EXTRA,
        id: result.id,
        mode: 'markers',
      });
    }

    //sorted mergedData by matching id
    return plotData.sort((a, b) => {
      if (a.id === id) return 1;
      if (b.id === id) return -1;
      return 0;
    });
  }
}
