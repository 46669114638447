import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import {
  DIALOG_DATA,
  DIALOG_REF,
  TableRefreshDataAction,
} from '@ansys/andromeda/shared';
import {
  AWCTableColumns,
  AWCTableRow,
  TableCellAlign,
} from '@ansys/awc-angular/tables';
import { Icons } from '@ansys/awc-angular/icons';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { RequirementsService } from '../../../shared/services/requirements.service';
import { TableEnum } from '../../../shared/enums/table.enum';
import { AWCFilter } from '@ansys/awc-angular/filters';
import { State } from '@ansys/andromeda/store';
import { SelectedDriveCycleState } from '../../../state/lib/selected-drive-cycle.state';
import { EventBus } from '../../../shared/tools/event-bus';
import { UpdateInputs } from '../../../actions';
import { driveCycle } from '../../../shared/models/concept-requirements/drive-cycle-requirement.model';
import {
  DataDisplayService,
  DataDisplayState,
} from '../../../shared/services/data-display.service';

@Component({
  selector: 'app-import-drive-cycle',
  templateUrl: './import-drive-cycle.component.html',
  styleUrl: './import-drive-cycle.component.scss',
})
export class ImportDriveCycleComponent {
  @ViewChild('fileUpload') upload!: ElementRef;
  protected driveCycles: AWCTableRow[] = [];
  protected columns: AWCTableColumns[] = [
    {
      id: 'name',
      header: 'Name',
      sortable: true,
      align: TableCellAlign.LEFT,
    },
    {
      id: 'menu',
      header: '',
    },
  ];
  protected driveCyclesLoaded: boolean = false;
  protected readonly icons = Icons;
  protected filters: AWCFilter[] = [];
  protected readonly tableEnum = TableEnum;
  private bus: EventBus = EventBus.getInstance();
  private updatingSelection: boolean = false;

  constructor(
    @Inject(DIALOG_DATA)
    private data: {
      selectedDriveCycle: string;
      part: any;
      onUpdate: () => void;
    },
    @Inject(DIALOG_REF) private dialogRef: any,
    private action: ActionContributionsService,
    private requirementService: RequirementsService,
    private state: State,
    private actionService: ActionContributionsService,
    private displayService: DataDisplayService
  ) {
    this.driveCyclesLoaded = true;

    this.bus.register('input:update:complete', () => {
      this.updatingSelection = false;
    });
  }

  ngOnInit(): void {
    this.state.set(SelectedDriveCycleState, this.data.selectedDriveCycle);
  }

  ngOnDestroy(): void {
    this.state.set(SelectedDriveCycleState, '');
  }

  protected uploadClick(): void {
    this.upload.nativeElement.value = '';
  }

  protected addDriveCycle($event: Event): void {
    this.upload.nativeElement.click();
  }

  protected async importDriveCycle($event: Event): Promise<void> {
    this.driveCyclesLoaded = false;
    const input = $event.target as HTMLInputElement;
    if (!input?.files?.length) return;

    const file: File = input.files[0];

    if (file) {
      const driveCycle = await this.requirementService.addDriveCycleFromFile(
        file.name.split('.')[0],
        file
      );

      if (driveCycle.id) {
        this.selectionIDSet(driveCycle.id);
      }
    }
    this.driveCyclesLoaded = true;
  }

  protected async selectionIDSet(driveCycleId: string): Promise<void> {
    if (this.updatingSelection) {
      return;
    }
    this.displayService.displayState = DataDisplayState.LOADING;
    this.updatingSelection = true;
    this.state.set(SelectedDriveCycleState, driveCycleId);
    this.data.part['drive_cycle_id'] = driveCycleId;
    this.data.selectedDriveCycle = driveCycleId;
    this.actionService.execute(UpdateInputs);
    this.bus.dispatch('drive-cycle:select:update', driveCycleId);
    this.data.onUpdate();
    await this.action.execute(
      TableRefreshDataAction,
      this.tableEnum.DRIVE_CYCLE
    );
    this.dialogRef.close();
  }
}
