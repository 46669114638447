import { StateNode } from '@ansys/andromeda/store';
import { PlotlyDataLayoutConfig } from 'plotly.js-dist-min';

export class PlotState implements StateNode<Partial<PlotlyDataLayoutConfig>> {
  key: string = 'plotLayout';
  mutable: boolean = true;
  observeInitial: boolean = true;
  defaultValue: () => Partial<PlotlyDataLayoutConfig> =
    (): Partial<PlotlyDataLayoutConfig> => {
      return {
        data: [],
        layout: {
          title: { text: '' }, // AWC Version 3 requires a title to be set
          autosize: true,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          paper_bgcolor: 'rgba(0,0,0,0)',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          plot_bgcolor: 'rgba(0,0,0,0)',
          showlegend: true,
          legend: { orientation: 'h' },
          xaxis: {
            gridwidth: 0.1,
            range: [0, 0],
          },
          yaxis: {
            gridwidth: 0.1,
            range: [0, 0],
          },
          yaxis2: {},
        },
        config: {
          displayModeBar: false,
        },
      };
    };
}
