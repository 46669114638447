/* eslint-disable @typescript-eslint/member-ordering */
import { Component } from '@angular/core';
import {
  WorkspaceTab,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import { DialogService } from '@ansys/andromeda/shared';
import { TabSectionAwareComponent } from '@ansys/andromeda/workspace';
import { ComponentLayout } from '@ansys/awc-angular/core';
import { Icons } from '@ansys/awc-angular/icons';
import { AWCMenuBarItem, MenuBarDisplay } from '@ansys/awc-angular/menus';
import { UnitsComponent } from '../../dialogs/units/units.component';
import { ImportLibraryAssetComponent } from '../../dialogs/import-library-asset/import-library-asset.component';
import { ConceptEnum } from '../../../tabs/concept-tab/enums/concept.enum';

@Component({
  selector: 'app-concept-navigation-tools',
  templateUrl: './concept-navigation-tools.component.html',
  styleUrls: ['./concept-navigation-tools.component.scss'],
})
export class ConceptNavigationToolsComponent extends TabSectionAwareComponent {
  protected display: boolean = false;
  protected id: string = 'ExampleMenuBar';
  protected items: AWCMenuBarItem[] = [
    {
      id: 'unitsTB',
      prefixIcon: {
        src: 'https://s2.svgbox.net/materialui.svg?ic=emoji_symbols',
      },
      text: 'Units',
      children: [],
      execute: (): void => {
        this.openUnits();
      },
    },
    {
      id: 'libraryTB',
      prefixIcon: {
        icon: Icons.LEGO,
      },
      text: 'Library',
      // disabled: true,
      execute: (): void => {
        // your function code here
        this.openLibrary();
      },
      children: [],
    },
  ];
  protected displayBar: MenuBarDisplay = MenuBarDisplay.ICON_TEXT;
  protected layout: ComponentLayout = ComponentLayout.HORIZONTAL;

  constructor(private dialogService: DialogService) {
    super();
  }
  private openUnits(): void {
    this.dialogService.open(UnitsComponent, {
      title: 'Units',
    });
  }
  private openLibrary(): void {
    this.dialogService.open(ImportLibraryAssetComponent, {
      title: 'Library',
    });
  }
  public override onTabChanged(tab: WorkspaceTab): void {
    this.display = tab.type === ConceptEnum.TAB_TYPE;
  }
  public override onSectionChanged(section: WorkspaceTabSection): void {
    section;
    // do nothing
  }
}
