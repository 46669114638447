import {
  TabTitleDisplayStrategy,
  WorkspaceTab,
} from '@ansys/andromeda/contributions';
import { ConceptEnum } from './enums/concept.enum';
// import { ConceptService } from '../../shared/services/concept.service';
import {
  DesignApiService,
  ProjectApiService,
} from '@ansys/cloud-angular-client';
import { lastValueFrom } from 'rxjs';
import { AWFTab } from '@ansys/andromeda/core';
import { ConceptLayoutComponent } from '../../components/layout/concept-layout/concept-layout.component';
import { VehicleSection } from './sections/configurations-section/configurations-section';
import { ComponentSection } from './sections/component-section/component-section';
import { ArchitectureSection } from './sections/architecture-section/architecture-section';
import { RequirementsSection } from './sections/requirement-section/requirement-section';

@AWFTab({
  route: ConceptEnum.TAB_TYPE,
  displayStrategy: TabTitleDisplayStrategy.TEXT,
  closable: true,
  cacheable: true,
  layout: [{ content: ConceptLayoutComponent }],
  sections: [
    { section: VehicleSection },
    { section: ComponentSection },
    { section: ArchitectureSection },
    { section: RequirementsSection },
  ],
})
export class ConceptTab {
  type: string = ConceptEnum.TAB_TYPE;
  public title = 'Concept';
  public urlParamKeys = ['instanceId'];

  constructor(
    private designService: DesignApiService,
    private projectService: ProjectApiService
  ) {}

  async getTitle(tab: WorkspaceTab): Promise<string> {
    let project;
    if (tab?.userData) {
      const instance = await lastValueFrom(
        this.designService.designInstanceLoadHandler({
          designInstanceId: tab?.userData?.['id'] as string,
        })
      );
      project = await lastValueFrom(
        this.projectService.projectLoadHandler({
          projectId: instance?.projectId as string,
        })
      );
    }
    return project?.projectTitle ?? this.title;
  }
}
