/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Battery in Database.
 */
export type BatteryFixedVoltagesInDB = {
    item_type?: BatteryFixedVoltagesInDB.item_type;
    name?: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: 'BatteryFixedVoltages';
    voltage_max?: number;
    voltage_min?: (number | null);
    charge_acceptance_limit?: (number | null);
    charge_release_limit?: (number | null);
    capacity?: (number | null);
    internal_resistance_charge?: number;
    internal_resistance_discharge?: number;
    _id?: string;
    id?: string;
};
export namespace BatteryFixedVoltagesInDB {
    export enum item_type {
        COMPONENT = 'component',
    }
}

