/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Aero configs with Database ID.
 */
export type AeroInDB = {
    item_type?: AeroInDB.item_type;
    name?: string;
    drag_coefficient_front?: number; // TODO: Don't remove this property via codegen
    drag_coefficient_rear?: (number | null);
    cross_sectional_area?: number;
    config_type?: 'aero';
    _id?: string;
    id?: string;
};
export namespace AeroInDB {
    export enum item_type {
        CONFIG = 'config',
    }
}

