import { Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { StaticRequirement, UnitType } from '../../../api';
import { RequirementPlotType } from '../../shared/enums/reqirement-plot-type.enum';
import { ConvertUnitValuesAction } from '../convert-unit-values/convert-unit-values.action';
import { RequirementsService } from '../../shared/services/requirements.service';
import { CapabilityCurveTypeEnum } from '../../shared/enums/capability-curve-type.enum';

// TODO: Don't use any[] in InputType or ReturnType
type InputType = any[];
type ReturnType = [any, number, number, number, number];

/**
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class ProcessDynamicResultDataAction
  implements AWFAction<InputType, ReturnType>
{
  // This can be used to track end user operations and can be used to analyze the user behavior.
  readonly reportAnalysis = false;

  constructor(
    private actions: ActionContributionsService,
    private requirementService: RequirementsService
  ) {}

  async execute(results: InputType): Promise<ReturnType> {
    const data: Partial<any>[] = [];
    let maxSpeed = 0;
    let maxTorque = 0;
    let maxPower = 0;
    let maxAcceleration = 0;
    let minSpeed = Infinity;
    let minTorque = Infinity;
    let minPower = Infinity;
    let minAcceleration = Infinity;
    let convertedSpeeds: number[] = [];
    let convertedValues: number[] = [];
    let convertedReqSpeeds: number[] = [];
    let convertedReqValues: number[] = [];

    for (const req of results) {
      const accelerationExists: boolean = !!req.capability_curve.accelerations;
      const color: string = 'grey';
      const lineColor: string = 'grey';

      // Convert speeds
      [convertedSpeeds, minSpeed, maxSpeed] = await this.actions.execute(
        ConvertUnitValuesAction,
        [req.capability_curve.speeds, UnitType.SPEED, minSpeed, maxSpeed]
      );

      // Determine y-axis values based on requirementYAxis
      switch (this.requirementService.requirementYAxis()) {
        case CapabilityCurveTypeEnum.POWER:
          [convertedValues, minPower, maxPower] = await this.actions.execute(
            ConvertUnitValuesAction,
            [req.capability_curve.powers, UnitType.POWER, minPower, maxPower]
          );
          [convertedReqValues, minPower, maxPower] = await this.actions.execute(
            ConvertUnitValuesAction,
            [
              req.requirement.static_requirements.map(
                (staticReq: StaticRequirement) => staticReq.total_tractive_power
              ),
              UnitType.POWER,
              minPower,
              maxPower,
            ]
          );
          break;

        case CapabilityCurveTypeEnum.TORQUE:
          [convertedValues, minTorque, maxTorque] = await this.actions.execute(
            ConvertUnitValuesAction,
            [
              req.capability_curve.torques,
              UnitType.TORQUE,
              minTorque,
              maxTorque,
            ]
          );
          [convertedReqValues, minTorque, maxTorque] =
            await this.actions.execute(ConvertUnitValuesAction, [
              req.requirement.static_requirements.map(
                (staticReq: StaticRequirement) =>
                  staticReq.total_tractive_torque
              ),
              UnitType.TORQUE,
              minTorque,
              maxTorque,
            ]);
          break;

        case CapabilityCurveTypeEnum.ACCELERATION:
          if (accelerationExists) {
            [convertedValues, minAcceleration, maxAcceleration] =
              await this.actions.execute(ConvertUnitValuesAction, [
                req.capability_curve.accelerations,
                UnitType.ACCELERATION,
                minAcceleration,
                maxAcceleration,
              ]);
            [convertedReqValues, minAcceleration, maxAcceleration] =
              await this.actions.execute(ConvertUnitValuesAction, [
                req.requirement.static_requirements.map(
                  (staticReq: StaticRequirement) => staticReq.acceleration
                ),
                UnitType.ACCELERATION,
                minAcceleration,
                maxAcceleration,
              ]);
          }
          break;

        default:
          throw new Error('Unknown requirementYAxis value');
      }

      // Convert requirement speeds for the x-axis
      [convertedReqSpeeds, minSpeed, maxSpeed] = await this.actions.execute(
        ConvertUnitValuesAction,
        [
          req.requirement.static_requirements.map(
            (staticReq: StaticRequirement) => staticReq.speed
          ),
          UnitType.SPEED,
          minSpeed,
          maxSpeed,
        ]
      );

      // Add the data points for capability and requirement
      data.push(
        {
          type: 'scatter',
          x: convertedSpeeds,
          y: convertedValues,
          line: { color: lineColor },
          name: `${req.requirement.name} Capability`,
          _type: RequirementPlotType.CAPABILITY_CURVE,
          id: req.id,
        },
        {
          type: 'scatter',
          marker: { color: color },
          x: convertedReqSpeeds,
          y: convertedReqValues,
          name: `${req.requirement.name}`,
          _type: RequirementPlotType.REQUIREMENT,
          id: req.id,
        }
      );
    }

    return [data, maxSpeed, maxTorque, maxPower, maxAcceleration];
  }
}
