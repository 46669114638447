/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Types of files.
 */
export enum ComponentFileType {
    MOTOR_LAB_FILE = 'motor_lab_file',
    MOTOR_TORQUE_GRID_FILE = 'motor_torque_grid_file',
    MOTOR_TORQUE_SPEED_FILE = 'motor_torque_speed_file',
    TRANSMISSION_TORQUE_GRID_FILE = 'transmission_torque_grid_file',
    BATTERY_LOOKUP_FILE = 'battery_lookup_file',
    DRIVE_CYCLE_FILE = 'drive_cycle_file',
}
