import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { ButtonSize, ButtonType } from '@ansys/awc-angular/buttons';
import { FlexLayout } from '@ansys/awc-angular/core';
import { IconType, Icons } from '@ansys/awc-angular/icons';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { AWCTableColumns, AWCTableRow } from '@ansys/awc-angular/tables';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { ConceptSections } from 'src/app/shared/enums/concept.enum';
import {
  DataDisplayService,
  DataDisplayState,
} from 'src/app/shared/services/data-display.service';
import { PlotService } from '../../../../shared/services/plot.service';
import { ResultType } from '../../results-list/results-list.component';
import { RequirementsService } from 'src/app/shared/services/requirements.service';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { DownloadDatasetComponent } from '../../../dialogs/download-dataset/download-dataset.component';
import { DialogService } from '@ansys/andromeda/shared';
import { requirementSolvedComponentHeadersConst } from '../../../../shared/consts/requirement-solved-component-headers.const';
import { driveCycleSolvedComponentHeadersConst } from '../../../../shared/consts/drive-cycle-solved-component-headers.const';

@Component({
  selector: 'app-table-display',
  templateUrl: './table-display.component.html',
  styleUrls: ['./table-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDisplayComponent implements OnChanges, OnInit {
  @Input() section!: ConceptSections;
  @Input() part!: AWCTreeItem;
  @Input() result!: any;
  readonly sections = ConceptSections;
  readonly resultType = ResultType;
  protected columns: AWCTableColumns[] = [];
  protected rows: AWCTableRow[] = [];
  protected maxHeight: number = 400;
  protected tableIcon: IconType = { icon: Icons.FUNCTION };
  protected downloadIcon: IconType = { icon: Icons.DOWNLOAD };
  protected blueprintIcon: IconType = { icon: Icons.BODIES };
  protected altType: ButtonType = ButtonType.SECONDARY;
  protected layout: FlexLayout = FlexLayout.ROW;
  protected buttonSize: ButtonSize = ButtonSize.LARGE;
  protected selectedPointIndex: string[] = [];
  protected pointIndexes: AWCListItem[] = [];
  protected selectedRequirementColumns: string[] = [];
  protected requirementColumns: AWCListItem[] =
    requirementSolvedComponentHeadersConst;

  constructor(
    private plotService: PlotService,
    private _cdr: ChangeDetectorRef,
    private displayService: DataDisplayService,
    private requirementService: RequirementsService,
    private elRef: ElementRef,
    private actions: ActionContributionsService,
    private dialog: DialogService
  ) {
    this.plotService.activeTableData.subscribe((data) => {
      [
        this.columns,
        this.rows,
        this.selectedRequirementColumns,
        this.selectedPointIndex,
      ] = data;
      this._cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.maxHeight = this.elRef.nativeElement.offsetHeight - 65;
    if (this.result) {
      this.requirementColumns =
        this.result?.requirement_solved_type === this.resultType.DRIVE_CYCLE
          ? driveCycleSolvedComponentHeadersConst
          : requirementSolvedComponentHeadersConst;
    }
    if (
      this.result &&
      this.result?.requirement_solved_type !== this.resultType.STATIC
    ) {
      this.buildPointSelections();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['result']) {
      this.requirementColumns =
        this.result?.requirement_solved_type === this.resultType.DRIVE_CYCLE
          ? driveCycleSolvedComponentHeadersConst
          : requirementSolvedComponentHeadersConst;
      if (this.result?.requirement_solved_type !== this.resultType.STATIC) {
        this.buildPointSelections();
      }
    }
  }

  protected changeColumnFilters(selection: AWCListItem[]): void {
    this.selectedRequirementColumns = selection.map((e) => e.id);
    this.requirementService.solvedComponentTable(
      this.result,
      parseInt(this.selectedPointIndex[0]),
      this.selectedRequirementColumns
    );

    this._cdr.markForCheck();
  }

  protected switchToGraphView(): void {
    this.displayService.displayState = DataDisplayState.GRAPH_DISPLAY;
  }

  protected switchToBlueprintView(): void {
    this.displayService.displayState = DataDisplayState.BLUEPRINT_DISPLAY;
    this._cdr.markForCheck();
  }

  protected async downloadData(): Promise<void> {
    this.dialog.open(DownloadDatasetComponent, { title: 'Download Dataset' });
  }

  protected changeTableComponentPoint($event: AWCListItem[]): void {
    this.selectedPointIndex = $event.map((e) => e.id);

    this.requirementService.selectedTimeIndex.next(this.selectedPointIndex[0]);

    this.requirementService.solvedComponentTable(
      this.result,
      parseInt(this.selectedPointIndex[0]),
      this.selectedRequirementColumns
    );
  }

  private buildPointSelections(): void {
    if (!this.result) return;
    const mapArray = this.result?.distance ?? this.result.traction_limits;
    this.pointIndexes = mapArray.map((c: unknown, i: number) => {
      return {
        id: i.toString(),
        text: `Point: ${i.toString()}`,
      };
    });

    if (this.selectedPointIndex[0] === 'NaN') {
      this.selectedPointIndex = [
        this.requirementService.selectedTimeIndex.value.toString(),
      ];

      this.requirementService.selectedTimeIndex.next(
        this.selectedPointIndex[0]
      );

      this.requirementService.solvedComponentTable(
        this.result,
        parseInt(this.selectedPointIndex[0]),
        this.selectedRequirementColumns
      );
    }
  }
}
