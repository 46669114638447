/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Type } from '@angular/core';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { State, StateNode } from '@ansys/andromeda/store';
import { IconType } from '@ansys/awc-angular/icons';
import { ActiveTabState } from '@ansys/andromeda/workspace';
import { Subject } from 'rxjs';
import { ConfigurationItemState } from 'src/app/state/lib/configurations.state';
import { ComponentsState } from 'src/app/state/lib/components.state';
import { RequirementItemState } from 'src/app/state/lib/requirements.state';
import { itemIconMap } from '../maps/item-icon-map';
/**
 */

@Injectable({ providedIn: 'root' })
export class TreeService {
  /**
   * Map of the parent ID to the state key
   */
  private stateMap: Map<string, Type<StateNode<AWCTreeItem[]>>> = new Map([
    ['vehicle', ConfigurationItemState],
    ['mass', ConfigurationItemState],
    ['wheel', ConfigurationItemState],
    ['aero', ConfigurationItemState],
    ['ancillary_load', ConfigurationItemState],
    ['deceleration_limit', ConfigurationItemState],
    ['transmission', ComponentsState],
    ['motor', ComponentsState],
    ['battery', ComponentsState],
    ['inverter', ComponentsState],
    ['clutch', ComponentsState],
    ['static_requirement', RequirementItemState],
    ['dynamic_requirement', RequirementItemState],
    ['drive_cycle', RequirementItemState],
  ]);
  /**
   * Map for icon construction to the parent ID
   */
  private iconMap = itemIconMap;
  public selectItem: Subject<AWCTreeItem> = new Subject<AWCTreeItem>();
  constructor(private state: State) {}
  /**
   * Adds a vehicle configuration to the tree view
   * @param {string} parentID - ID of the parent item
   * @param {any} config - Configuration to add TODO - make this a type
   * @param {boolean} remove - If true then remove the configuration from the tree view
   */
  public addVehiclePartItem(parentID: string, config: any): AWCTreeItem {
    const prefixIcon: IconType = this.iconMap.get(parentID) as IconType;
    const item: AWCTreeItem = {
      id: config.id,
      text: config.name,
      prefixIcon,
      userData: {
        ...config,
        _parentID: parentID,
      },
    };
    const state = this.stateMap.get(parentID);
    const tab = this.state.value(ActiveTabState);
    let currentState: AWCTreeItem[] = [];
    if (state) {
      currentState = this.state.value(state, tab?.id);
    }

    const currentIndex = currentState.findIndex((stateItem) => {
      return stateItem.id === item.id;
    });

    if (currentIndex !== -1) {
      currentState[currentIndex] = item;
    } else {
      currentState.push(item);
    }

    state && this.state.set(state, currentState, tab?.id);

    return item;
  }

  public updateItem(item: AWCTreeItem): void {
    const state = this.stateMap.get(
      item.userData ? (item.userData['_parentID'] as string) : ''
    );
    const tab = this.state.value(ActiveTabState);
    let currentState: AWCTreeItem[] = [];
    if (state) {
      currentState = this.state.value(state, tab?.id);
    }
    const index = currentState.findIndex(
      (stateItem) => stateItem.id === item.id
    );
    currentState[index] = item;
    state && this.state.set(state, [...currentState], tab?.id);
  }
}
