/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { BatteryFixedVoltages } from '../models/BatteryFixedVoltages';
import type { BatteryLookupTable } from '../models/BatteryLookupTable';
import type { BatteryLookupTableID } from '../models/BatteryLookupTableID';
import type { Body_create_component_data_from_file_components_from_file_post } from '../models/Body_create_component_data_from_file_components_from_file_post';
import type { Body_create_file_items_components_upload_post } from '../models/Body_create_file_items_components_upload_post';
import type { ComponentFileType } from '../models/ComponentFileType';
import type { ComponentInDB } from '../models/ComponentInDB';
import type { ComponentLossMapArgs } from '../models/ComponentLossMapArgs';
import type { DisconnectClutchInput } from '../models/DisconnectClutchInput';
import type { InverterAnalytical } from '../models/InverterAnalytical';
import type { MotorCTCP } from '../models/MotorCTCP';
import type { MotorLab } from '../models/MotorLab';
import type { MotorLabID } from '../models/MotorLabID';
import type { MotorLossMap } from '../models/MotorLossMap';
import type { MotorLossMapID } from '../models/MotorLossMapID';
import type { MotorTorqueCurves } from '../models/MotorTorqueCurves';
import type { MotorTorqueCurvesID } from '../models/MotorTorqueCurvesID';
import type { SubmittedJob } from '../models/SubmittedJob';
import type { TransmissionLossCoefficients } from '../models/TransmissionLossCoefficients';
import type { TransmissionLossMap } from '../models/TransmissionLossMap';
import type { TransmissionLossMapID } from '../models/TransmissionLossMapID';
import type { TransmissionNeglect } from '../models/TransmissionNeglect';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ComponentsService {
    constructor(public readonly http: HttpClient) {}
    /**
     * Create
     * Create from parameters.
     * @param designInstanceId
     * @param requestBody
     * @returns ComponentInDB Successful Response
     * @throws ApiError
     */
    public createComponentsPost(
        designInstanceId: string,
        requestBody: (BatteryFixedVoltages | BatteryLookupTable | MotorCTCP | MotorTorqueCurves | MotorLossMap | MotorLab | TransmissionLossCoefficients | TransmissionLossMap | TransmissionNeglect | InverterAnalytical | DisconnectClutchInput | MotorLossMapID | MotorLabID | MotorTorqueCurvesID | BatteryLookupTableID | TransmissionLossMapID),
    ): Observable<ComponentInDB> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/components',
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Component Data From File
     * Create component part from uploaded file.
     * @param componentFileType
     * @param designInstanceId
     * @param hpcId
     * @param fileHash
     * @param fileSize
     * @param accountId
     * @param formData
     * @param dockerTag
     * @returns SubmittedJob Successful Response
     * @throws ApiError
     */
    public createComponentDataFromFileComponentsFromFilePost(
        componentFileType: ComponentFileType,
        designInstanceId: string,
        hpcId: string,
        fileHash: string,
        fileSize: number,
        accountId: string,
        formData: Body_create_component_data_from_file_components_from_file_post,
        dockerTag: string = 'latest',
    ): Observable<SubmittedJob> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/components:from_file',
            query: {
                'component_file_type': componentFileType,
                'design_instance_id': designInstanceId,
                'hpc_id': hpcId,
                'file_hash': fileHash,
                'file_size': fileSize,
                'account_id': accountId,
                'docker_tag': dockerTag,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Create File Items
     * Create component from uploaded file.
     * @param componentFileType
     * @param designInstanceId
     * @param formData
     * @param returnSpeedOnly
     * @returns any Successful Response
     * @throws ApiError
     */
    public createFileItemsComponentsUploadPost(
        componentFileType: ComponentFileType,
        designInstanceId: string,
        formData: Body_create_file_items_components_upload_post,
        returnSpeedOnly: boolean = true,
    ): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/components:upload',
            query: {
                'component_file_type': componentFileType,
                'design_instance_id': designInstanceId,
                'return_speed_only': returnSpeedOnly,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Read
     * Get from ID.
     * @param itemId
     * @param designInstanceId
     * @returns ComponentInDB Successful Response
     * @throws ApiError
     */
    public readComponentsItemIdGet(
        itemId: string,
        designInstanceId: string,
    ): Observable<ComponentInDB> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/components/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update
     * Update with new parameters.
     * @param itemId
     * @param designInstanceId
     * @param requestBody
     * @returns ComponentInDB Successful Response
     * @throws ApiError
     */
    public updateComponentsItemIdPut(
        itemId: string,
        designInstanceId: string,
        requestBody: (BatteryFixedVoltages | BatteryLookupTable | MotorCTCP | MotorTorqueCurves | MotorLossMap | MotorLab | TransmissionLossCoefficients | TransmissionLossMap | TransmissionNeglect | InverterAnalytical | DisconnectClutchInput | MotorLossMapID | MotorLabID | MotorTorqueCurvesID | BatteryLookupTableID | TransmissionLossMapID),
    ): Observable<ComponentInDB> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/components/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete
     * Delete by ID.
     * @param itemId
     * @param designInstanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteComponentsItemIdDelete(
        itemId: string,
        designInstanceId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/components/{item_id}',
            path: {
                'item_id': itemId,
            },
            query: {
                'design_instance_id': designInstanceId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Calc Display Data
     * Calculate component data from an ID.
     * @param componentId
     * @param designInstanceId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public calcDisplayDataComponentsGetDisplayDataPost(
        componentId: string,
        designInstanceId: string,
        requestBody?: (ComponentLossMapArgs | null),
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/components:get_display_data',
            query: {
                'component_id': componentId,
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Calc Image Data
     * Calculate component data from an ID.
     * @param componentId
     * @param designInstanceId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public calcImageDataComponentsImageDataPost(
        componentId: string,
        designInstanceId: string,
        requestBody?: (ComponentLossMapArgs | null),
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/components:image_data',
            query: {
                'component_id': componentId,
                'design_instance_id': designInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }
}
