import { Component, Inject, Type } from '@angular/core';
import {
  SectionContributionsService,
  TabContributionsService,
  TabSectionType,
  MenuContributionsService,
  TableContributionsService,
  TableColumn,
  ActionContributionsService,
  WorkspaceTab,
  LayoutMenus,
  TabType,
} from '@ansys/andromeda/contributions';
import {
  SectionSelectAction,
  SectionsState,
  TabOpenAction,
  TabSelectAction,
} from '@ansys/andromeda/workspace';
import { AppThemeState, State } from '@ansys/andromeda/store';
import { ConceptService } from './shared/services/concept.service';
import {
  DashboardTables,
  LibraryTableColumns,
  LibraryTableActions,
  CreateDesignInstanceAction,
  OpenResultsAction,
  CreateDesignAction,
  FilesTableColumns,
  Tabs,
  OpenInstanceAction,
  INSTANCE_TAB_TYPE,
} from '@ansys/andromeda/dashboard';
import { LibraryTypeColumn } from './tables/library-type-column';
import { LibraryIconColumn } from './tables/library-icon-column';
import { Design, DesignInstance, Job } from '@ansys/cloud-angular-client';
import { lastValueFrom } from 'rxjs';
import { AuthSessionService } from '@ansys/andromeda/auth';
import { OpenConceptAction } from './actions';
import { CreateConceptFromTemplateAction } from './actions';
import {
  JobMessageData,
  UserSocketService,
  JobQueued,
  JobStarted,
  JobCreated,
  JobCompleted,
  JobFinished,
  JobMessage,
} from '@ansys/andromeda/sockets';
import { DialogService, SnackBarService } from '@ansys/andromeda/shared';
import { AddJob, UpdateJobStatus, UpdateJobProgress } from './actions';
import { ActiveConceptIDState } from './state/lib/active-concept-id.state';
import { StudyProjectColumn } from './tables/study-project-column';
import { AboutMenu } from './menus/about-menu';
import { LibraryDescriptionColumn } from './tables/library-description-column';
import { ProjectLogoColumn } from './tables/project-logo-column';
import { HelpMenu } from './menus/help-menu';
import { FileMenuItems, DashboardMenus } from '@ansys/andromeda/dashboard';
import { PlotState } from './state/lib/plot.state';
import { PlotService } from './shared/services/plot.service';
import {
  defaultAxisColorDarkConfig,
  defaultAxisColorLightConfig,
} from './components/widgets/charts/graph-display/lib/models/default-axis-color-config';
import { DriveCycleTable } from './tables/drive-cycle/drive-cycle.table';
import { DriveCycleNameColumn } from './tables/drive-cycle/drive-cycle-name.column';
import { DriveCycleMenuColumn } from './tables/drive-cycle/drive-cycle-menu.column';
import {
  DriveCycleDeleteMenuItem,
  DriveCycleMenu,
} from './menus/drive-cycle/drive-cycle-menu';
import { DriveCycleSelectedColumn } from './tables/drive-cycle/drive-cycle-selected.column';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ConceptEV';
  public authStatus: boolean = false;
  constructor(
    private tabs: TabContributionsService,
    private sections: SectionContributionsService,
    private conceptService: ConceptService,
    @Inject(TableContributionsService)
    private tables: TableContributionsService,
    @Inject(MenuContributionsService) private menus: MenuContributionsService,
    private actions: ActionContributionsService,
    private authSession: AuthSessionService,
    private snackbar: SnackBarService,
    userSocket: UserSocketService,
    private state: State,
    private dialog: DialogService,
    private plotService: PlotService,
    @Inject(INSTANCE_TAB_TYPE) private tabType: string
  ) {
    this.actions.get(TabSelectAction).subscribe((tab?: WorkspaceTab) => {
      this.state.set(
        ActiveConceptIDState,
        tab?.urlParams ? tab.urlParams[0] : ''
      );
      // check if state has concept loaded
      if (tab?.urlParams && tab.urlParams[0]) {
        this.conceptService.openConcept(tab.urlParams[0]);
      }
    });
    this.jobListeners();

    this.actions
      .get(OpenResultsAction)
      .subscribe((job: Job) => this.openResults(job));

    this.actions
      .get(CreateDesignAction)
      .subscribe((context: [Design, string | null]) =>
        this.createdDesign(context)
      );

    this.actions
      .get(CreateDesignInstanceAction)
      .subscribe((instance: DesignInstance) => this.createdInstance(instance));

    // MENU CONTRIBUTIONS
    this.menus.removeMenuItem(
      LibraryTableActions.MORE,
      LibraryTableActions.EDIT
    );
    this.menus.removeMenuItem(DashboardMenus.FILE_MENU, FileMenuItems.SHARE);

    this.menus.contribute(LayoutMenus.USER_PROFILE, AboutMenu);
    this.menus.contribute(LayoutMenus.USER_PROFILE, HelpMenu);

    // TABLE CONTRIBUTIONS
    this.tables.contributeColumn(DashboardTables.LIBRARY, LibraryTypeColumn);
    this.tables.contributeColumn(DashboardTables.LIBRARY, LibraryIconColumn);
    this.tables.contributeColumn(
      DashboardTables.LIBRARY,
      LibraryDescriptionColumn
    );

    this.tables.removeColumn(DashboardTables.FILES, FilesTableColumns.ICON);
    this.tables.contributeColumn(DashboardTables.FILES, ProjectLogoColumn);

    this.tables.contributeColumn(DashboardTables.STUDIES, StudyProjectColumn);

    this.tables
      .getColumn(DashboardTables.LIBRARY, LibraryTableColumns.ACTIONS)
      .then((column: TableColumn) => {
        column.width = 130;
      });
    this.tables.removeColumn(
      DashboardTables.LIBRARY,
      LibraryTableColumns.DESCRIPTION
    );

    // AppThemeState subscription
    this.state.get(AppThemeState).subscribe(async (theme) => {
      const isDarkMode =
        theme === 'dark' ||
        (theme === 'auto' &&
          window.matchMedia?.('(prefers-color-scheme: dark)').matches);

      const axisColorConfig = isDarkMode
        ? defaultAxisColorDarkConfig
        : defaultAxisColorLightConfig;

      this.plotService.updateGraphLayout({
        font: {
          color: isDarkMode ? '#fff' : '#000',
        },
        legend: {
          font: {
            color: isDarkMode ? '#fff' : '#000',
          },
        },
        xaxis: axisColorConfig,
        yaxis: axisColorConfig,
      });
    });

    this.tables.contributeTable(DriveCycleTable).then((table) => {
      this.tables.contributeColumn(table, DriveCycleSelectedColumn);
      this.tables.contributeColumn(table, DriveCycleNameColumn);
      this.tables.contributeColumn(table, DriveCycleMenuColumn);
    });
    this.menus.contribute('drive-cycle-more-menu', DriveCycleDeleteMenuItem);
    this.menus.contribute('drive-cycle-menu', DriveCycleMenu).then((menu) => {
      this.menus.addChildMenu(menu, 'drive-cycle-more-menu');
    });
  }

  /**
   * Util to contribute section
   */
  private contributeSection(
    tabType: TabType,
    section: Type<TabSectionType>
  ): void {
    this.sections.contribute(tabType, section);
  }
  private openInstance(instanceId: string): void {
    if (!instanceId) {
      throw new Error('No instance found');
    }
    this.actions.execute(OpenConceptAction, instanceId);
  }
  private async openResults(job: Job): Promise<void> {
    const instanceId = job.designInstanceId;
    if (!instanceId) {
      throw new Error('No instance found');
    }

    await this.actions.execute(TabOpenAction, {
      type: this.tabType,
      urlParams: [instanceId],
      userData: { id: instanceId },
      selectTab: true,
    });

    // Navigate to new Result
    setTimeout(() => {
      const sections = this.state.value(SectionsState);
      const requirementSection = sections.find(
        (section) => section.type === 'requirement'
      );

      this.actions.execute(SectionSelectAction, {
        sectionId: requirementSection?.id + '',
        urlParams: ['results', job.jobId + ''],
      });
    });
  }

  private async createdInstance(instance: DesignInstance): Promise<void> {
    await lastValueFrom(this.conceptService.cloneConcept(instance));
  }

  private async createdDesign(context: [Design, string | null]): Promise<void> {
    const [design, templateId] = context;

    if (templateId) {
      await this.actions.execute(CreateConceptFromTemplateAction, context);
    } else {
      if (design.parentDesignId && design.designInstanceList) {
        await lastValueFrom(
          this.conceptService.cloneConcept(design.designInstanceList[0])
        );
      } else {
        await this.conceptService.createConcept(design);
      }
    }
  }

  private jobListeners(): void {
    this.actions.get(JobQueued).subscribe(async (message: JobMessageData) => {
      this.actions.execute(UpdateJobStatus, [
        message.jobId,
        message.jobStatus ?? '',
      ]);
    });
    this.actions.get(JobStarted).subscribe((message: JobMessageData) => {
      this.actions.execute(UpdateJobStatus, [
        message.jobId,
        (message?.['status'] as string) ?? '',
      ]);
    });
    this.actions.get(JobCreated).subscribe((message: any) => {
      this.actions.execute(AddJob, message?.jobId as string).then(() => {
        this.actions.execute(UpdateJobStatus, [
          message?.jobId as string,
          message?.jobStatus as string,
        ]);
      });
      this.snackbar.info('Job Created');
    });
    this.actions.get(JobCompleted).subscribe((message: JobMessageData) => {
      this.actions.execute(UpdateJobStatus, [
        message.jobId,
        message['status'] as string,
      ]);
    });

    this.actions.get(JobFinished).subscribe((message: JobMessageData) => {
      const job = message['job'];
      this.actions.execute(UpdateJobStatus, [
        job?.jobId ?? '',
        job?.simulations?.[0].lastStatus === 'FAILED' ? 'FAILED' : 'COMPLETED',
      ]);
      this.snackbar.info(
        `${job?.jobName ?? 'Job'} finished with status: ${
          job?.simulations?.[0].lastStatus
        }`
      );
    });

    this.actions.get(JobMessage).subscribe((message: JobMessageData) => {
      if (message['messagetype'] === 'progress') {
        this.actions.execute(UpdateJobProgress, message);
      }
    });
  }
}
