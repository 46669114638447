/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Transmission In DB.
 */
export type TransmissionLossCoefficientsInDB = {
    item_type?: TransmissionLossCoefficientsInDB.item_type;
    name?: string;
    mass?: number;
    cost?: number;
    component_type?: 'TransmissionLossCoefficients';
    gear_ratios?: Array<number>;
    headline_efficiencies?: Array<number>;
    max_torque?: number;
    max_speed?: number;
    static_drags?: Array<number>;
    friction_ratios?: Array<number>;
    shift_time?: number;
    _id?: string;
};
export namespace TransmissionLossCoefficientsInDB {
    export enum item_type {
        COMPONENT = 'component',
    }
}

