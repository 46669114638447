import {
  Table,
  TableColumn,
  TableGetDataResponse,
} from '@ansys/andromeda/contributions';
import { AWCFilter } from '@ansys/awc-angular/filters';
import { ConceptService } from '../../shared/services/concept.service';
import { Injectable } from '@angular/core';
import { ActiveConceptState } from '../../state/lib/active-concept.state';
import { State } from '@ansys/andromeda/store';
import { TableEnum } from '../../shared/enums/table.enum';
import { SelectedDriveCycleState } from '../../state/lib/selected-drive-cycle.state';
import { DriveCycleTableType } from '../../shared/types/drive-cycle-table.type';

@Injectable({
  providedIn: 'root',
})
export class DriveCycleTable implements Table {
  columns: TableColumn[] = [];
  type: string = TableEnum.DRIVE_CYCLE;

  constructor(private conceptService: ConceptService, private state: State) {}

  async getData(
    pageNumber: number,
    filters: AWCFilter[] | undefined,
    fallbackData: any,
    searchValue: string | undefined
  ): Promise<TableGetDataResponse> {
    const concept = this.state.value(ActiveConceptState);
    const selectedDriveCycle = this.state.value(SelectedDriveCycleState);
    if (!concept) {
      return Promise.resolve({ data: [], total: 0 });
    }

    const driveCycles = await this.conceptService.getConceptDriveCycles(
      concept,
      false
    );
    const driveCycleTableData: DriveCycleTableType[] = driveCycles.map(
      (driveCycle) => {
        return {
          id: driveCycle[0],
          text: driveCycle[1],
          selected: driveCycle[0] === selectedDriveCycle,
        };
      }
    ) as DriveCycleTableType[];

    return Promise.resolve({
      data: driveCycleTableData,
      total: driveCycleTableData['length'],
    });
  }

  getId(data: DriveCycleTableType): string {
    return data.id;
  }
}
