<div class="panel-title-cont">
  <div class="panel-title">
    Drive Cycles
  </div>
  <div>
    <awc-button
      [text]="'Add Drive Cycle'"
      [prefixIcon]=" { icon: icons.ADD }"
      (clicked)="addDriveCycle($event)"
    ></awc-button>
    <input #fileUpload type="file" (click)="uploadClick()" (change)="importDriveCycle($event)" style="display:none" accept=".csv">
  </div>
</div>

@if (driveCyclesLoaded) {
  <div class="relative-cont">
    <awf-table [tableId]="tableEnum.DRIVE_CYCLE" [filters]="filters" (rowClick)="selectionIDSet($event)"></awf-table>
  </div>
} @else {
  <div class="loading-cont">
    <awc-icon [icon]="icons.SPINNER"></awc-icon>
  </div>
}
