import { AWCListItem } from '@ansys/awc-angular/lists';

export const requirementSolvedComponentHeadersConst: AWCListItem[] = [
  {
    id: 'speeds',
    text: 'Speeds',
  },
  {
    id: 'in_torques',
    text: 'In Torques',
  },
  {
    id: 'out_torques',
    text: 'Out Torques',
  },
  {
    id: 'in_powers',
    text: 'In Powers',
  },
  {
    id: 'out_powers',
    text: 'Out Powers',
  },
  {
    id: 'in_voltages',
    text: 'In Voltages',
  },
  {
    id: 'out_voltages',
    text: 'Out Voltages',
  },
  {
    id: 'efficiencies',
    text: 'Efficiencies',
  },
  {
    id: 'losses',
    text: 'Losses',
  },
  {
    id: 'losses_ratio',
    text: 'Losses Ratio',
  },
  {
    id: 'gear_ratios',
    text: 'Gear Ratios',
  },
];
