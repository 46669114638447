import {
  TabSectionType,
  WorkspaceTab,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import { Icons } from '@ansys/awc-angular/icons';
import { ConceptSections } from '../../../../shared/enums';
import { sectionState } from '../../../../shared/models/section-state';
import { IconType } from '@ansys/awc-angular/icons';
import { AWFSection } from '@ansys/andromeda/core';

@AWFSection({
  route: ConceptSections.REQUIREMENT,
})
export class RequirementsSection implements TabSectionType {
  public type: ConceptSections = ConceptSections.REQUIREMENT;
  public title: string = 'Requirements';
  public icon: Icons = Icons.PLOTS;

  async getTitle(
    tab: WorkspaceTab,
    section: WorkspaceTabSection
  ): Promise<string> {
    return this.title;
  }

  async getIcon(
    tab: WorkspaceTab,
    section: WorkspaceTabSection
  ): Promise<IconType> {
    return { icon: this.icon };
  }

  public isDisabled(): Promise<boolean> {
    return new Promise((resolve) => {
      resolve(!sectionState.requirement);
    });
  }
}
