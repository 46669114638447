/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Create motor from Constant Torque Constant Power parameters.
 */
export type MotorCTCP = {
    item_type?: MotorCTCP.item_type;
    name: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: MotorCTCP.component_type;
    rated_power?: (number | null);
    base_speed?: (number | null);
    stall_torque?: (number | null);
    max_speed: number;
    power_at_max_speed?: (number | null);
};
export namespace MotorCTCP {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        MOTOR_CTCP = 'MotorCTCP',
    }
}

