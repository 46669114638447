/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { UnitType, StaticRequirementAccelerationIds } from 'src/api';
import { InputTypes } from '../../../forms/input/input.component';
import { FlexLayout } from '@ansys/awc-angular/core';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ConfigurationsService } from 'src/app/shared/services/configurations.service';
import { ArchitectureService } from '../../../../../shared/services/architecture.service';

export type SetPart = [value: string, type: string, id: string, index?: number];

@Component({
  selector: 'app-static-requirements-inputs',
  templateUrl: './static-requirements.component.html',
  styleUrls: ['../inputs.scss'],
})
export class StaticRequirementsComponent {
  @Input() part!: StaticRequirementAccelerationIds;
  @Input() update!: boolean;
  @Output() setPart: EventEmitter<SetPart> = new EventEmitter<SetPart>();
  @Output() forceUpdate: EventEmitter<string> = new EventEmitter<string>();
  protected UnitType = UnitType;
  protected InputTypes = InputTypes;
  protected layout: FlexLayout = FlexLayout.COLUMN;
  protected aerodynamics: AWCListItem[] = [];
  protected massses: AWCListItem[] = [];
  protected wheels: AWCListItem[] = [];
  protected limits: AWCListItem[] = [];
  protected selectedAero: string[] = [];
  protected selectedMass: string[] = [];
  protected selectedWheel: string[] = [];
  protected selectedLimit: string[] = [];
  // protected isSingleAxleOnly: boolean = false;
  protected axleSplitStrategies: AWCListItem[] = [
    {
      id: 'max_efficiency',
      text: 'Max Efficiency',
    },
    {
      id: 'fixed',
      text: 'Fixed',
    },
  ];
  protected axleSplitStrategy: string = this.axleSplitStrategies[0].id;

  constructor(
    private configurationService: ConfigurationsService,
    protected architecture: ArchitectureService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.selectedAero = [this.part.aero_id];
    this.selectedMass = [this.part.mass_id];
    this.selectedWheel = [this.part.wheel_id];
    this.selectedLimit = [this.part.deceleration_limit_id ?? ''];
    [this.aerodynamics, this.massses, this.wheels, this.limits] =
      this.configurationService.configurationLists.map((list) => {
        return list.map((item) => {
          // const clone = { ...item };
          // delete (clone as AWCTreeItem).parent;
          // return clone;
          return { ...item };
        });
      });

    if (
      this.part.front_axle_split !== null &&
      this.part.front_axle_split !== undefined
    ) {
      this.axleSplitStrategy = this.axleSplitStrategies[1].id;
    }
  }

  protected changeAxleSplitStrategy($event: AWCListItem[]): void {
    this.axleSplitStrategy = $event[0].id;
    if (this.axleSplitStrategy === this.axleSplitStrategies[0].id) {
      this.part.front_axle_split = null;
    }
    this.forceUpdate.emit('');
  }

  protected selectionIDSet($event: string, type: string): void {
    this.part[type as keyof StaticRequirementAccelerationIds] = $event as never;
    this.forceUpdate.emit('');
  }
}
