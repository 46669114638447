/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Motor in Database.
 */
export type MotorCTCPInDB = {
    item_type?: MotorCTCPInDB.item_type;
    name: string;
    mass?: number;
    moment_of_inertia?: number;
    cost?: number;
    component_type?: MotorCTCPInDB.component_type;
    rated_power?: (number | null);
    base_speed?: (number | null);
    stall_torque?: (number | null);
    max_speed: number;
    power_at_max_speed?: (number | null);
    _id?: string;
    id?: string;
};
export namespace MotorCTCPInDB {
    export enum item_type {
        COMPONENT = 'component',
    }
    export enum component_type {
        MOTOR_CTCP = 'MotorCTCP',
    }
}

